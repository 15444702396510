import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardHeader, CardBody, Nav, NavItem, TabContent, TabPane  } from "reactstrap";
//import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Customers from "../../../components/customer/Customers";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import {dataEncryptKey} from "../../../constants/defaultValues";

import classnames from "classnames";
import { NavLink } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AllCustomer extends Component {
	
	constructor(props) {
		super(props);
		
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		
		this.state = {
			activeFirstTab: "1",
		};
	}
	
	toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
		  this.setState({
			activeFirstTab: tab
		  });
		}
	}
  
	render() {
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  parseInt(dataCrypto.decrypt(facility_id));
			
		return (
			<Fragment>
				<Row>
				  <Colxx xxs="12"> 
					<Breadcrumb heading="menu.all-customers" match={this.props.match} />
					<Separator className="mb-5" />
				  </Colxx>
				</Row>
				
				{facility_id!=9 ? <Row> 
					<Colxx lg="12" className="page-tabs">
						<Card className="mb-4">					
							<CardBody>
								<Customers {...this.props} register_type="2" />
							</CardBody>						 
						</Card>
					</Colxx>
				</Row>:<Row className="people-layout">          		  
					  <Colxx lg="12" className="page-tabs">
						  <Card className="mb-4">
							<CardHeader>
								<Nav tabs className="card-header-tabs " style={{marginBottom:'10px'}}>
									<NavItem>
									  <NavLink
										className={classnames({
										  active: this.state.activeFirstTab === "1",
										  "nav-link": true
										})}
										onClick={() => {
										  this.toggleFirstTab("1");
										}}
										to="#"
									  >
										All
									  </NavLink>
									</NavItem>
									<NavItem>
									  <NavLink
										className={classnames({
										  active: this.state.activeFirstTab === "2",
										  "nav-link": true
										})}
										onClick={() => {
										  this.toggleFirstTab("2");
										}}
										to="#"
									  >
										Individuals
									  </NavLink>
									</NavItem>
									<NavItem>
									  <NavLink
										className={classnames({
										  active: this.state.activeFirstTab === "3",
										  "nav-link": true
										})}
										onClick={() => {
										  this.toggleFirstTab("3");
										}}
										to="#"
									  >
										Companies
									  </NavLink>
									</NavItem>										 
								</Nav>
							</CardHeader>

							<TabContent activeTab={this.state.activeFirstTab}>
							  <TabPane tabId="1">
								<Row>
								  <Colxx sm="12">
									<CardBody>
										{this.state.activeFirstTab === "1" ? <Customers {...this.props} register_type="0" />:""}
									</CardBody>
								  </Colxx>
								</Row>
							  </TabPane>
							  <TabPane tabId="2">
								<Row>
								  <Colxx sm="12">
									<CardBody>
										{this.state.activeFirstTab === "2" ? <Customers {...this.props} register_type="2" />:""}
									</CardBody>
								  </Colxx>
								</Row>
							  </TabPane>
							  <TabPane tabId="3">
								<Row>
								  <Colxx sm="12">
									<CardBody>
										{this.state.activeFirstTab === "3" ? <Customers {...this.props} register_type="1" />:""}
									</CardBody>
								  </Colxx>
								</Row>
							  </TabPane>
							</TabContent>
						  </Card>
						</Colxx>

					</Row>}
			</Fragment>
		);
	}
}
