import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
  
export default class DebitPaymentModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			card_name:"",
			card_number:"",
			total_due:0
		};	
	}
	
	componentDidMount(){
		const payment_data = this.props.payment_data;
		var card_name = this.state.card_name;
		var card_number = this.state.card_number;
		
		if(payment_data!=null){
			card_name = payment_data['card_name'];
			card_number = payment_data['card_number'];
		}
		this.setState({	card_name,card_number, total_due:this.props.total_due+payment_data['debit'] });
	}
	
	resetCard = ()=>{
		this.setState({	card_name:"",card_number:"" });
		var payment_data = this.props.payment_data;
		payment_data['card_name'] = "";
		payment_data['card_number'] = "";
		payment_data['debit'] = 0;
		
		this.props.updatePaymentData(payment_data);
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0){
			var payment_data = this.props.payment_data;
			var card_name = this.state.card_name;
			var card_number = this.state.card_number;
			
			document.getElementById("debit-form-btn").disabled=true;
			
			payment_data['card_name'] = card_name;
			payment_data['card_number'] = card_number;
			payment_data['debit'] = this.props.total_due>0 ? this.props.total_due:0; 
			
			this.props.updatePaymentData(payment_data);
			this.props.toggleModal();
		}
	}

	render() {
		
		const total_due = this.state.total_due;
		return (
	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Debit Payment
				</ModalHeader>
				<AvForm
					id="debitForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup>
								<Label>Card Name<span className="text-danger">*</span></Label>
								<AvField
									id="card_name"
									name="card_name"
									type="text"
									value={this.state.card_name}
									onChange={(e)=>{ this.setState({ card_name:e.target.value }) }}
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter the card name"
										},										
										pattern: {
											value: "^[a-zA-Z ,.'-]+$",
											errorMessage: "Invalid card name"
										}
									}}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup >
								<Label>Card Number<span className="text-danger">*</span></Label>
								<AvField
									id="card_number"
									name="card_number"
									type="number"
									value={this.state.card_number}
									onChange={(e)=>{ this.setState({ card_number:e.target.value }) }}
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter the card number"
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx xxs="12">
							<AvGroup >
								<Label>Total Due</Label>
								<AvField
									id="total_due"
									name="total_due"
									type="text"
									readOnly={true}
									placeholder="0"
									value={total_due.toFixed(2)}
								/>
							</AvGroup>
						</Colxx>
					</Row>					
				</ModalBody>
				<ModalFooter className="justify-content-start">
					<Button color="primary" id="debit-form-btn">
						<IntlMessages id="customer.submit" />
					</Button>
					<Button color="primary" outline onClick={this.resetCard}>
						<IntlMessages id="customer.reset" />
					</Button>{" "}
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};
