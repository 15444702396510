import React from "react";
import {
  Row,
  Card,
  CardBody,
  CardSubtitle,
  CardImg,
  CardText,
  CustomInput,
  Badge,
  Button
} from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { BASE_URL } from "../../../constants/defaultValues";

const ImageListView = ({ result, isSelect, collect, onCheckItem, editmodal, editmodalother, deleteItem, user_privileges, register_type }) => {
var customerStatus=result.status!=1?1:0;
  return (
    <Colxx sm="6" lg="4" xl="3" className="mb-3" key={result.vendor_id}>
      <ContextMenuTrigger id="menu_id" data={result.vendor_id} collect={collect}>
        <Card>
          <div className="position-relative">
            <NavLink to={`?p=${result.vendor_id}`} className="w-40 w-sm-100">
              <CardImg top alt={result.vendor_name} src={(result)?((result.profile_pic)?BASE_URL+'users/'+result.profile_pic:BASE_URL+'users/avatar.jpg'):BASE_URL+'users/avatar.jpg'} />
            </NavLink>
            <Badge
              color={result.status==1?"success":"danger"}
              pill
              className="position-absolute badge-top-left"
            >
              {result.status==1?"Active":"Inactive"}
            </Badge>
			{register_type=="0" ? <Badge
              color={parseInt(result.register_type)==2 ? "info":"secondary"}
              pill
              className="position-absolute badge-top-left" style={{top: '36px'}}
            >
              {parseInt(result.register_type)==2 ? "Individual":"Company"}
            </Badge>:""}
          </div>
          <CardBody className="image-view-card-body">
            <Row>
              {user_privileges.indexOf(130)!==-1 ? <Colxx xxs="2" >
                <CustomInput
                  className="item-check mb-0"
                  type="checkbox"
                  id={`check_${result.vendor_id}`}
                  checked={isSelect}
                  onChange={event => onCheckItem(event, result.vendor_id,result.status)}
				  className={classnames("d-flex flex-row", {
					active: isSelect
				  })}
                  label=""
                />
              </Colxx>:""}
              <Colxx xxs="12" className="mb-3">
                <CardSubtitle className="image-view-title"><NavLink to="#" className="w-16 w-sm-100">{result.vendor_name}</NavLink></CardSubtitle>
                <CardText className="text-muted text-small mb-0 font-weight-light">
                  {result.email_id}<br/>
                  {result.phone}
                </CardText>
				{user_privileges.indexOf(130)!==-1 ? <div className="action-btns mt-3 w-100 w-sm-100">
					<Button className="mr-1" onClick={event =>{ parseInt(result.register_type)==2 ? editmodal(event,result.vendor_id):editmodalother(event,result.vendor_id) }} size="sm" color="success" title="Edit"><i className="simple-icon-pencil"></i></Button>
					<Button className="mr-1" onClick={event =>deleteItem(event,result.vendor_id,customerStatus,result.register_type)}  size="sm" color="danger" title="Deactivate" ><i className={result.status!=1?'simple-icon-check':'simple-icon-ban'}></i></Button>
					<Button className="mr-1" onClick={event =>deleteItem(event,result.vendor_id,2,result.register_type)}  size="sm" color="danger" title="Delete" ><i className="simple-icon-trash"></i></Button>
				</div>:""}
              </Colxx>
            </Row>
          </CardBody>
        </Card>
      </ContextMenuTrigger>
    </Colxx>
  );
};

/* React.memo detail : https://reactjs.org/docs/react-api.html#reactpurecomponent  */
export default React.memo(ImageListView);
