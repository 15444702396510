import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
//import Paginator from 'react-hooks-paginator';
import { ToastProvider } from "react-toast-notifications";

import { loginUser } from "../redux/actions";
import { Colxx } from "../components/common/CustomBootstrap";
import { projectTitle } from "../constants/defaultValues";
import HomeHeader from "../components/common/HomeHeader";
import HomeFooter from "../components/common/HomeFooter";

import { REST_API_END_POINT, dataEncryptKey, BASE_URL } from "../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import MailChimpIntegration from "../components/front-end/MailChimpIntegration";
import { seo } from "../helpers/Utils";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

class Privacy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            template_data: null
        };
        this.dataListRender = this.dataListRender.bind(this);
        seo({
            title: 'Privacy | ' + projectTitle,
            metaDescription: 'Know our story'
        });
    }

    componentDidMount() {

        document.body.classList.add("background");
        document.body.classList.add("background-main-pages");
        var facility_id = localStorage.getItem('facility_id');
        if (facility_id) {
            this.dataListRender();
        } else {
            window.location = '/';
        }
    }
    componentWillUnmount() {
        document.body.classList.remove("background");
        document.body.classList.remove("background-main-pages");
    }

    dataListRender() {
        var facility_id = localStorage.getItem('facility_id');
        facility_id = dataCrypto.decrypt(facility_id);
        var data_upload = new FormData();
        data_upload.append('facility_id', facility_id);
        data_upload.append('template_id', 'about');
        axios.post(REST_API_END_POINT + 'website/get-page-template-by-alias', data_upload).then((res) => {
            if (res.data.status == 1) {
                this.setState({ template_data: res.data.data, blocking: false });
            } else {
                this.setState({ blocking: false });
            }
        }).catch(e => {
            console.log("Addition failed , Error ", e)
            this.setState({ blocking: false });
        });
    }

    render() {

        const { template_data } = this.state;
        var content_text = '';
        var banner_image = '';
        var page_name = '';
        var page_sub_title = '';
        if (template_data != null) {
            let content_text_row = (template_data.content.find(r => { return r.key == 'content_text' }));
            if (content_text_row != null) {
                content_text = content_text_row.value;
            }
            let banner_image_row = (template_data.content.find(r => { return r.key == 'banner_image' }));
            if (banner_image_row != null) {
                banner_image = banner_image_row.value;
            }
            page_name = template_data.name;
            page_sub_title = template_data.sub_title;
        }
        return (
            <Fragment>
                <ToastProvider placement="bottom-left">
                    <div className="main-pages-layout d-flex justify-content-between">
                        <main>
                            <header>
                                <h1>Flashcat Privacy Policy</h1>
                                <ul>
                                    <li>
                                        <h3>Introduction</h3>
                                        <h4>
                                            Flashcat is a delivery app that allows users to track the delivery of
                                            their packages. We collect and use personal information to provide and
                                            improve our services. This privacy policy explains what information we
                                            collect, how we use it, and your choices about your personal information.
                                        </h4>
                                    </li>
                                    <li>
                                        <h3>What information do we collect?</h3>
                                        <h4>
                                            We collect the following personal information from you:
                                            <ul>
                                                <li>Contact information: This includes your name, email address, phone
                                                    number, and mailing address.</li>
                                                <li>Device information: This includes your device's IP address, device
                                                    type, and operating system.</li>
                                                <li>Location information: This includes your current location and the
                                                    location of the package you are tracking.</li>
                                                <li>Usage information: This includes information about how you use our
                                                    app, such as the features you use and the pages you visit.</li>
                                            </ul>
                                        </h4>
                                    </li>
                                    <li>
                                        <h3>How do we use your information?</h3>
                                        <h4>
                                            We use your personal information to:
                                            <ul>
                                                <li>Provide and improve our services: We use your information to provide
                                                    you with the Flashcat app and to improve our services. This includes
                                                    using your information to track the delivery of your packages, send you
                                                    notifications about your deliveries, and troubleshoot any problems you
                                                    may have with the app.</li>
                                                <li>Personalize your experience: We use your information to personalize
                                                    your experience with the Flashcat app. This includes showing you relevant
                                                    content and ads, and suggesting features that you may be interested in.</li>
                                                <li>Market to you: We may use your information to send you marketing emails
                                                    about our products and services. You can opt out of receiving marketing
                                                    emails at any time.</li>
                                                <li>Improve our security: We use your information to improve the security of
                                                    our app and to prevent fraud.</li>
                                                <li>Comply with the law: We may use your information to comply with applicable
                                                    laws and regulations.</li>
                                            </ul>
                                        </h4>
                                    </li>
                                    <li>
                                        <h3>How do we use your information?</h3>
                                        <h4>
                                            We use your personal information to:
                                            <ul>
                                                <li>Provide and improve our services: We use your
                                                    information to provide you with the Flashcat appandtoimprove our services. This includes using your informationto track the delivery of your packages, to sendyounotifications about your deliveries, and to troubleshoot
                                                    any problems you may have with the app.</li>
                                                <li>Personalize your experience: We use your
                                                    information to personalize your experience withtheFlashcat app. This includes showing you relevant contentand ads, and suggesting features that you may beinterested in..</li>
                                                <li>Market to you: We may use your informationtosendyou marketing emails about our products andservices. You can opt out of receiving marketing emails at anytime.</li>
                                                <li>Improve our security: We use your informationtoimprove the security of our app and to prevent fraud.</li>
                                                <li>Comply with the law: We may use your informationto comply with applicable laws and regulations.</li>
                                            </ul>
                                        </h4>
                                    </li>
                                    <li>
                                        <h3>Who do we share your information with?</h3>
                                        <h4>
                                            We may share your personal information with thefollowing third parties:
                                            <ul>
                                                <li>Our service providers: We may share your
                                                    information with our service providers who helpusprovide our services, such as our payment processorsand customer support team.</li>
                                                <li>Advertisers: We may share your informationwithadvertisers who want to show you relevant ads.</li>
                                                <li>Other third parties: We may share your informationwith other third parties if we have your consent or if weare required to do so by law.</li>

                                            </ul>
                                        </h4>
                                    </li>
                                    <li>
                                        <h3>How do we protect your information?</h3>
                                        <h4>
                                            We take steps to protect your personal information, suchas:
                                            <ul>
                                                <li>Using secure servers to store your information.</li>
                                                <li>Using encryption to protect your informationwhenit
                                                    is transmitted over the internet.</li>
                                                <li>Requiring our employees to keep your informationconfidential.</li>

                                            </ul>
                                        </h4>
                                    </li>
                                    <li>
                                        <h3>How long do we keep your information?</h3>
                                        <h4>
                                            We will keep your personal information for as longasit isnecessary to provide our services to you, to complywithour legal obligations, and to resolve disputes.

                                        </h4>
                                    </li>
                                    
                                    <li>
                                        <h3>Your rights</h3>
                                        <h4>
                                            You have the following rights with respect to your
                                            personal information:
                                       
                                        <ul>
                                        <li>Access: You have the right to access your personal
                                            information.</li>
                                        <li>Correction: You have the right to correct anyinaccuracies in your personal information.</li>
                                        <li>Deletion: You have the right to request that wedelete your personal information.</li>
                                        <li>Portability: You have the right to request that wetransfer your personal information to another organization.</li>
                                        <li>Objection: You have the right to object to theprocessing of your personal information.</li>
                                        <li>Withdraw consent: You have the right to withdrawyour consent to the processing of your personal
                                            information.</li>
                                        <li>To exercise any of these rights, please contact usat
                                            [email protected]</li>
                                            </ul>
                                            </h4>
                                    </li>
                                    <li>
                                        <h3>Changes to this privacy policy</h3>
                                        <h4>
                                            We may update this privacy policy fromtime totime. If wemake any material changes to this privacy policy, wewill
                                            notify you by email or through a prominent noticeonour
                                            website.
                                        </h4>

                                    </li>
                                    <li>
                                        <h3>Contact us</h3>
                                        <h4>
                                        If you have any questions about this privacy policy, please contact us at info@skyniche.com
                                        </h4>

                                    </li>
                                </ul>
                            </header>
                        </main>
                    </div>

                </ToastProvider>
            </Fragment >
        );
    }
}

const mapStateToProps = ({ authUser }) => {
    const { user, loading } = authUser;
    return { user, loading };
};

export default connect(
    mapStateToProps,
    {
        loginUser
    }
)(Privacy);
