import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class ComplexSales extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {		 
			reports:{},
			search: '',
			start_date: moment().startOf('day'),
			end_date: moment().endOf('day'),
			product_id: null,
			blocking: true,
			dataTableRefresh: true,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
	}
	
	handleChangeDate = date => {
		this.setState({
			start_date: date
		});
	}
	
	handleChangeEndDate = date => {
		this.setState({
			end_date: date
		});
	} 

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.start_date!=null){
			formData.append('start_date',moment(this.state.start_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('start_date','');
		}
		
		if(this.state.end_date!=null){
			formData.append('end_date',moment(this.state.end_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('end_date','');
		}
		
		axios.post(REST_API_END_POINT+'report/complex-sales',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				this.setState({
					reports: data.reports,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: {},
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		var that = this;
		this.setState({ dataTableRefresh:false  });
		setTimeout(function(){
			that.setState({ dataTableRefresh:true },()=>{
				that.$el = $(that.el);	
				that.$el.DataTable({
					dom: 'Bfrtip',
					bPaginate: false,
					bSort: false,
					aaSorting:[],
					buttons: [
						'csv', 'excel', {
						extend: 'pdf',
						orientation: 'landscape',
						pageSize: 'A4'} , 'print'
					]
				});
			});
		}, 300);
	}		
  
	render() {	
		const reports = this.state.reports;
		var total_sales_category={};
		total_sales_category['sales']=0;
		total_sales_category['quantity']=0;
		total_sales_category['gross_sales']=0;
		total_sales_category['discount']=0;
		total_sales_category['net_sales']=0;
		total_sales_category['cost']=0;
		total_sales_category['profit']=0;
		total_sales_category['profit_percent']=0;
		total_sales_category['percent_sales_by_qty']=0;
		total_sales_category['percent_sales_by_amt']=0;
		total_sales_category['percent_profit']=0;
		
		var p_total_sales_category={};
		p_total_sales_category['sales']=0;
		p_total_sales_category['quantity']=0;
		p_total_sales_category['gross_sales']=0;
		p_total_sales_category['discount']=0;
		p_total_sales_category['net_sales']=0;
		p_total_sales_category['cost']=0;
		p_total_sales_category['profit']=0;
		p_total_sales_category['profit_percent']=0;
		p_total_sales_category['percent_sales_by_qty']=0;
		p_total_sales_category['percent_sales_by_amt']=0;
		p_total_sales_category['percent_profit']=0;
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.complex-sales" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="5">
								<Label>Start date<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_date"
									name="start_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.start_date}
									onChange={this.handleChangeDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat="MM/DD/YYYY hh:mm a"
								/>
							</Colxx>
							
							<Colxx lg="5">
								<Label>End date<span className="text-danger">*</span></Label>
								<DatePicker
									id="end_date"
									name="end_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.end_date}
									onChange={this.handleChangeEndDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat="MM/DD/YYYY hh:mm a"
								/>
							</Colxx>
							<Colxx lg="2">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm> 	
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									{this.state.dataTableRefresh ? <table className="display table table-bordered table-striped" ref={el=>this.el=el} >
										<thead>
											<tr>
												<th> </th><th> </th><th> </th><th> </th><th> </th>
												<th> </th><th> </th><th> </th><th> </th><th> </th>
												<th> </th><th> </th><th> </th><th> </th>
											</tr>
										</thead>
										<tbody><tr>
												<td><b>Total Sales and Tax Breakdown</b></td>
												<td> </td><td> </td><td> </td>
												<td> </td><td> </td><td> </td>
												<td> </td><td> </td><td> </td>
												<td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 <tr>
												<td><b>Sales Tax</b></td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr><td>
												  Sales
												 </td>
												 <td>
												  {typeof reports['total_sales']!='undefined' ? (parseFloat(reports['total_sales'])-parseFloat(reports['total_taxes'])).toFixed(2):'0'}
												 </td>
												 <td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr><td>
												  Tax
												 </td>
												 <td>
												  {typeof reports['total_taxes']!='undefined' ? reports['total_taxes']:'0'}
												 </td>
												 <td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 <tr>
												<td><b>Tax Exempt</b></td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr><td>
												  Sales
												 </td>
												 <td>
												 {typeof reports['tax_exempt_total_sales']!='undefined' ? (parseFloat(reports['tax_exempt_total_sales'])-parseFloat(reports['tax_exempt_total_taxes'])).toFixed(2):'0'}
												 </td>
												 <td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr><td>
												  Tax
												 </td>
												 <td>
												 {typeof reports['tax_exempt_total_taxes']!='undefined' ? reports['tax_exempt_total_taxes']:'0'}
												 </td>
												 <td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 <tr>
												<td><b>Total Wholesale Sales and Tax Breakdown</b></td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 <tr>
												<td><b>Sales Tax</b></td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr><td>
												  Sales
												 </td>
												 <td>
												 {typeof reports['total_wholesales']!='undefined' ? (parseFloat(reports['total_wholesales'])-parseFloat(reports['total_wholetaxes'])).toFixed(2):'0'}
												 </td>
												 <td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr><td>
												  Tax
												 </td>
												 <td>
												 {typeof reports['total_wholetaxes']!='undefined' ? reports['total_wholetaxes']:'0'}
												 </td>
												 <td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 <tr>
												<td><b>Tax Exempt</b></td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td>Sales</td>
												 <td>{typeof reports['tax_exempt_total_wholesales']!='undefined' ? (parseFloat(reports['tax_exempt_total_wholesales'])-parseFloat(reports['tax_exempt_total_wholetaxes'])).toFixed(2):'0'}</td>
												 <td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												 <td>Tax</td>
												 <td>{typeof reports['tax_exempt_total_wholetaxes']!='undefined' ? reports['tax_exempt_total_wholetaxes']:'0'}</td>
												 <td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 <tr>
												<td><b>Total Wholesale Sales Placed on Account</b></td>
												<td>{typeof reports['wholesales_total']!='undefined' ? reports['wholesales_total']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 <tr>
												<td><b>Gross Sales</b></td>
												<td>{typeof reports['gross_sales']!='undefined' ? reports['gross_sales']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td><b>Total Tax</b></td>
												<td>{typeof reports['tax_total']!='undefined' ? reports['tax_total']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td><b>Total</b></td>
												<td>{typeof reports['total']!='undefined' ? reports['total']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 <tr>
												<td><b>Retail Cash In</b></td>
												<td>{typeof reports['retail_cash']!='undefined' ? reports['retail_cash']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td><b>Retail Credit Card In</b></td>
												<td>{typeof reports['retail_credit_card']!='undefined' ? reports['retail_credit_card']:'0'}</td>
												<td> </td><td> </td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 <tr>
												<td><b>Wholesale Cash In</b></td>
												<td>{typeof reports['whole_cash']!='undefined' ? reports['whole_cash']:'0'}</td>
												<td> </td><td> </td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td><b>Wholesale Credit Card In</b></td>
												<td>{typeof reports['whole_credit_card']!='undefined' ? reports['whole_credit_card']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 <tr>
												<td><b>Total Cash In</b></td>
												<td>{typeof reports['total_cash']!='undefined' ? reports['total_cash']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											</tr>
											 <tr>
												<td><b>Total Credit Card In</b></td>
												<td>{typeof reports['total_credit_card']!='undefined' ? reports['total_credit_card']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 <tr>
												<td><b>Total Cash Out</b></td>
												<td>{typeof reports['total_cashout']!='undefined' ? reports['total_cashout']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 <tr>
												<td><b>Cash Remaining</b></td>
												<td>{typeof reports['total_cash']!='undefined' ? (parseFloat(reports['total_cash'])-parseFloat(reports['total_cashout'])).toFixed(2):'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 
											{typeof reports['cash_close'] !='undefined' ? <Fragment>
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 
											 <tr>												
												<td><b>Drawer Name</b></td>
												<td><b>Assigned To</b></td>
												<td><b>Time Period</b></td>
												<td><b>Total Amount</b></td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											  </tr>
												{reports['cash_close'].map((row,i)=>{
													return(<tr key={i}>											  
													  <td>{row['drawer_name']}</td>
													  <td>{row['assigned_to']}</td>											  
													  <td>{moment(row['assigned_at']*1000).format('MM/DD/YYYY HH:mm')+' to '+moment(row['closed_at']*1000).format('MM/DD/YYYY HH:mm')}</td>	
													  <td>${row['total_amount']}</td>
													  <td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
													</tr>);
												})}
											</Fragment>:""}	
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 
											 <tr>
												<td><b>Total Deposit to Internal Bank</b></td>
												<td>{typeof reports['total_deposit']!='undefined' ? reports['total_deposit']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 
											 <tr>
												<td><b>Retail Transaction Count</b></td>
												<td>{typeof reports['retail_count']!='undefined' ? reports['retail_count']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 
											 <tr>
												<td><b>Wholesale Sale Transaction Count</b></td>
												<td>{typeof reports['wholesale_count']!='undefined' ? reports['wholesale_count']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 
											 <tr>
												<td><b>Ticket Summary</b></td>
												<td> </td><td> </td><td> </td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td>Total Number of Tickets</td>
												<td>{typeof reports['retail_count']!='undefined' ? reports['retail_count']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td>Number of Voided Tickets</td>
												<td>{typeof reports['void_count']!='undefined' ? reports['void_count']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td>Single Item Tickets</td>
												<td>{typeof reports['single_count']!='undefined' ? reports['single_count']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td>Multiple Item Tickets</td>
												<td>{typeof reports['multiple_count']!='undefined' ? reports['multiple_count']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td>Average Ticket Price</td>
												<td>{typeof reports['total_sales']!='undefined' && typeof reports['retail_count']!='undefined' && reports['total_sales']!='0.00' && reports['retail_count']!='0' ?  (parseFloat(reports['total_sales'])/parseFloat(reports['retail_count'])).toFixed(2):'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 
											 <tr>
												<td><b>Customers</b></td>
												<td> </td><td> </td><td> </td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td>New Customers</td>
												<td>{typeof reports['new_customer']!='undefined' ? reports['new_customer']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td>Returning Customers</td>
												<td>{typeof reports['old_customer']!='undefined' ? reports['old_customer']:'0'}</td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 
											{typeof reports['discounts']!='undefined' ? (Object.keys(reports['discounts']).length ? <Fragment>
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 
											 <tr>
												<td><b>Discount Summary</b></td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td><b>Discount Code</b></td>
												<td><b>Number of Discounts Provided</b></td>
												<td><b>Total Value of Discounts</b></td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 {Object.keys(reports['discounts']).map((coupon_code,i)=>{
												let row=reports['discounts'][coupon_code];
												return(<tr key={i}>											  
													  <td>{coupon_code}</td>
													  <td>{row['count']}</td>											  
													  <td>{row['discount']}</td>											  
													  <td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 	  </tr>)
											 })}
											</Fragment>:""):""}
											{typeof reports['sales_by_category']!='undefined' ? (reports['sales_by_category'].length ? <Fragment>
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 
											 <tr>
												<td><b>Sales Breakdown By Category</b></td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 <tr>
												<td><b>Category</b></td>
												<td><b>Sales</b></td>
												<td><b>Quantity</b></td>
												<td><b>Gross Sales</b></td>
												<td><b>Discount</b></td>
												<td><b>Net Sales</b></td>
												<td><b>Cost</b></td>
												<td><b>Profit</b></td>
												<td><b>Profit %</b></td>
												<td><b>% of Sales by Qty</b></td>
												<td><b>% of Sales by Amt</b></td>
												<td><b>% of Profit</b></td>
												<td><b>Average Price Per Unit</b></td>
												<td><b>Average Profit Per Unit</b></td>
											</tr>
											{reports['sales_by_category'].map((row,i)=>{
												total_sales_category['sales']+=parseFloat(parseFloat(row['sales']).toFixed(2));
												total_sales_category['quantity']+=parseFloat(parseFloat(row['quantity']).toFixed(2));
												total_sales_category['gross_sales']+=parseFloat(parseFloat(row['gross_sales']).toFixed(2));
												total_sales_category['discount']+=parseFloat(parseFloat(row['discount']).toFixed(2));
												total_sales_category['net_sales']+=parseFloat(parseFloat(row['net_sales']).toFixed(2));
												total_sales_category['cost']+=parseFloat(parseFloat(row['cost']).toFixed(2));
												total_sales_category['profit']+=parseFloat(parseFloat(row['profit']).toFixed(2));
												total_sales_category['percent_sales_by_qty']+=parseFloat(parseFloat(row['percent_sales_by_qty']).toFixed(2));
												total_sales_category['percent_sales_by_amt']+=parseFloat(parseFloat(row['percent_sales_by_amt']).toFixed(2));
												total_sales_category['percent_profit']+=parseFloat(parseFloat(row['percent_profit']).toFixed(2));
												
												return(<tr key={i}>											  
													  <td>{row['category']}</td>											  
													  <td>{row['sales']}</td>											  
													  <td>{parseFloat(row['quantity']).toFixed(2)}</td>											  
													  <td>{parseFloat(row['gross_sales']).toFixed(2)}</td>											  
													  <td>{parseFloat(row['discount']).toFixed(2)}</td>											  
													  <td>{parseFloat(row['net_sales']).toFixed(2)}</td>											  
													  <td>{parseFloat(row['cost']).toFixed(2)}</td>											  
													  <td>{parseFloat(row['profit']).toFixed(2)}</td>											  
													  <td>{parseFloat(row['profit_percent']).toFixed(2)}</td>											  
													  <td>{parseFloat(row['percent_sales_by_qty']).toFixed(2)}</td>											  
													  <td>{parseFloat(row['percent_sales_by_amt']).toFixed(2)}</td>											  
													  <td>{parseFloat(row['percent_profit']).toFixed(2)}</td>											  
													  <td>{parseFloat(row['avg_price_per_unit']).toFixed(2)}</td>											  
													  <td>{parseFloat(row['avg_profit_per_unit']).toFixed(2)}</td>								  
											      </tr>)
											})}
												 <tr>											  
													  <td><b>Total</b></td>											  
													  <td><b>{total_sales_category['sales'].toFixed(2)}</b></td>											  
													  <td><b>{total_sales_category['quantity'].toFixed(2)}</b></td>											  
													  <td><b>{total_sales_category['gross_sales'].toFixed(2)}</b></td>											  
													  <td><b>{total_sales_category['discount'].toFixed(2)}</b></td>											  
													  <td><b>{total_sales_category['net_sales'].toFixed(2)}</b></td>											  
													  <td><b>{total_sales_category['cost'].toFixed(2)}</b></td>											  
													  <td><b>{total_sales_category['profit'].toFixed(2)}</b></td>											  
													  <td><b>{(total_sales_category['profit']/total_sales_category['net_sales']*100).toFixed(2)}</b></td>											  
													  <td><b>{total_sales_category['percent_sales_by_qty'].toFixed(2)}</b></td>											  
													  <td><b>{total_sales_category['percent_sales_by_amt'].toFixed(2)}</b></td>											  
													  <td><b>{total_sales_category['percent_profit'].toFixed(2)}</b></td>	
													  <td> </td>
													  <td> </td>
											      </tr>
											</Fragment>:""):""}
											 
											{typeof reports['sales_by_category_detail']!='undefined' ? (Object.keys(reports['sales_by_category_detail']).length ? <Fragment>
											 <tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
											 
											 <tr>
												<td><b>Detailed Sales Breakdown by Category</b></td>
												<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
											 </tr>
											 {Object.keys(reports['sales_by_category_detail']).map((category,i)=>{
												let products = reports['sales_by_category_detail'][category];
												return(<Fragment key={i}>
												 <tr>
													<td><b>{category}</b></td>
													<td> </td><td> </td><td> </td>
													<td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td>
												 </tr>
												 <tr>
													<td><b>Product</b></td>
													<td><b>Sales</b></td>
													<td><b>Quantity</b></td>
													<td><b>Gross Sales</b></td>
													<td><b>Discount</b></td>
													<td><b>Net Sales</b></td>
													<td><b>Cost</b></td>
													<td><b>Profit</b></td>
													<td><b>Profit %</b></td>
													<td><b>% of Sales by Qty</b></td>
													<td><b>% of Sales by Amt</b></td>
													<td><b>% of Profit</b></td>
													<td><b>Average Price Per Unit</b></td>
													<td><b>Average Profit Per Unit</b></td>
												</tr>
													{products.map((row,ind)=>{
														p_total_sales_category['sales']+=parseFloat(parseFloat(row['sales']).toFixed(2));
														p_total_sales_category['quantity']+=parseFloat(parseFloat(row['quantity']).toFixed(2));
														p_total_sales_category['gross_sales']+=parseFloat(parseFloat(row['gross_sales']).toFixed(2));
														p_total_sales_category['discount']+=parseFloat(parseFloat(row['discount']).toFixed(2));
														p_total_sales_category['net_sales']+=parseFloat(parseFloat(row['net_sales']).toFixed(2));
														p_total_sales_category['cost']+=parseFloat(parseFloat(row['cost']).toFixed(2));
														p_total_sales_category['profit']+=parseFloat(parseFloat(row['profit']).toFixed(2));
														p_total_sales_category['percent_sales_by_qty']+=parseFloat(parseFloat(row['percent_sales_by_qty']).toFixed(2));
														p_total_sales_category['percent_sales_by_amt']+=parseFloat(parseFloat(row['percent_sales_by_amt']).toFixed(2));
														p_total_sales_category['percent_profit']+=parseFloat(parseFloat(row['percent_profit']).toFixed(2));
														
														return(<tr key={ind}>											  
															  <td>{row['product_name']}</td>											  
															  <td>{row['sales']}</td>											  
															  <td>{parseFloat(row['quantity']).toFixed(2)}</td>											  
															  <td>{parseFloat(row['gross_sales']).toFixed(2)}</td>											  
															  <td>{parseFloat(row['discount']).toFixed(2)}</td>											  
															  <td>{parseFloat(row['net_sales']).toFixed(2)}</td>											  
															  <td>{parseFloat(row['cost']).toFixed(2)}</td>											  
															  <td>{parseFloat(row['profit']).toFixed(2)}</td>											  
															  <td>{parseFloat(row['profit_percent']).toFixed(2)}</td>											  
															  <td>{parseFloat(row['percent_sales_by_qty']).toFixed(2)}</td>											  
															  <td>{parseFloat(row['percent_sales_by_amt']).toFixed(2)}</td>											  
															  <td>{parseFloat(row['percent_profit']).toFixed(2)}</td>											  
															  <td>{parseFloat(row['avg_price_per_unit']).toFixed(2)}</td>											  
															  <td>{parseFloat(row['avg_profit_per_unit']).toFixed(2)}</td>								  
														  </tr>)
													})}
													<tr>											  
														  <td><b>Total</b></td>											  
														  <td><b>{p_total_sales_category['sales'].toFixed(2)}</b></td>											  
														  <td><b>{p_total_sales_category['quantity'].toFixed(2)}</b></td>											  
														  <td><b>{p_total_sales_category['gross_sales'].toFixed(2)}</b></td>											  
														  <td><b>{p_total_sales_category['discount'].toFixed(2)}</b></td>											  
														  <td><b>{p_total_sales_category['net_sales'].toFixed(2)}</b></td>											  
														  <td><b>{p_total_sales_category['cost'].toFixed(2)}</b></td>											  
														  <td><b>{p_total_sales_category['profit'].toFixed(2)}</b></td>											  
														  <td><b>{(p_total_sales_category['profit']/p_total_sales_category['net_sales']*100).toFixed(2)}</b></td>											  
														  <td><b>{p_total_sales_category['percent_sales_by_qty'].toFixed(2)}</b></td>											  
														  <td><b>{p_total_sales_category['percent_sales_by_amt'].toFixed(2)}</b></td>											  
														  <td><b>{p_total_sales_category['percent_profit'].toFixed(2)}</b></td>	
														  <td> </td>
														  <td> </td>
													</tr>													
													<tr><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td><td> </td></tr>
												</Fragment>)
											})}												
											</Fragment>:""):""}</tbody>
									</table>:""}
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
