import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import DatePicker from "../../../components/common/CustomDateTimePicker";

import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import AddStrainModal from "../../../containers/inventory/AddStrainModal";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import moment from "moment";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { uuid } from 'uuidv4';

import ProductRow from "../../../components/receive-inventory/ProductRow";

import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
 

class CreateOrderModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.updateProductRow = this.updateProductRow.bind(this);
		this.getStrainsList = this.getStrainsList.bind(this);
	
		this.state = {
			selectVendor: null,	
			selectDriver: null,	
			product_row_id: null,	
			vendors:[],
			drivers:[],
			products:[],
			strains:[],
			ordered_date: moment(),
			due_date: null,
			product_rows:[],
			sub_total:"0",
			discount:"0",
			vat_amount:"0",
			grand_total:"0",
			delivery_own:"1"
		};
	
	}
	
	componentDidMount() {		
		var strains=[];
		var vendors=[];
		var drivers=[];
		var products=[];
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'receive-inventory/get-data-for-receive-order',formData).then((res)=>{
			var data = res.data;
		
			strains=data.strains;
			vendors=data.vendors;
			drivers=data.drivers;
			products=data.products;
			
			strains = strains.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			drivers = drivers.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			vendors = vendors.map((element,index)=>{
				element.label=element.vendor_name;
				element.value=element.vendor_id;
				element.key=index;
				return element;
			});
			
			products = products.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			var product_rows = [{
					id:uuid(),
					product_id:products.length ? products[0]:null,
					product_quantity:"",
					uom:null,
					variation_id:null,
					unit_cost:"",
					product_total:"",
					notes:"",
					on_consignment:false,
					strain_id:null,
					batches:[
						{
							id:uuid(),
							batch_number:"",
							harvest_date:null,
							package_date:null,
							exp_date:null,
							package_uid:data.package_barcode,
							gross_pkg:"",
							rcvd_total:"",
							net_mmj:"",
							rcvd_amt_of_flower:"",
						}
					]
				}];
			
			this.setState({
				strains,
				vendors,
				drivers,
				products,
				product_rows
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(!this.props.modalOpen){				
            if(JSON.stringify(this.state.strains)!==JSON.stringify(this.props.strains)) {				
				var strains = this.props.strains;
				this.setState({ 
					strains
				});
            }
        }
    }
	
	getStrainsList(product_row_id=null) {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'inventory/get-strains-list',formData).then((res)=>{
			var data = res.data;
			
			var strains=data.strains;
			strains = strains.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			var product_rows = this.state.product_rows;
			
			if(product_row_id!=null){
				product_rows = product_rows.map(row=>{
					if(row.id==product_row_id && strains.length){
						row.strain_id=strains[0];
					}
					return row;
				})
			}
				
			this.setState({			
				strains:strains,
				product_rows
			});
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleChangeDate = date => {
		this.setState({
			ordered_date: date
		});
	};
	
	handleChangeExpectedDate = date => {
		this.setState({
			due_date: date
		});
	};
	
	toggleModalAdd = (e,product_row_id=null) => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd,
		  product_row_id
		});
	};
	
	addProductRow = (value,id) => {
		var product_rows = this.state.product_rows;
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'receive-inventory/generate-package-id',formData).then((res)=>{
			var data = res.data;
			
			product_rows.push({
					id:uuid(),
					product_id:this.state.products.length ? this.state.products[0]:null,
					product_quantity:"",
					uom:null,
					variation_id:null,
					unit_cost:"",
					product_total:"",
					notes:"",
					on_consignment:false,
					strain_id:null,
					batches:[
						{
							id:uuid(),
							batch_number:"",
							harvest_date:null,
							package_date:null,
							exp_date:null,
							package_uid:data.package_barcode,
							gross_pkg:"",
							rcvd_total:"",
							net_mmj:"",
							rcvd_amt_of_flower:"",
						}
					]
				});
				
			this.setState({
				product_rows: product_rows
			});
		
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});		
	}
	
	updateProductRow = (value) => {
		var product_rows = this.state.product_rows;
		var sub_total = 0;
		var grand_total = 0;
		product_rows = product_rows.map(row=>{
			if(row.id===value.id){
				row = value;
			}
			if(row.product_total!=""){
				sub_total+=parseFloat(row.product_total)
			}
			return row;
		});
		
		sub_total = sub_total.toFixed(2);
		
		let discount = 0;
		if(this.state.discount!=""){
			discount = parseFloat(this.state.discount);
		}
		
		let vat_amount = 0;
		if(this.state.vat_amount!=""){
			vat_amount = parseFloat(this.state.vat_amount);
		}
		
		grand_total = parseFloat(sub_total) - discount + vat_amount;
				
		this.setState({
			product_rows,
			sub_total,
			grand_total
		});
	}
	
	removeProductRow = (id) => {
		var product_rows = this.state.product_rows;
		var sub_total = 0;
		var grand_total = 0;
		product_rows = product_rows.filter(row=>{ return row.id!=id });
		product_rows = product_rows.map(row=>{
			if(row.product_total!=""){
				sub_total+=parseFloat(row.product_total)
			}
			return row;
		});
		
		sub_total = sub_total.toFixed(2);
		
		let discount = 0;
		if(this.state.discount!=""){
			discount = parseFloat(this.state.discount);
		}
		
		let vat_amount = 0;
		if(this.state.vat_amount!=""){
			vat_amount = parseFloat(this.state.vat_amount);
		}
		
		grand_total = parseFloat(sub_total) - discount + vat_amount;
		
		this.setState({
			product_rows,
			sub_total,
			grand_total
		});
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {	
			
			let due_date = document.getElementById("due_date").value.trim(); 
			let errorMessage='';

			if(this.state.selectVendor==null){
				errorMessage="Please choose the vendor";
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			if(this.state.selectDriver==null && values.delivery_own==1){
				errorMessage="Please choose the driver";
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			if(due_date.length==0){
				errorMessage="Please select expected date";
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			if(this.state.product_rows.length==0){
				errorMessage="Please add at least one row";
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			document.getElementById("create-order").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("orderForm"));
			
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'receive-inventory/add-order',formData).then((res)=>{
				var data = res.data;
				document.getElementById("create-order").disabled=false;
				if(data.status==1){
					var api_message=document.getElementById("api_message");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					this.props.toggleModal();					
					this.props.dataListRender();
					
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var formData = new FormData();
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'receive-inventory/generate-package-id',formData).then((res)=>{
						var product_rows = [{
							id:uuid(),
							product_id:this.state.products.length ? this.state.products[0]:null,
							product_quantity:"",
							uom:null,
							variation_id:null,
							unit_cost:"",
							product_total:"",
							notes:"",
							on_consignment:false,
							strain_id:null,
							batches:[
								{
									id:uuid(),
									batch_number:"",
									harvest_date:null,
									package_date:null,
									exp_date:null,
									package_uid:data.package_barcode,
									gross_pkg:"",
									rcvd_total:"",
									net_mmj:"",
									rcvd_amt_of_flower:"",
								}
							]
						}];
						
						this.setState({
							product_rows,
							selectVendor: null,
							selectDriver: null,
							ordered_date: moment(),
							due_date: null,
							sub_total:"0",
							discount:"0",
							vat_amount:"0",
							grand_total:"0",
							delivery_own:"1"
						});
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
					});
					
				}else{
					swal("Warning!",data.msg,'warning');
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("create-order").disabled=false;
			});

		}
	}
	
	render() {	
		const { vendors, products, strains, product_rows, drivers } = this.state;
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  parseInt(dataCrypto.decrypt(facility_id));
		return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right modal-right-lg"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Create Order
			</ModalHeader>
			<AvForm
				id="orderForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			>
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Title<span className="text-danger">*</span></Label>
							  <AvField
								id="title"
								name="title"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "Please enter the title"
								  },
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Select Vendors<span className="text-danger">*</span>{facility_id==12 ? <span className="ml-2">(<strong>Com</strong>: Company, <strong>Ind</strong>: Individual)</span>:""}</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="vendor_id"
									id="vendor_id"
									value={this.state.selectVendor}						
									options={vendors}
									onChange={selectVendor => { this.setState({ selectVendor }) }}
									required
								/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Vendor License Number<span className="text-danger">*</span></Label>
								<AvField
									id="vendor_license"
									name="vendor_license"
									type="text"
									autoComplete="off"
									readOnly={true}
									value={this.state.selectVendor!=null ? this.state.selectVendor.license:null}
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required"
									  }
									}}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Vendor Transaction ID</Label>
								<AvField
									id="transaction_id"
									name="transaction_id"
									autoComplete="off"
									type="text"
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					
					<Row className="mb-2">
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Transportation Information</Label>
								<AvRadioGroup
									inline={true}
									name="delivery_own"
									validate={{
										required: {
											value: true,
											errorMessage: "Please select any of the options"
										}
									}}
									value={this.state.delivery_own}
								>	
									<AvRadio customInput label="Own Transporter" value="1" onClick={e=>{ this.setState({ delivery_own:e.target.value }) }} />
									<AvRadio customInput label="Other" value="2" onClick={e=>{ this.setState({ delivery_own:e.target.value }) }} />
								</AvRadioGroup>
							</AvGroup>
						</Colxx>
					</Row>
					
					{this.state.delivery_own==1 ? <Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Select Driver<span className="text-danger">*</span></Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="driver_id"
									id="driver_id"
									value={this.state.selectDriver}						
									options={drivers}
									onChange={selectDriver => { this.setState({ selectDriver }) }}
									required
								/>
							</AvGroup>
						</Colxx>
					</Row>:<Fragment>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Transporter Facility License Number<span className="text-danger">*</span></Label>
									<AvField
										id="transporter_facility_license_number"
										name="transporter_facility_license_number"
										autoComplete="off"
										type="text"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter transporter facility license number"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Driver Name<span className="text-danger">*</span></Label>
									<AvField
										id="delivered_by"
										name="delivered_by"
										autoComplete="off"
										type="text"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter driver name"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Driver Occupational License Number<span className="text-danger">*</span></Label>
									<AvField
										id="driver_occupational_license_number"
										name="driver_occupational_license_number"
										autoComplete="off"
										type="text"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter driver occupational license number"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Driver License Number<span className="text-danger">*</span></Label>
									<AvField
										id="driver_license_number"
										name="driver_license_number"
										autoComplete="off"
										type="text"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter driver license number"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Phone Number For Questions<span className="text-danger">*</span></Label>
									<AvField
										id="phone_number_for_questions"
										name="phone_number_for_questions"
										autoComplete="off"
										type="text"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter phone number"
											},
											pattern: {
												value: "^[0-9]{3}[0-9]{3}[0-9]{4}$",
												errorMessage: "Invalid phone number"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Vehicle Make<span className="text-danger">*</span></Label>
									<AvField
										id="vehicle_make"
										name="vehicle_make"
										autoComplete="off"
										type="text"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter vehicle make"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Vehicle Model<span className="text-danger">*</span></Label>
									<AvField
										id="vehicle_model"
										name="vehicle_model"
										autoComplete="off"
										type="text"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter vehicle make"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Vehicle License Plate Number<span className="text-danger">*</span></Label>
									<AvField
										id="vehicle_license_plate_number"
										name="vehicle_license_plate_number"
										autoComplete="off"
										type="text"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter vehicle license plate number"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
					</Fragment>}
					
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Date Received<span className="text-danger">*</span></Label>
								<DatePicker
									id="ordered_date"
									name="ordered_date"
									autoComplete="off"
									selected={this.state.ordered_date}
									onChange={this.handleChangeDate}
									placeholderText=""
									dateFormat="MM/DD/YYYY"
									maxDate={moment()}
								  />
							</AvGroup>
						</Colxx>					
					</Row>
					
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Date Expected<span className="text-danger">*</span></Label>
								<DatePicker
									id="due_date"
									name="due_date"
									autoComplete="off"
									selected={this.state.due_date}
									onChange={this.handleChangeExpectedDate}
									placeholderText=""
									dateFormat="MM/DD/YYYY"
									minDate={moment()}
								  />
							</AvGroup>
						</Colxx>					
					</Row>
					
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Status</Label>
								<AvField
									id="status"
									name="status"
									type="select"
									autoComplete="off"
									value="1"
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required"
									  }
									}}
								>
									<option value="1">Ordered</option>
									<option value="5">Received</option>
								</AvField>
							</AvGroup>
						</Colxx>					
					</Row>
					
					{this.state.products.length >0 ? product_rows.map((product,index)=>{
						return(<ProductRow 
							key={index}
							index={index}
							product_row={product}
							products={this.state.products} 
							strains={this.state.strains} 
							toggleModalAdd={this.toggleModalAdd}							
							updateProductRow={this.updateProductRow}							
							removeProductRow={this.removeProductRow}							
							product_rows_length={product_rows.length}							
						/>);
					}):<p className="text-danger">The inventory has no products. Please add products to inventory.</p>}
					
					<Row>
						<Colxx lg="12" className="mt-3 mb-3">
							<Button type="button" color="primary" onClick={this.addProductRow} >Add Row</Button>
						</Colxx>					
					</Row>
					
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Sub Total</Label>
								<AvField
									id="sub_total"
									name="sub_total"
									autoComplete="off"
									type="text"
									readOnly={true}
									value={this.state.sub_total}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					
					<Row>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
								<Label>Discount<span className="text-danger">*</span></Label>
								<AvField
									id="discount"
									name="discount"
									autoComplete="off"
									type="number"
									value={this.state.discount}
									onChange={e => {
										var sub_total=0;
										if(this.state.sub_total!=""){
											sub_total = parseFloat(this.state.sub_total);
										}
		
										let discount = 0;
										if(e.target.value!=""){
											discount = parseFloat(e.target.value);
										}
										
										let vat_amount = 0;
										if(this.state.vat_amount!=""){
											vat_amount = parseFloat(this.state.vat_amount);
										}
										
										var grand_total = sub_total - discount + vat_amount;
										
										this.setState({	discount, sub_total, grand_total });
									}}
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required"
									  },
									  min: {
										value: "0",
										errorMessage: "Value should be greater than or equal to 0"
									  }
									}}
								/>
							</AvGroup>
						</Colxx>					
					
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
								<Label>VAT<span className="text-danger">*</span></Label>
								<AvField
									id="vat_amount"
									name="vat_amount"
									autoComplete="off"
									type="number"
									value={this.state.vat_amount}
									onChange={e => {
										var sub_total=0;
										if(this.state.sub_total!=""){
											sub_total = parseFloat(this.state.sub_total);
										}
		
										let vat_amount = 0;
										if(e.target.value!=""){
											vat_amount = parseFloat(e.target.value);
										}
										
										let discount = 0;
										if(this.state.discount!=""){
											discount = parseFloat(this.state.discount);
										}
										
										var grand_total = sub_total - discount + vat_amount;
										
										this.setState({	vat_amount, sub_total, grand_total });
									}}
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required"
									  },
									  min: {
										value: "0",
										errorMessage: "Value should be greater than or equal to 0"
									  }
									}}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Grand Total</Label>
								<AvField
									id="grand_total"
									name="grand_total"
									autoComplete="off"
									type="text"
									value={this.state.grand_total}
									readOnly={true}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					
					
					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="create-order" >
						<IntlMessages id="customer.submit" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
			
			<AddStrainModal
				modalOpen={this.state.modalOpenAdd}
				product_row_id={this.state.product_row_id}
				toggleModal={this.toggleModalAdd}
				dataListRender={this.getStrainsList}
			/>
		</Modal>
	  );
  }
};

export default CreateOrderModal;
