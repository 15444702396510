import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Table
 } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import { REST_API_END_POINT, dataEncryptKey } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
  
export default class CouponModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			coupon_code:"",
			coupon_discount:0,
			applied_coupon_infos:[]
		};	
	}
	
	componentDidMount(){
		const payment_data = this.props.payment_data;
		var coupon_code = this.state.coupon_code;
		var coupon_discount = this.state.coupon_discount;
		
		if(payment_data!=null){
			coupon_code = payment_data['coupon_code'];
			coupon_discount = payment_data['coupon_discount'];
		}
		this.setState({	coupon_code, coupon_discount });
	}
	
	calculateDiscount = async ()=>{
		const payment_data = this.props.payment_data;
		const sub_total = this.props.sub_total;
		var applied_coupon_infos = this.state.applied_coupon_infos;
		var coupons = [];
		if(this.state.coupon_code!=''){
			coupons = this.state.coupon_code.split(',');		
		}
			
		var coupon_discount=0;
		var coupon_datas=[];
		for(let coupon of coupons){
			if(coupon!=''){
				let coupon_data = applied_coupon_infos.find(r=>{ return r.code==coupon }); 
				if(coupon_data==null){
					const user_id = localStorage.getItem('user_id');
					const customer_id = this.props.customer_id;
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					
					var formData = new FormData();
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);		
					formData.append('customer_id',customer_id);
					formData.append('coupon',coupon);
					await axios.post(REST_API_END_POINT+'point-of-sale/get-applied-coupon-detail',formData).then((res)=>{
						var data = res.data;
					
						if(data.status==1){
							coupon_data = data.coupon_data;
							applied_coupon_infos.push(coupon_data);
						}
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
					});
				}
				if(coupon_data!=null){
					if(coupon_data['is_product']=='1' && coupon_data['type']=='0'){					
						coupon_discount += parseFloat(coupon_data['amount']);
					}
					coupon_datas.push(coupon_data);
				}
			}			
		}
		
		
		if(coupon_datas.length){
			for(let coupon_data of coupon_datas){
				if(coupon_data['is_product']=='1' && coupon_data['type']=='1'){
					coupon_discount+=sub_total*parseFloat(coupon_data['amount'])/100;
				}
			}
		}
		
		if(sub_total<coupon_discount){
			coupon_discount=0;
		}
		if(coupon_discount==0){
			swal("Warning!","Sorry, this coupon is not applicable now","warning");
		}
		
		this.setState({	coupon_discount, applied_coupon_infos });
	}
	
	resetCoupon = () =>{
		var payment_data = this.props.payment_data;
		var coupon_code = "";
		var coupon_discount = 0;
				
		payment_data['coupon_code'] = coupon_code;
		payment_data['coupon_discount'] = coupon_discount;
		
		this.props.updatePaymentData(payment_data);
		this.props.recalculateTotals();
		this.setState({	coupon_discount, coupon_code });
	}
	
	async handleSubmit(event, errors, values) {		
		if (errors.length === 0){
			await this.calculateDiscount();
			
			var payment_data = this.props.payment_data;
			var coupon_code = this.state.coupon_code;
			var coupon_discount = this.state.coupon_discount;
			
			if(coupon_discount==0){
				coupon_code="";
			}
			
			document.getElementById("apply-coupon").disabled=true;
			
			payment_data['coupon_code'] = coupon_code;
			payment_data['coupon_discount'] = coupon_discount;
			
			this.props.updatePaymentData(payment_data);
			this.props.recalculateTotals();
			this.props.toggleModal();
		}
	}


	render() {
		const { coupon_discount } = this.state;
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Coupon Code
				</ModalHeader>
				<AvForm
					id="couponApplyForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<AvGroup>
									<Label>Code<span className="text-danger">*</span></Label>
									<AvField
										id="coupon_code"
										name="coupon_code"
										type="text"
										value={this.state.coupon_code}
										onChange={(e)=>{ this.setState({ coupon_code:e.target.value }) }}
										autoComplete="off"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the coupon code"
											}
										}}
									/>
								</AvGroup>
							</Colxx>					
						</Row>
						<Row>
							<Colxx xxs="12">
								<AvGroup >
									<Label>Discount</Label>
									<AvField
										id="coupon_discount"
										name="coupon_discount"
										type="number"
										readOnly={true}
										placeholder="0"
										value={coupon_discount.toFixed(2)}
									/>
								</AvGroup>
							</Colxx>
						</Row>
					</ModalBody>
					<ModalFooter className="justify-content-start">
						<Button color="primary" type="button" outline id="check-coupon" onClick={(e)=>{ this.calculateDiscount(); }}>
							<IntlMessages id="customer.check" />
						</Button>
						<Button color="primary" id="apply-coupon">
							<IntlMessages id="customer.apply" />
						</Button>{" "}
						<Button color="primary" type="button" outline onClick={this.resetCoupon}>
							<IntlMessages id="customer.reset" />
						</Button>{" "}
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}		  
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};
