import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input,  DropdownItem,CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import EditYieldModal from "../../../containers/equipments/EditYieldModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Alloperations extends Component {
	
	constructor(props) {
		super(props);
		this.dataListRender = this.dataListRender.bind(this);
		this.setEquipmentType = this.setEquipmentType.bind(this);
		
		this.state = {
			load_id:0,		 
			modalOpenAdd: false,
			modalOpenEdit: false,
			search: '',
			alloperations:[],
			equipment_type:0
		};
	}
	
	toggleModalEdit = (e,load_id=0) => {
		this.setState({
			load_id: parseInt(load_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};	
	
    componentDidMount(){
	   this.dataListRender();
	}
	
    setEquipmentType(equipment_type=0){
		this.setState({
			equipment_type: parseInt(equipment_type)
		},()=>{
			this.dataListRender();
		});
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('equipment_type',this.state.equipment_type);
		
		axios.post(REST_API_END_POINT+'processor/get-all-operations',formData).then((res)=>{
			var data = res.data;
		
			if(data.operations){
				this.setState({
					alloperations: data.operations,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					alloperations: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
    render() {
		let data = this.state.alloperations;
		if (this.state.search) {
			data = data.filter(row => {
				if(row.operated_by==null){
					row.operated_by="";
				}
				return row.equipment_name.toLowerCase().includes(this.state.search) || row.equipment_type_name.toLowerCase().includes(this.state.search) || row.batch_type.toLowerCase().includes(this.state.search) || row.batch_uid.toLowerCase().includes(this.state.search) || row.output_product.toLowerCase().includes(this.state.search) || row.yield.toLowerCase().includes(this.state.search) || row.operated_by.toLowerCase().includes(this.state.search)
			})
		}
		
	const dataTableColumns = [			
	  {
		Header: "Equipment Name",
		accessor: "equipment_name",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Equipment Type",
		accessor: "equipment_type_name",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Batch Type",
		accessor: "batch_type",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Batch UID",
		accessor: "batch_uid",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },	  	  
	  {
		Header: "Yield (Grams)",
		accessor: "output_product",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },	  	  
	  {
		Header: "Yield (%)",
		accessor: "yield",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },	  	  
	  {
		Header: "Operated By",
		accessor: "operated_by",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },	  	  
	  {
		Header: "Options",
		accessor: "load_id",
		Cell: props => props.original.is_crc=="0" ? <Button color="success" size="sm" className=""  onClick={(e)=>this.toggleModalEdit(e,props.value)}>Edit Yield</Button>:""
	  },

	];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.all-operations" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4"><br/>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
		   <Row>			  
				<Colxx xxs="12">			  
					<div className="float-sm-left">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  onClick={()=>this.setEquipmentType(0)}
							>
							 All							  
						</Button>																		
						   {" "}
						<Button
							color="primary"
							size="sm"
							className="mr-1"
							onClick={()=>this.setEquipmentType(1)}
						  >
							Extractor
						</Button>
						
						{/*<Button
							color="primary"
							size="sm"
							className="mr-1"
							onClick={()=>this.setEquipmentType(6)}
						  >
							CRC
						</Button>*/}
						<Button
							color="primary"
							size="sm"
							className="mr-1"
							onClick={()=>this.setEquipmentType(4)}
						  >
							Oven
						</Button>
						{/*<Button
							color="primary"
							size="sm"
							className="mr-1"
							onClick={()=>this.setEquipmentType(5)}
						  >
							MCP
						</Button>
						<Button
							color="primary"
							size="sm"
							className="mr-1"
							onClick={()=>this.setEquipmentType(2)}
						  >
							Evaporator
						</Button>
						<Button
							color="primary"
							size="sm"
							className="mr-1"
							onClick={()=>this.setEquipmentType(3)}
						  >
							Distillator
						</Button>*/}					  
					</div>
					
					<div className="mb-4 dropdown-edit">						
						<FormGroup row>
							<Colxx lg="12">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							filterable={false}
							showPageJump={false}
							responsive
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}
							defaultSorted={[{id: "send_on",desc: false}]}
						/>
					</div>					
				</Colxx>
				<EditYieldModal
					modalOpen={this.state.modalOpenEdit}
					toggleModal={this.toggleModalEdit}
					dataListRender={this.dataListRender}
					load_id={this.state.load_id}
				/>
			</Row>
		</Card>
      </Fragment>
    );
  }
}
