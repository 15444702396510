import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { Row,Button,Input } from "reactstrap";

import {Card, CardBody} from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import {
  CustomInput,
  Label,
  Collapse,
  InputGroup,
  InputGroupAddon
} from "reactstrap";


import {Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import swal from 'sweetalert';

import { CalendarToolbar } from "../../../components/CalendarToolbar";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT, JQUERY_MODULE, dataEncryptKey} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const localizer = momentLocalizer(moment);


class Mycalendar extends Component {
	
	constructor(props) {
		super(props);
    
		this.state = {
			modalOptions: false,
			calendarData:[]
		}
	};
	
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		var user_id = localStorage.getItem('user_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'staff/my-calendar-data',formData).then((res)=>{
			var data = res.data;
			if(data.status==1){
				this.setState({calendarData:data.scheduler});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
    }
	
  render() {
    //const {messages} = this.props.intl;
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.calendar" match={this.props.match}/>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>			
            <Colxx lg="12"  className="mb-4">
				<Card>
					<CardBody>
						<Calendar
							selectable
							popup={true}
							startAccessor="start"
							endAccessor="end"
							localizer={localizer}
							style={{ minHeight: "800px" }}
							events={this.state.calendarData}
							views={{"month":true,"week":true}}
							tooltipAccessor ="desc"
							className="dashboard_calender"
							onSelectSlot={()=>{ }}
							components={{
								toolbar: CalendarToolbar
							}}
						/>
					</CardBody>
				</Card>
			</Colxx>			 
        </Row>
        
      </Fragment>
    );
  }
}

export default injectIntl(Mycalendar);