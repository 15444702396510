import React, { Component,Fragment } from "react";
import { Row, FormGroup, Input } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

import TooltipItemText from "../../components/common/TooltipItemText";

export default class DealGrid extends Component {
	constructor(props) {
		super();
		this.state = {
			search:''
		}
		
		this.addToCart = this.addToCart.bind(this);
	}
	
	addToCart(product_package){
		product_package = JSON.parse(JSON.stringify(product_package));
		var values={};		
		values.product_id="";
		values.deal_id=product_package.deal_id;
		values.product_price=product_package.price;
		values.product_unit_price=product_package.amount;
		values.exise_tax=product_package.exise_tax;
		values.sales_tax=product_package.sales_tax;
		values.quantity=1;
		values.variation_id=product_package.variation_id;
		values.including_tax=product_package.including_tax;
		values.amount_of_flower=product_package.amount_of_flower;
		
		values.package_id=product_package.package_id;
		values.is_finished=1;
		values.name=product_package.name;
		values.real_stock=Math.min(...product_package.deal_products.map(r=>parseInt(r.stock)));
		values.deal_products = JSON.stringify(product_package.deal_products.map(r=>{ 
			delete r.stock;
			r.quantity = parseInt(r.quantity);
			return r; 
		}));
		
		this.props.addToCart(values);		
	}
	
	render() {
		const{ products, currency_rate, currency_symbol, index } = this.props;
		
		var final_products = products;		
		if (this.state.search) {
			final_products = final_products.filter(row => {
				return  row.name.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		return (<Fragment>
				<div className="mb-2 p-2 product-item-search dropdown-edit">						
					<FormGroup row className="mb-0">
						<Colxx lg="12">
							<Input
								type="text"
								name={"product_search"+index}
								id={"product_search"+index}
								placeholder="Search"
								value={this.state.search}
								onChange={e => this.setState({search: e.target.value.toLowerCase()})}
							/>
						</Colxx>
					</FormGroup>
				</div>
				<PerfectScrollbar
				  option={{ suppressScrollX: true, wheelPropagation: false }}
				>
					<div className="d-flex flex-wrap">
					{final_products.map((row,index)=>{
						return(<Colxx md="3" xxl="2" className="text-center product-item" key={index} onClick={(e)=>{ this.addToCart(row); }}>
							<div>
								<img src={row.thumbnail_url} className="product-thumnail img-responsive" />	
								<p className="mt-1 mb-1 product-item-name">{row.name.toUpperCase()}</p>
								<p className="mb-0 text-danger font-weight-bold">{currency_symbol+" "+(parseFloat(row.amount*currency_rate).toFixed(2))}</p>
								<div className="text-center">
									<TooltipItemText key={"tip_deal_"+index} item={{
									  className:'mr-1 btn btn-primary btn-xs round-tip ',
									  placement: "bottom",
									  text: "?",
									  body: <p className="text-left">Product: {row.product_name}<br/>Description:<br/>{row.description}</p>
									}} id={"tip_deal_"+index} />
								</div>
							</div>
						</Colxx>)
					})}					
					</div>
				</PerfectScrollbar>
			</Fragment>);
	}
}
