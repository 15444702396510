import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { AvField, AvGroup } from "availity-reactstrap-validation";
import {	
  CustomInput,
  Button,
  Label,FormGroup,
  Card, 
} from "reactstrap";
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, LBS_CONSTANT } from "../../constants/defaultValues";
import axios from 'axios';
import { uuid } from 'uuidv4';
import moment from "moment";

import DatePicker from "../../components/common/CustomDateTimePicker";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const qty_types = [
	{id:1, name:'Grams'},
	{id:2, name:'Lbs'},
	{id:3, name:'Units'}
			];

export default class ProductRow extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			product_row: this.props.product_row,
			product_variations:[],
		};
	}
	
	componentDidMount() {
		var product_row = this.state.product_row;
		
		if(product_row.product_id!=null){		
			this.getProductVariations(product_row.product_id);
		}
		
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
		if (JSON.stringify(prevProps.product_row) !== JSON.stringify(this.props.product_row) && JSON.stringify(this.state.product_row) !== JSON.stringify(this.props.product_row)){		
			this.setState({ product_row:this.props.product_row });
			this.getProductVariations(this.props.product_row.product_id);
		}
	}
	
	getProductVariations = product_id => {
		if(product_id!=null){
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('product_id',product_id.value);
			
			axios.post(REST_API_END_POINT+'receive-inventory/get-product-variations',formData).then((res)=>{
				var data = res.data;
				
				var product_variations=data.variations;
				product_variations = product_variations.map((element,index)=>{
					element.label=element.variation_name;
					element.value=element.variation_id;
					element.key=index;
					return element;
				});
				
				this.setState({			
					product_variations,
				});
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}
	}
	
	handleProductChange = product_id => {
		var product_row = this.state.product_row;
		product_row.product_id = product_id;
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'receive-inventory/generate-package-id',formData).then((res)=>{
			var data = res.data;
			
			product_row.variation_id=null;
			product_row.batches=[
						{
							id:uuid(),
							batch_number:"",
							harvest_date:null,
							package_date:null,
							exp_date:null,
							package_uid:data.package_barcode,
							gross_pkg:"",
							rcvd_total:"",
							net_mmj:"",
							rcvd_amt_of_flower:"",
						}
					];
			
			this.setState({
				product_row
			});
			
			this.props.updateProductRow(product_row);		
			this.getProductVariations(product_row.product_id);
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});		
	};
	
	addBatch = () => {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'receive-inventory/generate-package-id',formData).then((res)=>{
			var data = res.data;
			var product_row = this.state.product_row;
			
			product_row.batches.push({
							id:uuid(),
							batch_number:"",
							harvest_date:null,
							package_date:null,
							exp_date:null,
							package_uid:data.package_barcode,
							gross_pkg:"",
							rcvd_total:"",
							net_mmj:"",
							rcvd_amt_of_flower:"",
						});
			
			this.setState({
				product_row
			});
			
			this.props.updateProductRow(product_row);
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	removeBatch = (id) => {
		var product_row = this.state.product_row;
		
		product_row.batches = product_row.batches.filter(row=>{ return row.id!=id });
		
		this.setState({
			product_row
		});
		
		this.props.updateProductRow(product_row);
	}
	
  
	render() {
		var product_row = this.state.product_row;
		var product_variations = this.state.product_variations;
		const { products, strains, index } = this.props;
		
		var uom = null;
		var product_type=1;
		if(product_row.product_id!=null){
			uom = qty_types.find(r=>{ return r.id==parseInt(product_row.product_id.qty_type) });
			product_type=parseInt(product_row.product_id.product_type);
		}
		
		return( 
			<Card className="p-3 border-radius-7 mb-2">
				<Row>
					<Colxx md="3" className="pr-0">
						<AvGroup >
							<Label>Product<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name={"product_id["+index+"]"}
								id={"product_id_"+index}
								value={this.state.product_row.product_id}						
								options={products}
								onChange={selectProduct => { this.handleProductChange(selectProduct) }}
								required
							/>
						</AvGroup>
					</Colxx>
					
					<Colxx md="2" className="pr-0">
						<AvGroup>
							<Label>Quantity</Label>
							<AvField
								id={"product_quantity_"+index}
								name={"product_quantity["+index+"]"}
								autoComplete="off"
								type="number"
								value={this.state.product_row.product_quantity}
								onChange={e => { 
									var product_row = this.state.product_row;
									product_row.product_quantity = e.target.value;
									
									let product_quantity = 0;
									if(product_row.product_quantity!=""){
										product_quantity = parseFloat(product_row.product_quantity);
									}
									
									let unit_cost = 0;
									if(product_row.unit_cost!=""){
										unit_cost = parseFloat(product_row.unit_cost);
									}
									
									product_row.product_total = (product_quantity*unit_cost).toFixed(2);
									
									this.setState({	product_row	});			
									this.props.updateProductRow(product_row);
								}}							
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required"
								  },
								  min: {
									value: "0",
									errorMessage: "Please enter a value greater than 0"
								  },
								}}
							/>
						</AvGroup>
					</Colxx>
					<Colxx md="1" className="pr-0">
						<AvGroup>
							<Label>UOM</Label>
							<AvField
								id={"uom_"+index}
								name={"uom["+index+"]"}
								autoComplete="off"
								type="text"
								readOnly={true}
								value={uom!=null ? uom.name:""}
							/>
							<input type="hidden" name={"uom_id["+index+"]"} value={uom!=null ? uom.id:""} />
							<input type="hidden" name={"row_id["+index+"]"} value={index} />
							<input type="hidden" name={"on_consignment_"+index} value={this.state.product_row.on_consignment ? 1:0} />
						</AvGroup>
					</Colxx>
					<Colxx md="2" className="pr-0">
						<AvGroup >
							<Label>Variation{product_type==2 ? <span className="text-danger">*</span>:""}</Label>
							{/*<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name={"variation_id["+index+"]"}
							id={"variation_id"+index}
							options={product_variations}
							value={this.state.product_row.variation_id}
							onChange={selectVariation => { 
								var product_row = this.state.product_row;
								product_row.variation_id = selectVariation;
								this.setState({	product_row	});			
								this.props.updateProductRow(product_row);
							}}	
							required
							/>*/}
							<AvField
								id={"variation_id"+index}
								name={"variation_id["+index+"]"}
								autoComplete="off"
								type="select"
								value={this.state.product_row.variation_id!=null ? this.state.product_row.variation_id.value:""}
								onChange={e => {
									let  selectVariation = product_variations.find(v=>{ return v.variation_id==e.target.value });
									var product_row = this.state.product_row;
									product_row.variation_id = selectVariation;
									this.setState({	product_row	});			
									this.props.updateProductRow(product_row);
								}}							
								validate={product_type==2 ? {
								  required: {
									value: true,
									errorMessage: "This field is required"
								  }
								}:{}}
							>
							<option value="" >Select...</option>
							{product_variations.map((v,i)=>{
								return <option value={v.value} key={i}>{v.label}</option>;
							})}
							</AvField>
						</AvGroup>
					</Colxx>
					<Colxx md="2" className="pr-0">
						<AvGroup>
							<Label>Unit Cost<span className="text-danger">*</span></Label>
							<AvField
								id={"unit_cost_"+index}
								name={"unit_cost["+index+"]"}
								autoComplete="off"
								type="number"
								value={this.state.product_row.unit_cost}
								onChange={e => { 
									var product_row = this.state.product_row;
									product_row.unit_cost = e.target.value;
									
									let product_quantity = 0;
									if(product_row.product_quantity!=""){
										product_quantity = parseFloat(product_row.product_quantity);
									}
									
									let unit_cost = 0;
									if(product_row.unit_cost!=""){
										unit_cost = parseFloat(product_row.unit_cost);
									}
									
									product_row.product_total = (product_quantity*unit_cost).toFixed(2);
									
									this.setState({	product_row	});	
									this.props.updateProductRow(product_row);
								}}	
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required"
								  }
								}}
							/>
						</AvGroup>
					</Colxx>
					<Colxx md="2" >
						<AvGroup>
							<Label>Total<span className="text-danger">*</span></Label>
							<AvField
								id={"product_total_"+index}
								name={"product_total["+index+"]"}
								autoComplete="off"
								type="number"
								value={this.state.product_row.product_total}
								onChange={e => { 
									var product_row = this.state.product_row;
									product_row.product_total = e.target.value;
									
									let product_quantity = 0;
									if(product_row.product_quantity!=""){
										product_quantity = parseFloat(product_row.product_quantity);
									}
									
									let product_total = 0;
									if(product_row.product_total!=""){
										product_total = parseFloat(product_row.product_total);
									}
									
									if(product_quantity!=0){
										product_row.unit_cost = (product_total/product_quantity).toFixed(2);
									}
									
									this.setState({	product_row	});	
									this.props.updateProductRow(product_row);
								}}
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required"
								  }
								}}
							/>
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx md="4" className="pr-0">
						<AvGroup>
							<Label>Notes</Label>
							<AvField
								id={"notes"+index}
								name={"notes["+index+"]"}
								autoComplete="off"
								type="text"
								value={this.state.product_row.notes}
								onChange={e => { 
									var product_row = this.state.product_row;
									product_row.notes = e.target.value;
									
									this.setState({	product_row	});			
									this.props.updateProductRow(product_row);
								}}
							/>
						</AvGroup>
					</Colxx>
					<Colxx md="2" className="pr-0">
						<AvGroup>
							<Label>On Consignment</Label><br/>
							<Label>													  
								<Switch
									className="custom-switch custom-switch-primary im-inline-block"
									checked={this.state.product_row.on_consignment}
									onChange={status => { 
										var product_row = this.state.product_row;
										product_row.on_consignment = !product_row.on_consignment;
										this.setState({ product_row });
										this.props.updateProductRow(product_row);
									}}
								/>
							</Label>
						</AvGroup>
					</Colxx>
					<Colxx md="2" className={"pr-0 "+(product_type==1 ? "":"d-none")}>
						<AvGroup >
							<Label>Strain</Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"
								placeholder="--Select Strain--"
								name={"strain_id["+index+"]"}
								id={"strain_id"+index}
								options={strains}
								value={this.state.product_row.strain_id}
								onChange={selectStrain => { 
									var product_row = this.state.product_row;
									product_row.strain_id = selectStrain;
									this.setState({	product_row	});			
									this.props.updateProductRow(product_row);
								}}
								
							/>
						</AvGroup>
					</Colxx>
					<Colxx md="4" className="pr-0">
						<Label className="d-block w-100">&nbsp;</Label>
						{this.props.product_rows_length!=1 ? <Button type="button" size="sm" color="danger" className="mr-2" onClick={()=>{ this.props.removeProductRow(product_row.id); }} >Remove Row</Button>:""}
						<Button type="button" size="sm" color="primary" className="mr-2" onClick={this.addBatch} >Add Batch</Button>
						{product_type==1 ? <Button type="button" size="sm" color="primary" className="mr-2" onClick={(e)=>{ this.props.toggleModalAdd(e,this.state.product_row.id) }} >Add Strain</Button>:""}
					</Colxx>
				</Row>
				
				{product_row.batches.map((row,i)=>{
					return(<Card className="p-3 ml-4 border-radius-7 mb-2" key={i}>
						<Row>
							<Colxx md="4" className="pr-0">
								<AvGroup>
									<Label>Batch #</Label>
									<AvField
										id={"batch_number_row_"+index+"_"+i}
										name={"batch_number_row_"+index+"["+i+"]"}
										autoComplete="off"
										value={row.batch_number}
										onChange={e => { 
											var product_row = this.state.product_row;
											product_row.batches = product_row.batches.map(r=>{
												if(r.id==row.id){
													r.batch_number=e.target.value;
												}
												return r;
											});
											this.setState({	product_row	});			
											this.props.updateProductRow(product_row);
										}}										
										type="text"
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx md="3" className={"pr-0 "+(product_type==1 ? "":"d-none")}>
								<AvGroup>
									<Label>Harvest Date</Label>
									<DatePicker
										id={"harvest_date_row_"+index+"_"+i}
										name={"harvest_date_row_"+index+"["+i+"]"}
										autoComplete="off"
										selected={row.harvest_date}
										onChange={date => { 
											var product_row = this.state.product_row;
											product_row.batches = product_row.batches.map(r=>{
												if(r.id==row.id){
													r.harvest_date=date;
												}
												return r;
											});
											this.setState({	product_row	});			
											this.props.updateProductRow(product_row);
										}}
										placeholderText=""
										dateFormat="MM/DD/YYYY"
										maxDate={moment()}
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx md="3" className="pr-0">
								<AvGroup>
									<Label>Package Date</Label>
									<DatePicker
										id={"package_date_row_"+index+"_"+i}
										name={"package_date_row_"+index+"["+i+"]"}
										autoComplete="off"
										selected={row.package_date}
										onChange={date => { 
											var product_row = this.state.product_row;
											product_row.batches = product_row.batches.map(r=>{
												if(r.id==row.id){
													r.package_date=date;
												}
												return r;
											});
											this.setState({	product_row	});			
											this.props.updateProductRow(product_row);
										}}
										placeholderText=""
										dateFormat="MM/DD/YYYY"
										maxDate={moment()}
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx md={product_type==1 ? "2":"3"} >
								<AvGroup>
									<Label>Exp Date</Label>
									<DatePicker
										id={"exp_date_row_"+index+"_"+i}
										name={"exp_date_row_"+index+"["+i+"]"}
										autoComplete="off"
										selected={row.exp_date}
										onChange={date => { 
											var product_row = this.state.product_row;
											product_row.batches = product_row.batches.map(r=>{
												if(r.id==row.id){
													r.exp_date=date;
												}
												return r;
											});
											this.setState({	product_row	});			
											this.props.updateProductRow(product_row);
										}}
										placeholderText=""
										dateFormat="MM/DD/YYYY"

									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx md="2" className="pr-0">
								<AvGroup>
									<Label>Package ID</Label>
									<AvField
										id={"package_uid_row_"+index+"_"+i}
										name={"package_uid_row_"+index+"["+i+"]"}
										autoComplete="off"
										value={row.package_uid}
										readOnly={true}
										type="text"
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx md="2" className="pr-0">
								<AvGroup>
									<Label>{product_row.variation_id==null && product_row.product_id.qty_type!="3" ? "Gross Weight":"Quantity"}<span className="text-danger">*</span></Label>
									<AvField
										id={"gross_pkg_row_"+index+"_"+i}
										name={"gross_pkg_row_"+index+"["+i+"]"}
										autoComplete="off"
										value={row.gross_pkg}
										type="number"
										onChange={e => { 
											var product_row = this.state.product_row;
											product_row.batches = product_row.batches.map(r=>{
												if(r.id==row.id){
													r.gross_pkg=e.target.value;
												}
												return r;
											});
											this.setState({	product_row	});			
											this.props.updateProductRow(product_row);
										}}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required"
											},
											min: {
												value: "0",
												errorMessage: "Please enter a value greater than 0"
											},
										}}
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx md="2" className={"pr-0 "+(product_row.variation_id==null && product_row.product_id.qty_type!="3" ? "":"d-none")}>
								<AvGroup>
									<Label>Net Weight<span className="text-danger">*</span></Label>
									<AvField
										id={"net_mmj_row_"+index+"_"+i}
										name={"net_mmj_row_"+index+"["+i+"]"}
										autoComplete="off"
										value={product_row.variation_id==null && product_row.product_id.qty_type!="3" ? row.net_mmj:"0"}
										type="number"
										onChange={e => { 
											var product_row = this.state.product_row;
											product_row.batches = product_row.batches.map(r=>{
												if(r.id==row.id){
													r.net_mmj=e.target.value;
												}
												return r;
											});
											this.setState({	product_row	});			
											this.props.updateProductRow(product_row);
										}}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required"
											},
											min: {
												value: "0",
												errorMessage: "Please enter a value greater than 0"
											},
										}}
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx md="2" className="pr-0">
								<AvGroup>
									<Label>{product_row.variation_id==null && product_row.product_id.qty_type!="3" ? "Label Weight":"Received Quantity"}<span className="text-danger">*</span></Label>
									<AvField
										id={"rcvd_total_row_"+index+"_"+i}
										name={"rcvd_total_row_"+index+"["+i+"]"}
										autoComplete="off"
										value={row.rcvd_total}
										type="number"
										onChange={e => { 
											var product_row = this.state.product_row;
											product_row.batches = product_row.batches.map(r=>{
												if(r.id==row.id){
													r.rcvd_total=e.target.value;
												}
												return r;
											});
											this.setState({	product_row	});			
											this.props.updateProductRow(product_row);
										}}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required"
											},
											min: {
												value: "0",
												errorMessage: "Please enter a value greater than 0"
											},
										}}
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx md="2" className={"pr-0 "+(product_type==1 ? "":"d-none")}>
								<AvGroup>
									<Label>Amount of Flower<span className="text-danger">*</span></Label>
									<AvField
										id={"rcvd_amt_of_flower_row_"+index+"_"+i}
										name={"rcvd_amt_of_flower_row_"+index+"["+i+"]"}
										autoComplete="off"
										value={row.rcvd_amt_of_flower}
										type="number"
										onChange={e => { 
											var product_row = this.state.product_row;
											product_row.batches = product_row.batches.map(r=>{
												if(r.id==row.id){
													r.rcvd_amt_of_flower=e.target.value;
												}
												return r;
											});
											this.setState({	product_row	});			
											this.props.updateProductRow(product_row);
										}}
										validate={product_type==1 ? {
											required: {
												value: true,
												errorMessage: "This field is required"
											},
											min: {
												value: "0",
												errorMessage: "Please enter a value greater than 0"
											},
										}:{}}
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx md={product_row.variation_id==null && product_row.product_id.qty_type!="3" ? "2":"4"} className="pt-3">
								<Button color="danger" onClick={()=>{ this.removeBatch(row.id) }} className="float-right">X</Button>
							</Colxx>
							
						</Row>
					</Card>)
				})}
				
			</Card>
		);
	}
  
};
