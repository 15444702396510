import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";

import { zeroPad } from "../../../../helpers/Utils";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class Discounts extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {		 
			reports:[],
			search: '',
			start_date: moment(),
			end_date: moment(),
			blocking: true,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
	}
	
	handleChangeDate = date => {
		this.setState({
			start_date: date
		});
	}
	
	handleChangeEndDate = date => {
		this.setState({
			end_date: date
		});
	} 

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.start_date!=null){
			formData.append('start_date',moment(this.state.start_date).format('YYYY-MM-DD'));
		}else{
			formData.append('start_date','');
		}
		
		if(this.state.end_date!=null){
			formData.append('end_date',moment(this.state.end_date).format('YYYY-MM-DD'));
		}else{
			formData.append('end_date','');
		}			
		
		axios.post(REST_API_END_POINT+'report/discounts',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
								
				this.setState({
					reports: data.reports,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: [],
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		this.$el.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray(),
				aaSorting:[],
				buttons: [
					'csv', 'excel', 'pdf', 'print'
				]
			});
	}
	
	makeArray(){
		const reports = this.state.reports;
		var formated_data=[];
		var i=1;
		for(let row of reports){
			if(parseFloat(row['discount'])>0){
				let coupon_code = row.coupon_code.split(',');
				coupon_code = coupon_code.filter(function(item, pos, self) { return self.indexOf(item) == pos; }).filter(r=>{ return r!='' }).join(',')
				formated_data.push([
					zeroPad(row.order_number,6),
					moment(row.timestamp*1000).format('MM/DD/YYYY'),
					row.vendor_name,
					row.authorized_by,
					"$"+row.discount,
					coupon_code,
					"$"+row.total,
					row.payment_mode,
					row.reason_for_discount
				])
			}
		}
		return formated_data;
	}
	
	
  
	render() {	
		const reports = this.state.reports;
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.discounts" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="5">
								<Label>Start date<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_date"
									name="start_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.start_date}
									onChange={this.handleChangeDate}
									placeholderText=""
									dateFormat="MM/DD/YYYY"
								/>
							</Colxx>
							
							<Colxx lg="5">
								<Label>End date<span className="text-danger">*</span></Label>
								<DatePicker
									id="end_date"
									name="end_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.end_date}
									onChange={this.handleChangeEndDate}
									placeholderText=""
									dateFormat="MM/DD/YYYY"
								/>
							</Colxx>
							<Colxx lg="2">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm> 	
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									<table className="display table table-bordered table-striped" ref={el=>this.el=el} >
										<thead>
											<tr>				
												<th>Invoice</th>
												<th>Invoice Date</th>
												<th>Customer Name</th>
												<th>Authorized By</th>
												<th>Discount</th>
												<th>Coupon Codes Used</th>
												<th>Grand Total</th>
												<th>Payment Mode</th>
												<th>Discount Reason</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
