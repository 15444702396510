import React, { Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AppLayout from "../../layout/AppLayout";
import dashboard from "./dashboard";
import customer from "./customer";
import allorders from "./allorders";
import account from "./account";
import transportation from "./transportation";
import inventory from "./inventory";
import wholesale from "./wholesale";
import growhouse from "./growhouse";
//import settings from "./settings";							  
import pages from "./pages";
import ui from "./ui";
import menu from "./menu";
import blankPage from "./blank-page";
import complaintandcomments from "./complaintandcomments";
import onboarding from "./onboarding";
import kitchen from "./kitchen";
import myjobs from "./myjobs";
import processor from "./processor";
import mycalendar from "./mycalendar";
import visitors from "./visitors";
import staffmanagement from "./staffmanagement";
import leafmanagement from "./leafmanagement";
import officialrequests from "./officialrequests";
import bankmanagement from "./bankmanagement";
import reports from "./reports";
import department from "./department";
import warehouse from "./warehouse";
import complaints from "./complaints";
import pointOfSale from "./point-of-sale";
import profile from "./profile";
import websiteManagement from "./website-management";
import metrc from "./metrc";

class App extends Component {
  render() {
    const { match } = this.props;

    return (
      <AppLayout>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
          <Route path={`${match.url}/dashboard`} component={dashboard} />
          <Route path={`${match.url}/customer`} component={customer} />
          <Route path={`${match.url}/all-orders`} component={allorders} />
          <Route path={`${match.url}/account`} component={account} />
		  <Route path={`${match.url}/transportation`} component={transportation} />
          <Route path={`${match.url}/inventory`} component={inventory} />
          <Route path={`${match.url}/wholesale`} component={wholesale} />
          <Route path={`${match.url}/growhouse`} component={growhouse} />
		  <Route path={`${match.url}/pages`} component={pages} />
          <Route path={`${match.url}/ui`} component={ui} />
          <Route path={`${match.url}/menu`} component={menu} />
          <Route path={`${match.url}/blank-page`} component={blankPage} />
		  {/*<Route path={`${match.url}/settings`} component={settings} />*/}
		  <Route path={`${match.url}/complaints-and-comments`} component={complaintandcomments} />
		  <Route path={`${match.url}/onboarding`} component={onboarding} />
		  <Route path={`${match.url}/kitchen`} component={kitchen} />
          <Route path={`${match.url}/jobs`} component={myjobs} />
          <Route path={`${match.url}/processor`} component={processor} />
          <Route path={`${match.url}/mycalendar`} component={mycalendar} />
          <Route path={`${match.url}/visitors`} component={visitors} />
          <Route path={`${match.url}/leaf-management`} component={leafmanagement} />
          <Route path={`${match.url}/official-requests`} component={officialrequests} />
          <Route path={`${match.url}/bankmanagement`} component={bankmanagement} />
          <Route path={`${match.url}/reports`} component={reports} />
          <Route path={`${match.url}/staffmanagement`} component={staffmanagement} />
          <Route path={`${match.url}/department`} component={department} />
		  <Route path={`${match.url}/warehouse`} component={warehouse} />
		  <Route path={`${match.url}/complaints`} component={complaints} />
		  <Route path={`${match.url}/point-of-sale`} component={pointOfSale} />
		  <Route path={`${match.url}/profile`} component={profile} />
		  <Route path={`${match.url}/website`} component={websiteManagement} />
		  <Route path={`${match.url}/metrc`} component={metrc} />
          <Redirect to="/error" />
        </Switch>
      </AppLayout>
    );
  }
}
const mapStateToProps = ({ menu }) => {
  const { containerClassnames } = menu;
  return { containerClassnames };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(App)
);
