import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import transportation from "./transportation";
import drivers from "./drivers";
import trackdrivers from "./trackdrivers";
import shipperinformation from "./shipperinformation";
import vehicles from "./vehicles";
import Routes from "./routes";
const Transportation = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
                      
			<Route path={`${match.url}/transportation`} component={transportation} />
			<Route path={`${match.url}/drivers`} component={drivers} />
			<Route path={`${match.url}/track-drivers`} component={trackdrivers} />
			<Route path={`${match.url}/shipper-information`} component={shipperinformation} />
			<Route path={`${match.url}/vehicles`} component={vehicles} />
			<Route path={`${match.url}/routes`} component={Routes} />
			<Route path={`${match.url}`} component={transportation} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Transportation  ;