import React from "react";
import { Tooltip, DropdownToggle, ButtonDropdown,
    DropdownItem,
    DropdownMenu,  } from "reactstrap";
import { NavLink } from "react-router-dom";

class ItemDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  isOpenSizing: false,
    };
  }
  
  toggleSizing = () => {
    this.setState(prevState => ({
      isOpenSizing: !prevState.isOpenSizing
    }));
  };

  render() {
    return (
      <span>
        <ButtonDropdown
			  className="mr-2 mb-4"
			  isOpen={this.state.isOpenSizing}
			  toggle={this.toggleSizing}
			>
			  <DropdownToggle caret size="sm" color="primary"></DropdownToggle>
			  {this.props.item}	  
		</ButtonDropdown>
		
      </span>
    );
  }
}
export default ItemDropdown;
