import React, { Component }  from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";

import {DEFAULT_QTY_TYPES } from "../../../../constants/defaultValues";
import Barcode from 'react-barcode';
import moment from "moment";

import {LBS_CONSTANT, INVENTORY_SOURCES} from "../../../../constants/defaultValues";

export default class PrintPackageBarcodes extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		const { product_packages, product, selectedIds, facility  } = this.props;
		const qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==product.qty_type });
		
		var packages = JSON.parse(JSON.stringify(product_packages));
		//packages = packages.filter(r=>{ return selectedIds.indexOf(r.package_id)!=-1 });
		
		return (
			<div>
				<style dangerouslySetInnerHTML={{__html: `
					@media print {
						@page {
						  size: 2in 4in;
						  margin:0;
						}
					}
				`}} />
				{packages.map((row,index)=>{
					let additives = [];
					if(typeof row['additives']!='undefined'){
						for(let additive of row['additives']){
							additives.push(additive.name);
						}
					}
					return (
						<div style={{padding:'2px 10px 2px 3px'}} key={index} >                  
							<div style={{width:'2in', height:'3.8in', fontFamily: 'sans-serif'}}>
								Lic#: {facility.license_number} <br/>
								Date: {moment(row.timestamp*1000).format('YYYY-MM-DD')} <br/>
								Total Weight: {(row['qty_type']==1) ? row['quantity']+' GR': (row['qty_type']==2 ? Math.round(row['quantity']/LBS_CONSTANT,2)+' Lbs':row['quantity']+' Unit')}<br/>
								Usable Weight: {(row['qty_type']==1 || row['qty_type']==2) ? row['amount_of_flower_qty']+' GR' : row['quantity']+' Unit'}<br/>
								Batch # {row.barcode} <br/>
								{product.name} <br/>
								Additives: {additives.join(", ")} <br/>
								<div className="text-center">
									<Barcode value={row.barcode} height={25} width={0.95} margin={0} marginTop={5} displayValue={false} />
									<p className="text-center">{(row.barcode.match(/.{1,4}/g)).join(' ')}</p>
								</div>
							</div>
							{index!=(packages.length-1) ? <div className="page-break-style"></div>:""}
						</div>
					)
				})}
			</div>
		);
	}
};