import React, { Component, Fragment } from "react";
import { AvForm } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Input,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label, Table
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import CustomSelectInput from "../../components/common/CustomSelectInput";
import Rating from "../../components/common/Rating";

import axios from 'axios';
import swal from 'sweetalert';
import { zeroPad } from "../../helpers/Utils";
import number_format from "number_format-php";
import SimpleCrypto from "simple-crypto-js";
import { REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, BASE_URL, REST_API_BASE_URL, GOOGLE_API_KEY, PUBNUB_CREDENTIALS } from "../../constants/defaultValues";

import { compose, withProps, lifecycle } from "recompose";
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	DirectionsRenderer,
	Marker, InfoWindow

} from "react-google-maps";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const google = window.google;

const defaultMapOptions = {
	zoomControl: true,
	disableDefaultUI: true
};

const MapWithADirectionsRenderer = compose(
	withProps({
		googleMapURL:
			"https://maps.googleapis.com/maps/api/js?key=" + GOOGLE_API_KEY + "&v=3.exp&libraries=geometry,drawing,places",
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `500px` }} />,
		mapElement: <div style={{ height: `100%` }} />
	}),
	withGoogleMap,

	lifecycle({
		componentDidMount() {
			const DirectionsService = new google.maps.DirectionsService();
			var places = [];


			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			var formData = new FormData();
			formData.append('order_id', this.props.order_id);
			formData.append('facility_id', facility_id);
			axios.post(REST_API_END_POINT + 'transportation/get-data-for-assign-driver', formData).then((res) => {

				var data = res.data;

				var place = {};
				place = { address: data.start_loc };
				places.push(place);
				places.push({ address: data.end_loc });

				if (places.length > 1) {
					const waypoints = places.map(p => ({
						location: p.address,
						stopover: true
					}));

					const origin = waypoints.shift().location;
					const destination = waypoints.pop().location;

					DirectionsService.route(
						{
							origin: origin,
							destination: destination,
							travelMode: google.maps.TravelMode.DRIVING,
							waypoints: waypoints
						},
						(result, status) => {
							if (status === google.maps.DirectionsStatus.OK) {

								if (typeof result.routes[0] != 'undefined') {
									let legs = result.routes[0].legs[0];
									let start_location = { lat: legs.start_location.lat(), lng: legs.start_location.lng() }
									let end_location = { lat: legs.end_location.lat(), lng: legs.end_location.lng() }
									places[0].latLng = start_location;
									places[1].latLng = end_location;
								}

								this.setState({
									directions: result,
									places
								});
							} else {
								console.error(`error fetching directions ${result}`);
							}
						}
					);
				}

			}).catch(e => {
				console.log("Addition failed , Error ", e);
			});


		}
	})
)(props => (
	<GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }} defaultOptions={defaultMapOptions}>
		{props.directions && <DirectionsRenderer directions={props.directions} options={{ suppressMarkers: true }} />}
		{props.places && props.places.map((marker, i) => {
			if (i == 0) {
				return (<Marker
					key={i}
					position={marker.latLng}
					title={marker.address}
				>
				</Marker>)
			} else {
				return (<Marker
					key={i}
					position={marker.latLng}
					title={marker.address}
				>
				</Marker>)
			}
		})}
	</GoogleMap>
));


class ViewCreateModal extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			order_data: null,
			order_products: null,
			delivery_details: null,
			customer_review: null,
			signature: null,
			photoproof: null,
			route: null,
			drivers: null,
			selectDriver: null,
            tags: []
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange=this.handleChange.bind(this);
	}

    handleChange(tags) {
	
		
        this.setState({ tags });
    }
    toggleModalCreateView() {
        localStorage.setItem('tableData', JSON.stringify({id:"FCKLXILA10202023"}));
        // this.props.toggleModal()
    }

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			let zip_codes = this.state.tags;
			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);
			var user_id=localStorage.getItem('user_id');
			
			let formData = new FormData(document.getElementById("routeBaseForm"));
			formData.append("zip_codes",zip_codes);
			formData.append("facility_id",facility_id);
			formData.append('user_id',user_id);
			axios.post(REST_API_END_POINT+'transportation/add-route-base',formData).then((res)=>{	
				var data = res.data;
				console.log("Response from add route base: ",data);
				var api_message=document.getElementById("api_message");
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);
						
						JQUERY_MODULE.$('.alert .close').on("click", function(e){
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						});
						
						this.props.toggleModal();
						window.location="/app/transportation/routes";
						// this.props.dataListRender();
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-customer").disabled=false;
			});	

		}
	}

	// componentDidMount() {
	// 	if (this.props.delivery_id != 0) {
	// 		var facility_id = localStorage.getItem('facility_id');
	// 		facility_id = dataCrypto.decrypt(facility_id);
	// 		var formData = new FormData();
	// 		formData.append('delivery_id', this.props.delivery_id);
	// 		formData.append('facility_id', facility_id);
	// 		axios.post(REST_API_END_POINT + 'transportation/view-route-detail', formData).then((res) => {

	// 			var data = res.data;
	// 			if (data.status == 1) {
	// 				var customer_review = null;
	// 				var signature = null;
	// 				var photoproof = null;
	// 				var route = data.delivery
	// 				if (data.customer_review) {
	// 					customer_review = data.customer_review;
	// 				}

	// 				if (data.signature) {
	// 					signature = data.signature;
	// 				}

	// 				if (data.photoproof) {
	// 					photoproof = data.photoproof;
	// 				}
	// 				var order_data = data.order_data;
	// 				// var delivery_details = data.delivery_details;
	// 				this.setState({
	// 					order_data,
	// 					// order_products:data.order_products,
	// 					// delivery_details,
	// 					drivers:data.drivers,
	// 					route,
	// 					customer_review,
	// 					signature,
	// 					photoproof,
	// 				});

	// 				// if(order_data.delivery_status==1){
	// 				// 	var subscriber_id = localStorage.getItem('subscriber_id');
	// 				// 	var direction_channel = ['tridant_'+subscriber_id+'_'+facility_id];
	// 				// 	var local_channel = 'tridant_s_'+subscriber_id+'_'+facility_id;

	// 				// 	setTimeout(function(){
	// 				// 		const container = document.getElementById('map');
	// 				// 		if(container) {
	// 				// 			const PubNub=window.PubNub;
	// 				// 			const L=window.L;
	// 				// 			const eon=window.eon;

	// 				// 			var pn = new PubNub({
	// 				// 				publishKey: PUBNUB_CREDENTIALS.publishKey,
	// 				// 				subscribeKey: PUBNUB_CREDENTIALS.subscribeKey,
	// 				// 				ssl: (('https:' == document.location.protocol) ? true : false)
	// 				// 			});

	// 				// 			var pn_loc = new PubNub({
	// 				// 				publishKey: PUBNUB_CREDENTIALS.publishKey,
	// 				// 				subscribeKey: PUBNUB_CREDENTIALS.subscribeKey,
	// 				// 				ssl: (('https:' == document.location.protocol) ? true : false)
	// 				// 			});

	// 				// 			//pn.subscribe({ channels: direction_channel });		

	// 				// 			L.RotatedMarker = L.Marker.extend({
	// 				// 				options: { angle: 0 },
	// 				// 				_setPos: function(pos) {
	// 				// 					L.Marker.prototype._setPos.call(this, pos);
	// 				// 					if (L.DomUtil.TRANSFORM) {	// use the CSS transform rule if available
	// 				// 						this._icon.style[L.DomUtil.TRANSFORM] += ' rotate(' + this.options.angle + 'deg)';
	// 				// 					} else if (L.Browser.ie) { // fallback for IE6, IE7, IE8
	// 				// 						var rad = this.options.angle * L.LatLng.DEG_TO_RAD,costheta = Math.cos(rad),sintheta = Math.sin(rad);
	// 				// 						this._icon.style.filter += ' progid:DXImageTransform.Microsoft.Matrix(sizingMethod=\'auto expand\', M11=' + costheta + ', M12=' + (-sintheta) + ', M21=' + sintheta + ', M22=' + costheta + ')';
	// 				// 					}
	// 				// 				}
	// 				// 			});

	// 				// 			pn_loc.addListener({
	// 				// 				message: function (loc_data) {
	// 				// 					if(loc_data.message.user_id){											
	// 				// 						let new_torchys=[{latlng:{ lat: -34.397, lng: 150.644 },user_id:loc_data.message.user_id}];
	// 				// 						new_torchys[0].latlng= {lat:loc_data.message.latitude,lng:loc_data.message.longitude};
	// 				// 						pn.publish({
	// 				// 									channel: local_channel,
	// 				// 									message: new_torchys
	// 				// 								});
	// 				// 					}
	// 				// 				}
	// 				// 			});
	// 				// 			pn_loc.subscribe({ channels: direction_channel });

	// 				// 			var old_latLng = { lat: -34.397, lng: 150.644 };

	// 				// 			var map = eon.map({
	// 				// 				pubnub: pn,
	// 				// 				id: 'map',
	// 				// 				googleKey: GOOGLE_API_KEY,
	// 				// 				options : { zoom:8, center:{ lat: -34.397, lng: 150.644 } },
	// 				// 				channels:[local_channel],
	// 				// 				provider: 'google',
	// 				// 				rotate: true,
	// 				// 				history: true,
	// 				// 				connect: function () {
	// 				// 					var me = {
	// 				// 						icon: {
	// 				// 							'marker-color': '#ce1126'
	// 				// 						}
	// 				// 					};
	// 				// 					var them = { 
	// 				// 						icon: {
	// 				// 							'marker-color': '#29abe2'
	// 				// 						}
	// 				// 					};
	// 				// 				},
	// 				// 				message: function (data) {
	// 				// 					if(typeof data!='undefined'){
	// 				// 						if(typeof data[0]!='undefined'){
	// 				// 							if(data[0]['user_id']){
	// 				// 								if(data[0]['user_id']==delivery_details['driver_user_id']){
	// 				// 									map.setView(data[0].latlng, 8);
	// 				// 								}
	// 				// 							}
	// 				// 						}
	// 				// 					}
	// 				// 				},
	// 				// 				marker: function (latlng, data) {
	// 				// 					if(typeof latlng!='undefined'){
	// 				// 						old_latLng = latlng
	// 				// 					}

	// 				// 					var marker = new L.RotatedMarker(old_latLng, {
	// 				// 					  icon: L.icon({
	// 				// 						iconUrl: REST_API_BASE_URL+'img/truck-T.png',
	// 				// 						iconSize: [120, 68]
	// 				// 					  })
	// 				// 					});
	// 				// 					marker.bindPopup(delivery_details['name']);
	// 				// 					return marker;

	// 				// 				}
	// 				// 			});
	// 				// 		}
	// 				// 	}, 300);
	// 				// }

	// 			} else {
	// 				this.props.toggleModal();
	// 				swal("Warning!", "There is no such order", "warning");
	// 			}
	// 		}).catch(e => {
	// 			console.log("Addition failed , Error ", e);
	// 		});
	// 	}
	// }

	render() {

        const containerStyle = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10
        }
        const buttonStyle = {
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'center',
        }

		const { order_data, order_products, route, delivery_details, customer_review, photoproof, signature } = this.state;

		let delivery_status = 'Awaiting approval from Driver';
		if (route != null) {
			if (route.status == 1) {
				delivery_status = 'Accepted';
			} else if (route.status == 2) {
				delivery_status = 'Awaiting approval from Driver';
			} else if (route.status == 3) {
				delivery_status = 'Rejected';
			}
		}

		return (
			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				wrapClassName="modal-center"
				size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					{/* <h2 style={{fontWeight:600}}> */}
						Create Route
					{/* </h2> */}
				</ModalHeader>
			<AvForm
			id="routeBaseForm"
			className="av-tooltip tooltip-right-bottom"
			onSubmit={this.handleSubmit}
			>
                <ModalBody>
				<Row >																							
					<Colxx lg="12" className="mb-2 mt-2" >							
						<div lg="12" >
							<Row>
							<Colxx lg="12"  className="mb-2">
								<Label>Route Name</Label>
						
								<Input
								type="text"
								id="route_name"
								name="route_name"
								placeholder="Enter Route Name"
								/>
							</Colxx>
							</Row>
							<Row>
							<Colxx lg="12"  className="mb-2">
								<Label>Zip Codes</Label>
						
								<TagsInput
								value={this.state.tags}
								onChange={(tags) => this.handleChange(tags)}
								inputProps={
									{className: 'react-tagsinput-input',
									placeholder: 'Add Zip'}
								  }
								
								/>
							</Colxx>
							</Row>
						</div>
					</Colxx>
				</Row>
                {/* <div style={containerStyle}>
                    <div>
                    <h3 className="font-weight-bold">Route ID</h3>
                    </div>
                    <div style={{minWidth:'35%'}}>
                    <span className="h8">FCKLXILA10202023</span>
                    </div>
                </div> */}
                {/* <div style={containerStyle}>
                    <div>
                    <h5>Route Name</h5>
                    </div>
                    <Input
					type="text"
					name="text"
					id="route_name"
					placeholder="Enter route name"/>
                </div>
                <div style={containerStyle}>
                    <div>
                    <h5>Link Zipcode</h5>
                    </div>
                    <div style={{minWidth:'37%'}}>
                    <TagsInput
                    placeholder="asdfsa"
                        value={this.state.tags}
                        style={{placeholder:'fsd'}}
                        onChange={(tags) => this.handleChange(tags)}
                        />
                    </div>
                </div> */}
                </ModalBody>
				
				<ModalFooter>
					<Button color="primary" id="add-route-base">
						<IntlMessages id="customer.add"/>
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel"/>
					</Button>
                {/* <Button style={{height:35,width:'22%',display:'flex',justifyContent:'center',alignItems:'center'}} outline onClick={this.props.toggleModal}>Cancel</Button>{" "}
                <Button
				    color="primary"
					size="sm"
					className=""
							 
					onClick={this.props.toggleUpdateModal} 
				>*/}
					{/* <Link to="/app/point-of-sale/customer-lookup"><span style={{color:'#fff'}}>Create Order</span></Link> */}
					{/* Create Route */}
				{/* </Button>  */}
	
				</ModalFooter>
			</AvForm>
			</Modal>
		);
	}
};

export default ViewCreateModal;
