import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvCheckboxGroup, AvCheckbox, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import {
	Row,Card,
  CustomInput,
  Button,
  Modal,Input,
  ModalHeader,
  ModalBody,
  ModalFooter,FormGroup,
  Label,  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "../../components/common/CustomDateTimePicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import moment from 'moment';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddStaffModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeUserrole = this.changeUserrole.bind(this);
		this.DeselectAll = this.DeselectAll.bind(this);
			
		this.state = {	
			startDate: null,
			selectRole:[],
			selectState:[],
			selectOnboarding:[],
			Permissions:[],
			AllPermissions:[],
			Facilities:[],
			AllFacilities:[],
			selected_permissions:[],
			role_val:[],
			onboarding_val:[],
			state_val:[],
			receive_trans_notification:false,
		};
	}
	
	componentDidMount() {		
		var user_roles=[];
		var states=[];
		var Permissions=[];
		var AllPermissions=[];
		var selected_permissions=[];
		var workflows=[];
		var worklocations=[];
		var Facilities=[];
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'staff/get-data-for-add-staff',formData).then((res)=>{
			var data = res.data;
		
			user_roles=data.user_roles;
			states=data.states;
			workflows=data.workflows;
			
			var selectUserType=null;
			
			user_roles = user_roles.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			if(user_roles.length){
				selectUserType=user_roles[0];
				if(user_roles[0].user_permissions!=''){
					selected_permissions=user_roles[0].user_permissions.split(',');
				}
			}
			
			states = states.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});	
			
			workflows = workflows.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
	
			this.setState({
				selectRole:user_roles,
				selectState:states,
				selectOnboarding:workflows,
				Permissions:data.staff_permissions,
				AllPermissions:data.staff_permissions,
				Facilities:data.facilities,
				AllFacilities:data.facilities,
				defaultFaclity:[facility_id],
				selected_permissions,
				selectUserType
			});
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
   
	}
	 
    handleChangeDate = date => {
		this.setState({
		  startDate: date
		});
	};
	  
    handleSubmit(event, errors, values) {		
		if (errors.length === 0) {		
			document.getElementById("add-staff").disabled=true;
			
			let dateOfBirth = document.getElementById("dob").value.trim();
			let errorMessage='';

			if(dateOfBirth.length==0)
			{
				errorMessage+="Date of Birth is empty\n";
			}

			if(errorMessage.length!=0)
			{
				swal("Warning",errorMessage,"warning");
				document.getElementById("add-staff").disabled=false;
				return false;
			}
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('email',values.email);			
			
			axios.post(REST_API_END_POINT+'staff/is-email-exist',formData).then((res)=>{
				var data = res.data;
				
				if(data.status!=1){
					var formData = new FormData(document.getElementById("staffForm"));
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);
					formData.append('receive_trans_notification',this.state.receive_trans_notification ? 1:0);

					axios.post(REST_API_END_POINT+'staff/add-staff',formData).then((res)=>{
						var data = res.data;
						
						var api_message=document.getElementById("api_message");
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);
						
						JQUERY_MODULE.$('.alert .close').on("click", function(e){
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						});
						
						document.getElementById("add-staff").disabled=false;
						this.props.toggleModal();
						this.DeselectAll();
						this.props.dataListRender();

					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("add-staff").disabled=false;
					});
				}else{
					swal("Email already exist! Please use another email");
					document.getElementById("add-staff").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-staff").disabled=false;
			});

		}
	}
	
	changeUserrole(selectUserType){
		var AllPermissions = this.state.AllPermissions;
		var selected_permissions = [];
		var old_role = this.state.selectRole.find(row=>{ return row.id==this.state.selectUserType.value });
		var new_role = this.state.selectRole.find(row=>{ return row.id==selectUserType.value });
		
		var old_role_permissions=[];
		var new_role_permissions=[];
		if(old_role.user_permissions!=''){
			old_role_permissions = old_role.user_permissions.split(',');
		}
		if(new_role.user_permissions!=''){
			new_role_permissions = new_role.user_permissions.split(',');
		}
		
		var inputElements = document.querySelectorAll('input[name^=assigned_permissions]');
		for(var i=0; inputElements[i]; ++i){
			if(inputElements[i].checked && old_role_permissions.indexOf(inputElements[i].value)==-1){
				selected_permissions.push(inputElements[i].value);
			}
		}
		for(let permission of new_role_permissions){
			if(selected_permissions.indexOf(permission)==-1){
				selected_permissions.push(permission)
			}
		}
		
		this.setState({
			Permissions:[],
			selectUserType
		},()=>{
			this.setState({
				Permissions:AllPermissions,					
				selected_permissions			
			});
		});
		
	}
  
	onChange = e => {
		switch (e.target.name) {
			case 'files[]':
				if(e.target.files[0]){
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
				}else{
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
				}
				break;
			default: e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};
	
	DeselectAll(){
		var AllPermissions = this.state.AllPermissions;
		var AllFacilities = this.state.AllFacilities;
		var user_roles = this.state.selectRole;
		var selected_permissions = [];
		if(user_roles.length){
			if(user_roles[0].user_permissions!=''){
				selected_permissions=user_roles[0].user_permissions.split(',');
			}
		}
		
		this.setState({
			Permissions:[],
			Facilities:[]
		},()=>{
			this.setState({
				Permissions:AllPermissions,		
				Facilities:AllFacilities,		
				selected_permissions,					
				selectStateType:null,					
				selectOnboardingType:null,					
				startDate:null,					
			});
		});
	}
 
	render() {

		const { selectRole,selectState,selectOnboarding,Permissions,Facilities,defaultFaclity } = this.state;
	  
	  return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={(e)=>{ this.DeselectAll(); this.props.toggleModal(e); }}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Staff
		  </ModalHeader>
			<AvForm
				id="staffForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
			<Row>
				<Colxx lg="8">
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Full Name<span className="text-danger">*</span></Label>
							  <AvField
								id="name"
								name="name"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								  pattern: {
									value: "^[a-zA-Z ,.'-]+$",
									errorMessage: "Invalid name"
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  }
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					
					<Row>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Gender<span className="text-danger">*</span></Label>
							</AvGroup>
							<AvRadioGroup
								inline
								name="gender"
								onChange={(e) => {}}
								validate={{
								  required: {
									value: true,
									errorMessage: "Please select any of the gender"
								  }
								}}
								defaultValue='1'
								>
								<AvRadio customInput label="Male" value="1" />
								<AvRadio customInput label="Female" value="2" />
							</AvRadioGroup >
						</Colxx>
						<Colxx lg="6">
							<Label><IntlMessages id="customer.dob" /><span className="text-danger">*</span></Label>
							<DatePicker
								id="dob"
								name="dob"
								autoComplete="off"
								className="mb-5"
								maxDate={moment().subtract(18, "years")}
								selected={this.state.startDate}
								onChange={this.handleChangeDate}
								placeholderText=""
								dateFormat="YYYY-MM-DD"
							  />
						</Colxx>
						
					</Row>
					<Row>
						<Colxx lg="12">
							<Label>Address<span className="text-danger">*</span></Label>	&nbsp;&nbsp;
							<AvGroup className="error-t-negative">
							  <AvField
								id="address"
								name="address"
								type="textarea"
								autoComplete="off"
								validate={{
									required: {
										value: true, 
										errorMessage: "This field is required."
									},
									minLength: {
										value: 10,
										errorMessage: "Your message must have minimum of 10 characters"
									},
								}}
								value={this.state.textarea_content}
								placeholder=""
								rows="3"
							  />
							</AvGroup>	
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="6">
							<AvGroup>
							  <Label><IntlMessages id="customer.phone" /><span className="text-danger">*</span></Label>
							  <AvField
								id="phone"
								name="phone"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								  number: {
									value: true,
									errorMessage: "Please enter a valid number"
								  },
								  minLength: {
									value: 7,
									errorMessage:
									  "Your name must be between 7 and 15 characters"
								  },
								  maxLength: {
									value: 15,
									errorMessage:
									  "Your name must be between 7 and 15 characters"
								  }
								}}							
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<AvGroup >
							  <Label><IntlMessages id="customer.email" /><span className="text-danger">*</span></Label>
							  <AvField
								id="email"
								name="email"
								type="email"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required. "
								  },
								  email: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="6">
							<AvGroup >
							  <Label>City<span className="text-danger">*</span></Label>
							  <AvField
								id="city"
								name="city"
								type="text"
								autoComplete="off"							
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}							
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<AvGroup >
							  <Label>State<span className="text-danger">*</span></Label>
							  <Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="state"
								id="state"
								value={this.state.selectStateType}						
								options={selectState}
								onChange={selectStateType => { this.setState({ selectStateType }) }}
							/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Select Onboarding Workflows</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="workflow_ids[]"
									isMulti
									id="workflow_ids"
									value={this.state.selectOnboardingType}						
									options={selectOnboarding}
									onChange={selectOnboardingType => { this.setState({ selectOnboardingType }) }}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					
					{Facilities.length > 0 ? <Row><Colxx lg="12">
						<Label>Work Locations<span className="text-danger">*</span></Label>	
						<AvGroup className="w-100">
							<AvCheckboxGroup
								inline
								name="facility_ids[]"
								onChange={selectFacility => { this.setState({ selectFacility }) }}
								validate={{
								  required: {
									value: true,
									errorMessage: "Please select any of the facilities "
								  }
								}}
								defaultValue={defaultFaclity}
							>
								{Facilities.map((row)=>{
									return <AvCheckbox customInput label={row.name} value={row.id} key={row.id} />
								})}
							</AvCheckboxGroup >	
						</AvGroup>					
					</Colxx></Row>:""}
				
				
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>User Role<span className="text-danger">*</span></Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="user_role"
									id="user_role"
									value={this.state.selectUserType}						
									options={selectRole}
									onChange={this.changeUserrole}
									required
								/>
							</AvGroup>
						</Colxx>
					</Row>
					<AvGroup className="error-l-125 error-t-negative">
						<Label>Identity Proof</Label>
							<InputGroup className="mb-3">
							  <InputGroupAddon addonType="prepend"></InputGroupAddon>
							  <CustomInput
								type="file"
								name="files[]"
								id="files[]"
								accept="image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
								onChange={this.onChange}
							  />
							</InputGroup>
					</AvGroup>
					<AvGroup className="error-l-125 error-t-negative">
						<Label>													  
							<Switch
								className="custom-switch custom-switch-primary im-inline-block"
								checked={this.state.receive_trans_notification}
								onChange={status => { this.setState({ receive_trans_notification:!this.state.receive_trans_notification }) }}
							/>
							<span className="h6 ml-4">Receive transportation related notifications</span>
						</Label>
					</AvGroup>
				</Colxx>	
				{Permissions.length ? <Colxx lg="4"  >					
					<Row> 
						<Colxx lg="12">
							<AvGroup className="error-l-100 tooltip-label-right">
								<Label className="h4 font-weight-bold">Staff Permissions</Label>
								<div className="staff-scroll">
								
									<AvCheckboxGroup
										name="assigned_permissions[]"
										className="staff_permissions"
										onChange={e => { }}
										validate={{
										  required: {
											value: true,
											errorMessage: "Please select any of the permissions"
										  }
										}}
										defaultValue={this.state.selected_permissions}
										>
										<Row>
										{Permissions.map((row,index)=>{
											return <Colxx lg="12" key={index}><AvCheckbox customInput label={row.upriv_name} value={row.upriv_id} title={row.upriv_desc} /></Colxx>
										})}
										</Row>
									</AvCheckboxGroup >
								
								</div>
							</AvGroup>
						</Colxx>
					</Row>
				</Colxx>:""}
			</Row>	
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary"  id="add-staff">
			  <IntlMessages id="customer.add" />
			</Button>
			<Button color="secondary" outline onClick={(e)=>{ this.DeselectAll(); this.props.toggleModal(e); }}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddStaffModal;
