import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card, Table, 
  InputGroupAddon,Input, Badge
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/customer/ItemDropdown";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

   const selectQuantity = [
    { label: "Gram", value: "1", key: 0 },
    { label: "Lbs", value: "2", key: 1 }
  ];
  const selectProducts = [
    { label: "Tissue Culture Clones", value: "1", key: 0 },
    { label: "Trim", value: "2", key: 1 },
    { label: "Fresh Frozen Flower", value: "3", key: 2 },
    { label: "Frozen Trim", value: "4", key: 3 }
  ];
  
 

class CutPlantModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {
		selectProductType: null,	
		search: '',
	};
	
  }
	
  handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }
  
  render() {
	let data = this.state.data
			if (this.state.search) {
				data = data.filter(row => {
					return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
				})
			}
		
	const dataTableColumns = [			  
	  {
		Header: "Barcode",
		accessor: "barcode",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Strain",
		accessor: "strain",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Phase",
		accessor: "phase",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Birth Date",
		accessor: "birthDate",
		Cell: props => <p className="text-muted">{props.value}</p>
	  }
	  ,
	  {
		Header: "Time in Room",
		accessor: "time_in_room",
		Cell: props => <p className="text-muted">{props.value}</p>
	  }
	];	  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Cut Plants
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
			<Row>
				<Colxx xxs="6">
					<Label>Strain</Label>
				</Colxx>
				<Colxx xxs="6">
					<Label>Grapefruit OG</Label>
				</Colxx>
			</Row>
			<Row className="mt-2">
				<Colxx lg="6">
					<Label >Products</Label>
					 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"						
						name="product_id"
						id="product"
						value={this.state.selectProductType}						
							options={selectProducts}
							onChange={selectProductType => { this.setState({ selectProductType }) }}
					/>
				</Colxx>	
				<Colxx lg="6">
					<AvGroup className="error-t-negative">
					  <Label>Quantity<span className="text-danger">*</span></Label>
					  <AvField
						id="quantity"
						name="quantity"
						type="number"
						validate={{
								required: {
								value: true,
								errorMessage: "This field is required."
							  }
						}}							
					  />
					</AvGroup>
				</Colxx>
							
			</Row>
						
			<Row className="mt-4">					
				<Colxx lg="12">
					<Button																		 
						  color="primary"
						  size="sm"
						  className="mr-2"							  
						>
						  Cut Plant							  
					</Button>										
				</Colxx>
			</Row>
			<Card className="p-4 mt-4">
								
				  <div className="mb-4 dropdown-edit">
						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value})}
								/>
						  </Colxx>
						  </FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={5}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>
			</Card>	
		  </ModalBody>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default CutPlantModal;
