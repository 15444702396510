import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardBody, Table
} from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import moment from 'moment';

import { zeroPad } from "../../../helpers/Utils";
import { DEFAULT_QTY_TYPES } from "../../../constants/defaultValues";
import number_format from "number_format-php";

export default class InvoiceModalContent extends Component{
		
    render() {
		const { order_data, order_products, facility_data } = this.props;
		var current_currency_symbol = '$';
		var sales_tax=0;
		var sub_total=0;
		var p_discount=0;
		
		return (			
			<Row className="invoice-react">
				<Colxx xxs="12" className="mb-4">
					<div className="mb-5 invoice-contents">
						<CardBody className="d-flex flex-column justify-content-between">
							<div className="d-flex flex-column">
								<div className="d-flex flex-row justify-content-between pt-2 pb-2">
									<div className="">
										<div className="font-weight-bold h5">{facility_data!=null ? facility_data.facility_name:""}</div>
										{facility_data!=null ? (facility_data.facility_address!=null ? <p className="mb-0">{facility_data.facility_address}</p>:""):""}
										{facility_data!=null ? (facility_data.facility_phone!=null ? <p className="mb-0">{facility_data.facility_phone}</p>:""):""}
										{facility_data!=null ? (facility_data.facility_email!=null ? <p className="mb-0">{facility_data.facility_email}</p>:""):""}
										{facility_data!=null ? (facility_data.license_number!=null ? <p className="mb-0">Lic No: {facility_data.license_number}</p>:""):""}
									</div>
									{order_data!=null ? <div className="w-30 justify-content-end text-right ">
										<div className="font-weight-bold text-uppercase h4">Invoice</div>
										<p className="mb-0">
											INVOICE # {zeroPad(order_data.order_number,6)}<br/>
											DATE: {moment(order_data.timestamp*1000).format("MMMM DD, YYYY")}
										</p>
									</div>:""}
								</div>
								<div className="border-bottom pt-4 mb-5" />

								{order_data!=null ? <div className="d-flex flex-row justify-content-between mb-5">
									<div className="d-flex flex-column w-100 mr-2 p-4 bg-semi-muted">
										<div className="font-weight-bold h5">BILLING ADDRESS:</div>
										<p className="mb-0">
											{order_data.vendor_name}<br/>
											{order_data.vendor_address!=null ? <Fragment>{order_data.vendor_address}<br/></Fragment>:""}
											{order_data.vendor_phone!=null ? <Fragment>Tel: {order_data.vendor_phone}</Fragment>:""}
										</p>
									</div>
								</div>:""}

								{order_data!=null ?<Table borderless>
									<thead>
										<tr>
											<th className="mb-2">#</th>						
											<th className="mb-2">Product</th>						
											<th className="mb-2 text-right">Price</th>						
											<th className="mb-2 text-right">Quantity</th>						
											<th className="mb-2 text-right">Total</th>						
										</tr>
									</thead>
									<tbody>
										{order_products.map((row,index)=>{
											let qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==row.qty_type })
											return(<tr key={index}>
											<td>{index+1}</td>
											<td>{row.product} {row.variation_name!=null ? "("+row.variation_name+")":""}</td>
											<td className="text-right">{current_currency_symbol+""+number_format(parseFloat(row.unit_price),2,'.','')}</td>
											<td className="text-right">{number_format(parseFloat(row.qty),2,'.','')}{" "}{qty_type.label}</td>
											<td className="text-right">{current_currency_symbol+""+number_format(parseFloat(row.total_amount),2,'.','')}</td>
										</tr>)
										})}                                                   
									</tbody>
								</Table>:""}							  
							</div>
							{order_data!=null ?<div className="d-flex flex-column">
								<div className="border-bottom pt-3 mb-2" />
								<Table borderless className="d-flex justify-content-end">
									<tbody>
										<tr>
											<td className="pb-0">Subtotal :</td>
											<td className="text-right pb-0">{current_currency_symbol+number_format(parseFloat(order_data.sub_total),2,'.','')}</td>
										</tr>
										<tr>
											<td className="pb-0">VAT :</td>
											<td className="text-right pb-0">{current_currency_symbol+number_format(parseFloat(order_data.vat_amount),2,'.','')}</td>
										</tr>
										<tr>
											<td className="pb-0">Discount :</td>
											<td className="text-right pb-0">{current_currency_symbol+number_format(parseFloat(order_data.discount),2,'.','')}</td>
										</tr>
										<tr className="font-weight-bold">
											<td className="pb-0">Grand Total :</td>
											<td className="text-right pb-0">{current_currency_symbol+number_format(parseFloat(order_data.grand_total),2,'.','')}</td>
										</tr>
									</tbody>
								</Table>
							</div>:""}
						</CardBody>
					</div>
				</Colxx>
			</Row>
		);
  }
};
