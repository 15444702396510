import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, CustomInput, Badge, DropdownMenu, DropdownItem } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import TemplateApplicationMenu from "../../../containers/template/TemplateApplicationMenu";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import EditTaxModal from "../../../containers/inventory/all-tax/EditTaxModal";
import AddTaxModal from "../../../containers/inventory/all-tax/AddTaxModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AllTax extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		this.state = {
		modalOpenAdd: false,
		 modalOpenEdit: false,
		 selectedIds:[],
		  selectAll:0,
	      tax_id:0,
		  search: '',
		  taxes:[],
		  user_privileges:[]
		};
		this.deleteItem = this.deleteItem.bind(this);
		
	}

	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.taxes.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	deleteItem = (e,selectedIds,status)  => {
		if(selectedIds.length==0){
			swal("Warning!","Please select at least one tax category","warning");
			return;
		}
		var msg="";
		var success_msg="";
		var failure_msg="";
        		var strain_word="tax";
		
		if(selectedIds.length>1){
			strain_word="taxes";
		}
		
        if(status===1){
            msg="Activate the "+strain_word+" ?";
            success_msg="The "+strain_word+" has been activated.";
            failure_msg="Failed to activate "+strain_word;
        }else if(status===2){
            msg="Delete the "+strain_word+" ?";
            success_msg="The "+strain_word+" has been deleted.";
            failure_msg="Failed to delete "+strain_word;
        }else{
            msg="Deactivate the "+strain_word+" ?";
            success_msg="The "+strain_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+strain_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-tax").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('tax_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'inventory/delete-tax-categories',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-tax").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-tax").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	activeItem=(e,tax_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(tax_id);
			
		}else{
		  var x=selectedIds.indexOf(tax_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.taxes.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,tax_id=0) => {
		this.setState({
			modalOpenEdit: !this.state.modalOpenEdit,
			tax_id: parseInt(tax_id),
		});
	};
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'inventory/get-all-tax-categories',formData).then((res)=>{
			var data = res.data;
		
			if(data.tax_categories){
				this.setState({
					taxes: data.tax_categories,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					taxes: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidMount(){
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}


  render() {
	  
	  let data = this.state.taxes
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search) || row.tax_rate.includes(this.state.search) 
			})
		}
		
		
	const dataTableColumns = [		
		{
			Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
			accessor: "id",
			width:50,
			sortable: false,
			Cell: ( rowInfo )=> {
				return ( <FormGroup>
					<CustomInput type="checkbox" id={"coupon_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
					</FormGroup> );
			}
		},
		{
			Header: "Name",
			accessor: "name",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Rate(%)",
			accessor: "tax_rate",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},	  	  
		{
			Header: "Actions",
			accessor: "id",
			sortable: false,
			Cell: props => this.state.user_privileges.indexOf(57)!==-1 || this.state.user_privileges.indexOf(58)!==-1 ? <Fragment>
					<div className="action-btns w-9 w-sm-50 p-0">
						<ItemDropdown item={<DropdownMenu >
								{this.state.user_privileges.indexOf(57)!==-1 ? <DropdownItem onClick={(e)=> this.toggleModalEdit(e,props.value) } >
									<span className="d-inline-block">Edit</span>						
								</DropdownItem>:""}		
								{this.state.user_privileges.indexOf(58)!==-1 ? <DropdownItem onClick={event =>this.deleteItem(event,[props.value],2)} >
									<span className="d-inline-block" >Delete</span>
								</DropdownItem>:""}
							</DropdownMenu>}
						/>
					</div>
				</Fragment>:""
		}
	];
    return (
      <Fragment>
	
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.tax-category" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>	
		<Card className="p-4">
		   <Row>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
				 <Colxx xxs="12">
					<div className="float-sm-right">
					{" "}{this.state.user_privileges.indexOf(56)!==-1 ? <Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  Add Tax						  
						</Button>:""}																	
						 {" "}{this.state.user_privileges.indexOf(58)!==-1 ? <Button																		 
							  color="primary"
							  size="sm"
							  id="delete-tax"
							  className=""
                             onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 							  
							>
							  Delete							  
						</Button>:""}																								   
					</div>
						
					<div className="mb-4 dropdown-edit">						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>		
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							filterable={false}
							showPageJump={false}
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}
							defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>					
				</Colxx>
			</Row>
			{this.state.user_privileges.indexOf(56)!==-1 ? <AddTaxModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				dataListRender={this.dataListRender}
			/>:""}
			{this.state.user_privileges.indexOf(57)!==-1 ? <EditTaxModal
				modalOpen={this.state.modalOpenEdit}
				toggleModal={this.toggleModalEdit}
				tax_id={this.state.tax_id}
				dataListRender={this.dataListRender}
			/>:""}
		</Card>
      </Fragment>
    );
  }
}
