import React, { Component } from "react";
import {
  Row,
  Button,
  ButtonDropdown,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  CustomInput,
  Collapse
} from "reactstrap";
import { injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import IntlMessages from "../../../helpers/IntlMessages";

import {
  DataListIcon,
  ThumbListIcon,
  ImageListIcon
} from "../../../components/svg";
class ListPageHeading extends Component {
  constructor(props) {
    super();
    this.state = {
      dropdownSplitOpen: false,
      displayOptionsIsOpen: false
    };
	this.toggleSplit=this.toggleSplit.bind(this);
  }

  toggleDisplayOptions = () => {
    this.setState(prevState => ({
      displayOptionsIsOpen: !prevState.displayOptionsIsOpen
    }));
  };
  
  toggleSplit() {
    this.setState(prevState => ({
      dropdownSplitOpen: !prevState.dropdownSplitOpen
    }));
  }

  render() {
    const { messages } = this.props.intl;
    const {
      displayMode,
      changeDisplayMode,
      handleChangeSelectAll,
      changeOrderBy,
      changePageSize,
      selectedPageSize,
      totalItemCount,
      selectedOrderOption,
      //match,
      startIndex,
      endIndex,
      selectedItemsLength,
      itemsLength,
      onSearchKey,
      orderOptions,
      pageSizes,
      toggleModal,
	  selectedItemsStatus,
	  groupDelete,
	  register_type,
    } = this.props;

    const { displayOptionsIsOpen, dropdownSplitOpen } = this.state;
	const ItemsStatus = Array.from(new Set(selectedItemsStatus));
	var showActivate=1;
	var showDetactivate=1;
	if(ItemsStatus.length>1){
		showActivate=0;
		showDetactivate=0;
	}else if(ItemsStatus.length===1){
		if(ItemsStatus[0]==0){
			showActivate=1;
			showDetactivate=0;
		}else{
			showDetactivate=1;
			showActivate=0;
		}
	}
	
    return (
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            
			<div className="float-sm-right">
			{" "}{this.props.user_privileges.indexOf(82)!==-1 ? <Button
                color="primary"
                size="sm"
                className="mb-1"
                onClick={()=>toggleModal()}
              >
				<IntlMessages id="pages.add-new" />
              </Button>:""}
			  {"  "}
              {this.props.user_privileges.indexOf(84)!==-1 ? <ButtonDropdown
                isOpen={dropdownSplitOpen}
                toggle={this.toggleSplit}
              >
                <div className="btn btn-primary pl-4 pr-0 check-button check-all">
                    <CustomInput
                      className="custom-checkbox mb-0 d-inline-block"
                      type="checkbox"
                      id="checkAll"
                      checked={selectedItemsLength >= itemsLength}
                      onChange={() => handleChangeSelectAll(true)}
                      label={
                        <span
                          className={`custom-control-label ${
                            selectedItemsLength > 0 &&
                            selectedItemsLength < itemsLength
                              ? "indeterminate"
                              : ""
                          }`}
                        />
                      }
                    />
                </div>
                <DropdownToggle
                  caret
                  color="primary"
                  className="dropdown-toggle-split pl-2 pr-2"
                />
                <DropdownMenu right>
					{showActivate===1? <DropdownItem onClick={() => groupDelete(1)}> <IntlMessages id="pages.activate" /> </DropdownItem>:"" }
					{showDetactivate===1? <DropdownItem onClick={() => groupDelete(0)}> <IntlMessages id="pages.deactivate" /> </DropdownItem>:"" }
					<DropdownItem onClick={() => groupDelete(2)}>
						<IntlMessages id="pages.delete" />
					</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>:""}
            </div>
            
          </div>

          <div className="mb-2">
            <Button
              color="empty"
              className="pt-0 pl-0 d-inline-block d-md-none"
              onClick={this.toggleDisplayOptions}
            >
              <IntlMessages id="pages.display-options" />{" "}
              <i className="simple-icon-arrow-down align-middle" />
            </Button>
            <Collapse
              isOpen={displayOptionsIsOpen}
              className="d-md-block"
              id="displayOptions"
            >
              <span className="mr-3 mb-2 d-inline-block float-md-left">
                <a
                  href="#/"
                  className={`mr-2 view-icon ${
                    displayMode === "list" ? "active" : ""
                  }`}
                  onClick={() => changeDisplayMode("list")}
                >
                  <DataListIcon />
                </a>
                <a
                  href="#/"
                  className={`mr-2 view-icon ${
                    displayMode === "thumblist" ? "active" : ""
                  }`}
                  onClick={() => changeDisplayMode("thumblist")}
                >
                  <ThumbListIcon />
                </a>
                <a
                  href="#/"
                  className={`mr-2 view-icon ${
                    displayMode === "imagelist" ? "active" : ""
                  }`}
                  onClick={() => changeDisplayMode("imagelist")}
                >
                  <ImageListIcon />
                </a>
              </span>

              <div className="d-block d-md-inline-block">
                <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                  <DropdownToggle caret color="outline-dark" size="xs">
                    <IntlMessages id="pages.orderby" />
                    {selectedOrderOption.label}
                  </DropdownToggle>
                  <DropdownMenu>
                    {orderOptions.map((order, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          onClick={() => changeOrderBy(order.column)}
                        >
                          {order.label}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={messages["menu.search"]}
                    onKeyUp={e => onSearchKey(e)}
                  />
                </div>
              </div>
              <div className="float-md-right mr-2">
                <span className="text-muted text-small mr-1">{`${startIndex}-${endIndex>totalItemCount? totalItemCount : endIndex} of ${totalItemCount} `}</span>
                <UncontrolledDropdown className="d-inline-block">
                  <DropdownToggle caret color="outline-dark" size="xs">
                    {selectedPageSize}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {pageSizes.map((size, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          onClick={() => changePageSize(size)}
                        >
                          {size}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Collapse>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      
		<Colxx xxs="12" className={`mb-3 ${
                    displayMode === "list" || displayMode === "thumblist" ? "d-none d-lg-flex " : "d-none"
                  }`}>
				<div className={displayMode === "thumblist" ? "d-none d-lg-flex " : "d-none"} style={{width:'85px'}}>
				
			  </div>
			  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
				<div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center p-2">
					<div className="w-12 w-sm-100">
						<p className="list-item-heading mb-1">
						  ID
						</p>
					</div>
					<div className="w-12 w-sm-100">
						<p className="list-item-heading mb-1">
						  Name
						</p>
					</div>
					<div className="w-12 w-sm-100">
						<p className="list-item-heading mb-1">
						  Dispensary
						</p>
					</div>
					<div className="w-12 w-sm-100">
						<p className="list-item-heading mb-1">
						  Address
						</p>
					</div>
					<div className="w-12 w-sm-100">
						<p className="list-item-heading mb-1">
						  Phone
						</p>
					</div>
					<div className="w-12 w-sm-100">
						<p className="list-item-heading mb-1">
						  Email
						</p>
					</div>
					{register_type=="0" ? <div className="w-9 w-sm-100">
						<p className="list-item-heading mb-1">
						  Profile Type
						</p>
					</div>:""}
					<div className="w-9 w-sm-100">
						<p className="list-item-heading mb-1">
						  Status
						</p>
					</div>
					<div className="w-9 w-sm-100">
						<p className="list-item-heading mb-1">
						  &nbsp;
						</p>
					</div>
				</div>
				<div className="custom-control custom-checkbox pl-1 align-self-center pr-4"></div>
			  </div>
			
		</Colxx>
	  </Row>
    );
  }
}

export default injectIntl(ListPageHeading);
