import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import ReactTable from "react-table";

import IntlMessages from "../../helpers/IntlMessages";
import Pagination from "../../components/DatatablePagination";


const BestSellers = ({ best_sellers }) => {
  const columns = [
    {
      Header: "Product",
      accessor: "name",
      Cell: props => <p className="list-item-heading mb-0">{props.value}</p>
    },
    {
      Header: "Count",
      accessor: "amount",
      Cell: props => <p className="text-muted mb-0">{props.value}</p>
    }
  ];
  return (
    <Card className="h-100 small-padd">
      <CardBody>
        <CardTitle>
          <IntlMessages id={"dashboards.best-sellers"} />
        </CardTitle>
        <ReactTable
          defaultPageSize={5}
          data={best_sellers}
          columns={columns}
          minRows={2}
          showPageJump={false}
          showPageSizeOptions={false}
          PaginationComponent={Pagination}
        />
      </CardBody>
    </Card>
  );
};
export default BestSellers;
