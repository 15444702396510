import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
import swal from 'sweetalert';

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class ConsolidatedSalesReport extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {		 
			reports:[],
			search: '',
			start_date: moment().startOf('day'),
			end_date: moment().endOf('day'),
			blocking: true,
			dataTableRefresh: true,
			currencies:[],
			currency_code:'USD',
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
	}
	
	handleChangeDate = date => {
		this.setState({
			start_date: date
		});
	}
	
	handleChangeEndDate = date => {
		this.setState({
			end_date: date
		});
	} 

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		
		if(this.state.start_date.unix()>this.state.end_date.unix()){
			swal("Warning!", "The start date should be less than or equal to end date!", "warning");				
			return;
		}
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.start_date!=null){
			formData.append('start_date',moment(this.state.start_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('start_date','');
		}
		
		if(this.state.end_date!=null){
			formData.append('end_date',moment(this.state.end_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('end_date','');
		}
		
		
		
		axios.post(REST_API_END_POINT+'report/consolidated-sales-report',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				this.setState({
					reports: data.reports,
					currencies:data.currencies,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: {},
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		var that = this;
		this.setState({ dataTableRefresh:false  });
		setTimeout(function(){
			that.setState({ dataTableRefresh:true },()=>{
				that.$el = $(that.el);	
				that.$el.DataTable({
					dom: 'Bfrtip',
					bPaginate: false,
					bSort: false,
					aaSorting:[],
					buttons: [
						'csv', 'excel', 'pdf', 'print'
					]
				});
			});
		}, 300);
	}		
  
	render() {	
		const reports = this.state.reports;
		const selected_currency = this.state.currencies.find(row=>{ return row.currency_code==this.state.currency_code });
		
		var currency_struct =null;
		
		if(typeof selected_currency!='undefined'){			
			currency_struct = JSON.parse(selected_currency.currency_struct);			
		}
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.consolidated-sales-report" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="5">
								<Label>Start date<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_date"
									name="start_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.start_date}
									onChange={this.handleChangeDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat="MM/DD/YYYY hh:mm a"
								/>
							</Colxx>							
							<Colxx lg="5">
								<Label>End date<span className="text-danger">*</span></Label>
								<DatePicker
									id="end_date"
									name="end_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.end_date}
									onChange={this.handleChangeEndDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat="MM/DD/YYYY hh:mm a"
								/>
							</Colxx>
							<Colxx lg="2">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm> 	
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									{this.state.dataTableRefresh ? <table className="table table-bordered" ref={el=>this.el=el} >
										<thead>
											<tr>
												<th> </th><th> </th>
											</tr>
										</thead>
										<tbody>											
											{reports.map((row,i)=>{
												let total_tax=0;
												let denominations_view=[];
												if(currency_struct!=null){
													for(let key in currency_struct){
														denominations_view.push(<tr key={key}>
																			<td>{currency_struct[key].alt}</td>
																			<td>{row.denominations? ((row.denominations[key]!=null)?row.denominations[key]:'0'):'0' }</td>
																			<td> </td>
																		</tr>);
														
													}
												}
												
												return(<Fragment>
													<tr>
														<td><b>DATE {row.date}</b></td>
														<td> </td>
													</tr>
													<tr><td> </td><td> </td><td> </td></tr>
													<tr>
														<td><b>Total Retail Sales</b></td>
														<td><b>${parseFloat(row.total_sales).toFixed(2)}</b></td>
														<td> </td>
													</tr>
													<tr><td> </td><td> </td><td> </td></tr>
													{row.tax_split_ups.map((r,ii)=>{
														return(<tr>
															<td>{r.tax_name} ({r.tax_rate}%) taxable sales</td>
															<td>${parseFloat(r.taxable_amount).toFixed(2)}</td>
															<td> </td>
														</tr>)
													})}
													<tr><td> </td><td> </td><td> </td></tr>
													<tr>
														<td><b>Tax Break Down</b></td>
														<td><b>Total Tax Collected</b></td><td><b>Estimated Tax Owned</b></td>
													</tr>
													{row.tax_split_ups.map((r,ii)=>{
														total_tax+=parseFloat(r.total_amount)
														return(<tr>
															<td>{r.tax_name} ({r.tax_rate}%)</td>
															<td>${parseFloat(r.total_amount).toFixed(2)}</td>
															<td>${parseFloat(r.estimated_taxable_amount).toFixed(2)}</td>
														</tr>)
													})}
													<tr><td> </td><td> </td><td> </td></tr>
													<tr>
														<td><b>Total Tax Deposit</b></td>
														<td><b>${total_tax.toFixed(2)}</b></td>
														<td> </td>
													</tr>
													<tr>
														<td><b>Total Discount</b></td>
														<td><b>${parseFloat(row.total_discount).toFixed(2)}</b></td>
														<td> </td>
													</tr>
													<tr>
														<td><b>Total Retail Deposit</b></td>
														<td><b>${parseFloat(row.sub_total_sales).toFixed(2)}</b></td>
														<td> </td>
													</tr>
													<tr><td> </td><td> </td><td> </td></tr>
													<tr>
														<td><b>Denomination Break Down</b></td>
														<td> </td><td> </td>
													</tr>
													{denominations_view}
													<tr>
														<td><b>Total</b></td>
														<td><b>${parseFloat(row.denominations.total).toFixed(2)}</b></td>
														<td> </td>
													</tr>
													<tr><td> </td><td> </td><td> </td></tr>
												</Fragment>)
												})
											}
											<tr><td> </td><td> </td><td> </td></tr>
										</tbody>
									</table>:""}
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
