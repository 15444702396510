import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import swal from 'sweetalert';
import {
Row,Button,Input,
	Card, CardBody,
  CustomInput,  
  Label,
  Collapse,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import {Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";

import { CalendarDealToolbar } from "../../../components/CalendarDealToolbar";
import { NavLink } from "react-router-dom";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT,dataEncryptKey} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const localizer = momentLocalizer(moment);

class CalendarDashboard extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			modalOptions: false,
			calendarData:[]
		}
	};
	
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'point-of-sale/daily-deals-calender',formData).then((res)=>{
			var data = res.data;
			this.setState({calendarData:data.deals});
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
    }
	
	handleSelect = ({ start, end }) => {
		console.log('start');
	}
	
	eventStyleGetter(event, start, end, isSelected) {    
		var backgroundColor = event.hexColor;
		var style = {
			backgroundColor: backgroundColor,
		};
		return {
			style: style
		};
	}
	
	render(){    
		return (
		  <Fragment>
			<Row>
			  <Colxx xxs="12">
				<Breadcrumb heading="menu.daily-deals-calendar" match={this.props.match}/>
				<Separator className="mb-5" />
			  </Colxx>
			</Row>
			<Row>			
				<Colxx lg="12"  className="mb-4">				
					<Row>				  
						<Colxx xl="12" lg="12" className="mb-4">
							<Card>
								<CardBody>
									<div className="d-flex align-items-center mb-3">
										<div className="mr-3">
											<span className="color-span-width-20" style={{backgroundColor:'#3f941b'}}></span><span className="d-inline-block"> Mix and Match Deals</span>
										</div>
										<div>
											<span className="color-span-width-20" style={{backgroundColor:'#941b58'}}></span> <span className="d-inline-block"> Daily Deals</span>
										</div>
									</div>
									<Calendar
										selectable
										localizer={localizer}
										style={{ minHeight: "800px" }}
										events={this.state.calendarData}
										views={["month","week"]}
										className="dashboard_calender"
										onSelectEvent={event => swal(event.content_name,event.content_cat,'warning')}
										onSelectSlot={this.handleSelect}
										eventPropGetter={(this.eventStyleGetter)}
										components={{
											toolbar: CalendarDealToolbar
										}}
									/>
								</CardBody>
							</Card>
						</Colxx>			  
					</Row>
				</Colxx>
			</Row>			
		  </Fragment>
		);
	}
}

export default injectIntl(CalendarDashboard);