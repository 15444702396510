import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Table
 } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';

export default class DiscountModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			discount : 0,
			old_discount_value : 0,
			discount_value : 0,
			s_discount_type : 0,
			discount_reason : "",
		};	
	}
	
	componentDidMount(){
		const payment_data = this.props.payment_data;
		var discount = this.state.discount;
		var discount_value = this.state.discount_value;
		var old_discount_value = parseFloat(this.state.discount_value);
		var s_discount_type = this.state.s_discount_type;
		var discount_reason = this.state.discount_reason;
		
		if(payment_data!=null){
			discount = payment_data['discount'];
			discount_value = payment_data['discount_value'];
			old_discount_value = parseFloat(payment_data['discount_value']);
			s_discount_type = payment_data['s_discount_type'];
			discount_reason = payment_data['discount_reason'];
		}
		this.setState({	discount, discount_value,old_discount_value, s_discount_type, discount_reason });
	}
	
	calculateDiscount = ()=>{
		const discount = parseFloat(this.state.discount);		
		const s_discount_type = this.state.s_discount_type;
		const sub_total = this.props.sub_total;
		var discount_value = discount;
		if(s_discount_type=="2"){
			discount_value = parseFloat((sub_total*discount*this.props.currency_rate/100).toFixed(2));
		}
		
		this.setState({	discount_value });
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0){
			var payment_data = this.props.payment_data;
			var discount = this.state.discount;
			var discount_value = this.state.discount_value;
			var s_discount_type = this.state.s_discount_type;
			var discount_reason = this.state.discount_reason;
			
			document.getElementById("discount-form-btn").disabled=true;
			
			payment_data['discount'] = discount;
			payment_data['discount_value'] = discount_value;
			payment_data['s_discount_type'] = s_discount_type;
			payment_data['discount_reason'] = discount_reason;
			
			if(parseFloat(discount_value)>parseFloat(this.props.total+this.state.old_discount_value)){
				swal("Warning!","Discount amount can't be greater than total due amount","warning");
				document.getElementById("discount-form-btn").disabled=false;
				return;
			}
			
			this.props.updatePaymentData(payment_data);
			this.props.recalculateTotals();
			this.props.toggleModal();
		}
	}


	render() {
		const total_due = this.props.total_due;
		return (	  
			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				size="lg"
			>
			<ModalHeader toggle={this.props.toggleModal}>
				Discount
			</ModalHeader>
			<AvForm
		        id="discountForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			>
				<ModalBody>
					<Row>
						<Colxx lg="10">
							<AvGroup>
								<Label>Discount<span className="text-danger">*</span></Label>
								<AvField
									id="discount"
									name="discount"
									type="number"
									value={this.state.discount}
									onChange={(e)=>{ this.setState({ discount:e.target.value },()=>{ this.calculateDiscount() }) }}
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter the discount"
										},
										min: {
											value: "0",
											errorMessage: "Please enter a value greater than 0"
										}
									}}
								/>
							</AvGroup>
						</Colxx>					
						<Colxx lg="2">
							<AvGroup>
								<Label>&nbsp;</Label>
								<AvField
									id="s_discount_type"
									name="s_discount_type"
									type="select"
									value={this.state.s_discount_type}
									onChange={(e)=>{ this.setState({ s_discount_type:e.target.value },()=>{ this.calculateDiscount() }) }}
									autoComplete="off"
								>
									<option value="1" >$</option>
									<option value="2" >%</option>
								</AvField>
							</AvGroup>					
						</Colxx>					
					</Row>
					<Row>
						 <Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Reason for Discount<span className="text-danger">*</span></Label>
								<AvField
									id="discount_reason"
									name="discount_reason"
									type="textarea"
									value={this.state.discount_reason}
									onChange={(e)=>{ this.setState({ discount_reason:e.target.value }) }}
									autoComplete="off"
									rows="4"
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter the reason for discount"
										}
									}}
								/>
								</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx xxs="12">
							<AvGroup >
								<Label>Total Due</Label>
								<AvField
									id="total_due"
									name="total_due"
									type="text"
									readOnly={true}
									placeholder="0"
									value={total_due.toFixed(2)}
								/>
							</AvGroup>
						</Colxx>
					</Row>
				</ModalBody>
				<ModalFooter className="justify-content-start">
					<Button color="primary" id="discount-form-btn">
						<IntlMessages id="customer.submit" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}		  
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};
