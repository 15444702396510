import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';


import allvendors from "./allvendors";
import allServices from "./allServices";
import vendorTypes from "./vendorTypes";
import allGroups from "./allGroups";
import viewVendors from "./viewVendors";
import groupList from "./groupList";
import ReceiveInventoryOrders from "./ReceiveInventoryOrders";
import LabTestingCalendar from "./LabTestingCalendar";


const WholeSale = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
                      
			
          <Route path={`${match.url}/all-vendors`} component={allvendors} />
          <Route path={`${match.url}/all-services`} component={allServices} />
          <Route path={`${match.url}/vendor-types`} component={vendorTypes} />
          <Route path={`${match.url}/all-groups`} component={allGroups} />
          <Route path={`${match.url}/view-vendor`} component={viewVendors} />
          <Route path={`${match.url}/groups-list`} component={groupList} />
		  <Route path={`${match.url}/all-orders`} component={ReceiveInventoryOrders} />
		  <Route path={`${match.url}/lab-testing-calendar`} component={LabTestingCalendar} />
		  <Route path={`${match.url}`} component={allvendors} />
          <Redirect to="/error" />

        </Switch>
    </div>
);
export default WholeSale;