import React, { Component, Fragment } from "react";
import { Row, Button, Card, FormGroup, Input, CustomInput } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";

import Breadcrumb from "../../../containers/navs/Breadcrumb";
import swal from 'sweetalert';
import DataTablePagination from "../../../components/DatatablePagination";
import AddNewCouponModal from "../../../containers/customer/coupons/AddNewCouponModal";
import EditNewCouponModal from "../../../containers/customer/coupons/EditNewCouponModal";
import ReactTable from "react-table";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Coupons extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this); //bind the function if it invoked to another component
		this.state = {	 
			modalOpenAdd: false,
			modalOpenEdit:false,
			data:[],
			selected:{},
			selectedIds:[],
			discount_id:0,
			selectAll:0,
			coupon:[],
			user_privileges:[],
			search: '',
		};
		this.deleteItem = this.deleteItem.bind(this);
	}
		
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.coupon.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        if(status===1){
            msg="Activate the coupon ?";
            success_msg="The coupon has been activated.";
            failure_msg="Failed to activate coupon";
        }else if(status===2){
            msg="Delete the coupon ?";
            success_msg="The coupon has been deleted.";
            failure_msg="Failed to delete coupon";
        }else{
            msg="Deactivate the coupon ?";
            success_msg="The coupon has been deactivated.";
            failure_msg="Failed to deactivate coupon";
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-coupon").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('discount_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'point-of-sale/delete-discount',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-coupon").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(parseInt(data.status)===1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-coupon").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	activeItem=(e,discount_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(discount_id);			
		}else{
		  var x=selectedIds.indexOf(discount_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.coupon.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,discount_id=0) => {
		if(discount_id!=0){
			this.setState({
				discount_id: parseInt(discount_id),
			});
		}
		this.setState({
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'point-of-sale/get-discount-program',formData).then((res)=>{
			var data = res.data;
		
			if(data.discounts){
				this.setState({
					coupon: data.discounts,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					coupon: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
    componentDidMount(){  
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}
	
	render() {		
		let data = this.state.coupon;
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search.toLowerCase()) || row.code.toLowerCase().includes(this.state.search.toLowerCase()) || row.discount.includes(this.state.search)|| row.product_name.includes(this.state.search)|| row.category_name.includes(this.state.search)||row.max_uses.includes(this.state.search)||row.start_date.includes(this.state.search)||row.end_date.includes(this.state.search)
			})
		}
		
		const dataTableColumns = [		
		  {
			Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
			accessor: "id",
			width:50,
			sortable: false,
			Cell: ( rowInfo )=> {
				return ( <FormGroup>
					<CustomInput type="checkbox" id={"coupon_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
					</FormGroup> );
			}
		  },
		  {
			Header: "Name",
			accessor: "name",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		  },
		  {
			Header: "Code",
			accessor: "code",
			Cell: props => <p className="text-muted">{props.value}</p>
		  },
		  {
			Header: "Discount",
			accessor: "discount",
			Cell: props =>  <p className="text-muted">{props.value}</p>
		  },
		  {
			Header: "Buy Product Type",
			accessor: "category_name",
			Cell: props =>  <p className="text-muted">{props.value}</p>
		  },
		  {
			Header: "Buy Product In",
			accessor: "product_name",
			Cell: props =>  <p className="text-muted">{props.value}</p>
		  },
		  {
			Header: "Max Use",
			accessor: "max_uses",
			Cell: props =>  <p className="text-muted">{props.value}</p>
		  },
		  {
			Header: "Start Date",
			accessor: "start_date",
			Cell: props => <p className="text-muted">{props.value}</p>
		  },
		  {
			Header: "End Date",
			accessor: "end_date",
			Cell: props => <p className="text-muted">{props.value}</p>
		  },
		  {
			Header: "",
			accessor: "id",
			sortable: false,
			Cell: props => this.state.user_privileges.indexOf(87)!==-1 ? <Button color="primary" size="sm" onClick={(e)=> this.toggleModalEdit(e,props.value) } >Edit</Button>:"" 
		  }
		];
	
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.coupons" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				
				<Card className="p-4">
				   <Row>
					  
						<Colxx xxs="12">
							<div id="api_message"></div>
						</Colxx>
						<Colxx xxs="12">
							<div className="float-sm-right">
								{" "}{this.state.user_privileges.indexOf(86)!==-1 ?<Button																		 
									  color="primary"
									  size="sm"
									  className=""
									  onClick={this.toggleModalAdd}									  
									>
									  <IntlMessages id="pages.add-new" />							  
								</Button>:""}{" "}{this.state.user_privileges.indexOf(88)!==-1 ?<Button
									color="primary"
									size="sm"
									id="delete-coupon"
									className=""
									onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 							
								  >
									<IntlMessages id="pages.delete" />
								</Button>:""}
							</div>
								
							<div className="mb-4 dropdown-edit">								
								<FormGroup row>
									<Colxx lg="6">
										<Input
										  type="text"
										  name="table_search"
										  id="table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
										/>
									</Colxx>
								</FormGroup>			
									<ReactTable
										data={data}
										columns={dataTableColumns}
										defaultPageSize={10}
										filterable={false}
										showPageJump={false}
										PaginationComponent={DataTablePagination}
										showPageSizeOptions={true}
										defaultSorted={[{id: "send_on",desc: true}]}
									/>
							</div>							
						</Colxx>
					</Row>
				</Card>
				
				{this.state.user_privileges.indexOf(86)!==-1 ?<AddNewCouponModal
					modalOpen={this.state.modalOpenAdd}
					toggleModal={this.toggleModalAdd}
					dataListRender={this.dataListRender}
				  />:""}
				  
				{this.state.user_privileges.indexOf(87)!==-1 ?<EditNewCouponModal
					modalOpen={this.state.modalOpenEdit}
					toggleModal={this.toggleModalEdit}
					discount_id={this.state.discount_id}
					dataListRender={this.dataListRender}
				  />:""}
			</Fragment>
		);
	}
}
