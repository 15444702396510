import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
   Input,
   Alert,
   InputGroupText,
   Card, CardBody, CardTitle,
   
    DropdownItem,
Dropdown,

    DropdownMenu,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import CustomInputExample from "../../containers/forms/CustomInputExample";

import {  ButtonDropdown, DropdownToggle
   
      } from "reactstrap";

  const selectsubCategories = [
    { label: "Tissue Culture Clones", value: "1", key: 0 },
    { label: "Trim", value: "2", key: 1 },
    { label: "Fresh Frozen Flower", value: "3", key: 2 },
    { label: "Frozen Trim", value: "4", key: 3 }
  ];

class ActionModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {
 modalOpenAdd: false,
modalOpenDoc: false, 
		};
	
  }
	
  handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }
  toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	 toggleModalDoc = () => {
		this.setState({
		  modalOpenDoc: !this.state.modalOpenDoc
		});
	};
	
	
	toggleBasic = () => {
    this.setState(prevState => ({
      dropdownBasicOpen: !prevState.dropdownBasicOpen
    }));
  };
  
  toggleBasicd = () => {
    this.setState(prevState => ({
      dropdownBasicOpend: !prevState.dropdownBasicOpend
    }));
  };
  
    toggleBasict = () => {
    this.setState(prevState => ({
      dropdownBasicOpent: !prevState.dropdownBasicOpent
    }));
  };
  
	
	
	
  onChange = e => {
    switch (e.target.name) {
      case 'profile_pic':
		if(e.target.files[0]){
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
		}else{
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
      break;
      default:
        e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
     }
  };


  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Action Stage
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
		  
		  
		  			<Row className="mt-4">
					<Colxx lg="12" className="mb-2">	
					<AvGroup>
					  <Label>Stage Name</Label>
					  <AvField 
						name="suggestion" 
						type="text" 
						rows="2"
						validate={{
							  required: {
								value: true,
								errorMessage: "Please enter the  message"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The message must have atleast 2 characters"
							  }
							}}
						/>
					</AvGroup>
					</Colxx>
				</Row>
		  
		  
			
		

				
			   <Row className="mt-4">
          <Colxx xxs="12">
         <p > When vendor enter this stage, do these actions

</p> 
       <Card>
		
						
		
				<p className="text-center mt-4"> No actions added

</p>  
              </Card>
           
          </Colxx>
        </Row>
				
		

<Row className="mt-4">
					<Colxx lg="12">
						<Label >Select vendor type</Label>
					 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						isMulti
						id="select2-state_id-container"
						value={this.state.selectedOptions}
						onChange={this.handleChangeMulti}
						options={selectsubCategories}
					/>
					</Colxx>									
				</Row>

<Row className="mt-4">
					<Colxx lg="12">
						<Label >Select groups</Label>
					 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						isMulti
						id="select2-state_id-container"
						value={this.state.selectedOptions}
						onChange={this.handleChangeMulti}
						options={selectsubCategories}
					/>
					</Colxx>									
				</Row>


<Row className="mt-4">
					<Colxx lg="12">
						<Label >Select relationship managers</Label>
					 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						isMulti
						id="select2-state_id-container"
						value={this.state.selectedOptions}
						onChange={this.handleChangeMulti}
						options={selectsubCategories}
					/>
					</Colxx>									
				</Row>






		
	   <Dropdown
                  isOpen={this.state.dropdownBasicOpend}
                  toggle={this.toggleBasicd}
                  className="mb-5 mt-4"
                >
                  <DropdownToggle caret color="" outline  >
                    <IntlMessages id="Add an action" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem   onClick={this.toggleModalData} >
                      <IntlMessages id="Set Vendor Type" />
                    </DropdownItem>
                    <DropdownItem  onClick={this.toggleModalQuest} >
                      <IntlMessages id="Add to Groups" />
                    </DropdownItem>
					
					 <DropdownItem  onClick={this.toggleModalQuest} >
                      <IntlMessages id="Set Relationship Manager" />
                    </DropdownItem>
                 
                  </DropdownMenu>
                </Dropdown>			
				
						
								
				
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" >
			  <IntlMessages id="customer.save" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default ActionModal;
