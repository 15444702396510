import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody,  FormGroup, Input, DropdownItem,CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";

import AddDrawersModal from "../../../containers/bankmanagement/drawers/AddDrawersModal";
import EditDrawerModal from "../../../containers/bankmanagement/drawers/EditDrawerModal";
import AssignDrawerModal from "../../../containers/bankmanagement/drawers/AssignDrawerModal";
import AdjustDrawerModal from "../../../containers/bankmanagement/drawers/AdjustDrawerModal";
import CloseDrawerModal from "../../../containers/bankmanagement/drawers/CloseDrawerModal";
import CloseShiftModal from "../../../containers/bankmanagement/drawers/CloseShiftModal";

import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class DrawersManagement extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {		 
			modalOpenAdd: false,
			modalOpenEdit: false,
			modalOpenAssign: false,
			modalOpenAdjust: false,
			modalOpenDrawer: false,
			modalOpenClose: false,
			modalOpenCloseShift: false,
			search: '',
			drawers:[],
			staffs:[],
			currencies:[],
		};
		
		this.deleteItem = this.deleteItem.bind(this);
	}
	
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,drawer_id=0) => {
		this.setState({
			drawer_id: parseInt(drawer_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
	toggleModalAssign = (e,drawer_id=0) => {
		this.setState({
			drawer_id: parseInt(drawer_id),
			modalOpenAssign: !this.state.modalOpenAssign
		});
	};
	
	toggleModalAdjust = (e,drawer_id=0) => {
		this.setState({
			drawer_id: parseInt(drawer_id),
		  modalOpenAdjust: !this.state.modalOpenAdjust
		});
	};
	
	toggleModalDrawer = (e,drawer_id=0) => {
		this.setState({
			drawer_id: parseInt(drawer_id),
			modalOpenDrawer: !this.state.modalOpenDrawer
		});
	};
	
	toggleModalClose = (e,drawer_id=0) => {
		this.setState({
			drawer_id: parseInt(drawer_id),
			modalOpenClose: !this.state.modalOpenClose
		});
	};
	
	toggleModalCloseShift = (e,drawer_id=0) => {
		this.setState({
			drawer_id: parseInt(drawer_id),
			modalOpenCloseShift: !this.state.modalOpenCloseShift
		});
	};
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.drawers.forEach(x => {
				if(x.assigned_status!=1){
					newSelected.push(x.drawer_id);
				}
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    componentDidMount(){
		this.dataListRender();
	}
	
    deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="drawer";
		
		if(selectedIds.length>1){
			msg_word="drawers";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-drawers").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var formData = new FormData();
					formData.append('drawer_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'drawer/delete-drawers',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-drawers").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-drawers").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}   
	   
	activeItem=(e,drawer_ids)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(drawer_ids);
			
		}else{
		  var x=selectedIds.indexOf(drawer_ids);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.drawers.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'drawer/get-all-drawers',formData).then((res)=>{
			var data = res.data;
			var staffs=data.staffs;	
			var currencies=data.currencies;	
			
			staffs = staffs.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			if(data.drawers){
				this.setState({
					drawers: data.drawers,
					selectAll:0,
					selectedIds:[],
					staffs,
					currencies
				});
			}else{
				this.setState({
					drawers: [],
					selectAll:0,
					selectedIds:[],
					staffs,
					currencies
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
	render() {
		let data = this.state.drawers;
	  
		if (this.state.search) {
			data = data.filter(row => {
				if(row.created_by==null){
					row.created_by='';
				}
				return row.drawer_name.toLowerCase().includes(this.state.search) || row.assigned_status_text.toLowerCase().includes(this.state.search) || row.created_by.toLowerCase().includes(this.state.search) || row.created_at_date.toLowerCase().includes(this.state.search) || row.status_text.toLowerCase().includes(this.state.search)
			})
		}
		
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "drawer_id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					if(rowInfo.original.assigned_status!=1){
						return (<FormGroup>
							<CustomInput type="checkbox" id={"drawers_"+rowInfo.original.drawer_id} onChange={event =>this.activeItem(event,rowInfo.original.drawer_id) } checked={this.state.selectedIds.includes(rowInfo.original.drawer_id)} />
							</FormGroup> );
					}else{
						return "";
					}
				}
			},
			{
				Header: "Name",
				accessor: "drawer_name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Assigned Status",
				accessor: "assigned_status",
				Cell: props =>  <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{parseInt(props.value)===1?"Assigned":"Not Assigned"}</Badge>	  },
			{
				Header: "created By",
				accessor: "created_by",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Created At",
				accessor: "created_at_date",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props =>  <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{parseInt(props.value)===1?"Active":"Inactive"}</Badge>
			},
			{
				Header: "Actions",
				accessor: "drawer_id",
				Cell: props => <Fragment><div className="action-btns w-9 w-sm-100"><ItemDropdown item={<DropdownMenu >
						<DropdownItem onClick={(e)=> this.toggleModalEdit(e,props.value) }>
								<span className="d-inline-block">Edit</span>
							</DropdownItem>
							{props.original.assigned_status!=1 ? (props.original.status==1 ? <DropdownItem onClick={(e)=> this.toggleModalAssign(e,props.value) }>
								<span className="d-inline-block">Assign to Bud-tender</span>
							</DropdownItem>:"")	: <Fragment>
							{props.original.status==1 ? <DropdownItem onClick={(e)=> this.toggleModalAdjust(e,props.value) }>
								<span className="d-inline-block">Adjust Drawer</span>
							</DropdownItem>:""}
							<DropdownItem onClick={(e)=> this.toggleModalAdjust(e,props.value) }>
								<NavLink to={"/app/bankmanagement/drawer-view/"+props.value} className="btn-block" >View Drawer Details</NavLink>
							</DropdownItem>	
							<DropdownItem onClick={(e)=> this.toggleModalCloseShift(e,props.value) }>
								<span className="d-inline-block">Close Shift</span>
							</DropdownItem>	
							<DropdownItem onClick={(e)=> this.toggleModalClose(e,props.value) }>
								<span className="d-inline-block">Close Drawer</span>
							</DropdownItem>	</Fragment>}
						 </DropdownMenu>} /></div></Fragment> 
			}
		];  
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
             <Breadcrumb heading="sidebar.drawersmanagement" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
			  <Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx> 
			  <Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  <IntlMessages id="pages.add-new" />							  
						</Button>
                        						
						   {" "}<Button
								id="delete-drawers"
								color="primary"
								size="sm"
								className=""	
								onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 								
							  >
								<IntlMessages id="pages.delete" />
							</Button>
					</div>
					 
					<div className="mb-4 dropdown-edit">						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={10}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>
					
			  </Colxx>
			</Row>
			<AddDrawersModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				dataListRender={this.dataListRender}
			/>
			<EditDrawerModal
				modalOpen={this.state.modalOpenEdit}
				toggleModal={this.toggleModalEdit}
				drawer_id={this.state.drawer_id}
				dataListRender = {this.dataListRender}
			/>
			  
			<AssignDrawerModal
				modalOpen={this.state.modalOpenAssign}
				toggleModal={this.toggleModalAssign}
				drawer_id={this.state.drawer_id}
				dataListRender = {this.dataListRender}
				staffs={this.state.staffs}
				currencies={this.state.currencies}
			/>
			<AdjustDrawerModal
				modalOpen={this.state.modalOpenAdjust}
				toggleModal={this.toggleModalAdjust}
				drawer_id={this.state.drawer_id}
				dataListRender = {this.dataListRender}
				currencies={this.state.currencies}
			/>
			<CloseDrawerModal
				modalOpen={this.state.modalOpenClose}
				toggleModal={this.toggleModalClose}
				drawer_id={this.state.drawer_id}
				dataListRender = {this.dataListRender}
				currencies={this.state.currencies}
			/>
			
			<CloseShiftModal
				modalOpen={this.state.modalOpenCloseShift}
				toggleModal={this.toggleModalCloseShift}
				drawer_id={this.state.drawer_id}
				dataListRender = {this.dataListRender}
				staffs={this.state.staffs}
				currencies={this.state.currencies}
			/>
			  
		</Card>
      </Fragment>
    );
  }
}
