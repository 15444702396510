import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddColumnsModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);	
	}
	  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {			
			document.getElementById("add-columns").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("columnForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('container_type',this.props.container_type);

			axios.post(REST_API_END_POINT+'processor/add-column',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-columns").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-columns").disabled=false;
			});

		}
	}  
  
	render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
	     	Add {this.props.modalHead}
		  </ModalHeader>
			<AvForm
				id="columnForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>{this.props.modalHead} UID<span className="text-danger">*</span></Label>
							  <AvField
								id="container_name"
								name="container_name"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }								  
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<Row>
								<Colxx lg="4">
									<AvGroup className="error-t-negative">
									  <Label>{this.props.modalHead} Capacity</Label>						 
									</AvGroup>
								</Colxx>
								<Colxx lg="4">
									<AvGroup className="error-t-negative">
										<Label>Lbs<span className="text-danger">*</span></Label>
										<AvField
											id="container_capacity_lbs"
											name="container_capacity_lbs"
											type="number"
											autoComplete="off"
											validate={{
											  required: {
												value: true,
												errorMessage: "This field is required."
											  },
											  min:{
												value: "0",
												errorMessage: "Value should be greater than 0."
											  }
											}}
										/>
									</AvGroup>
								</Colxx>
								<Colxx lg="4">
									<AvGroup className="error-t-negative">
										<Label>Grams<span className="text-danger">*</span></Label>
										<AvField
											id="container_capacity_grams"
											name="container_capacity_grams"
											type="number"
											autoComplete="off"
											validate={{
											  required: {
												value: true,
												errorMessage: "This field is required."
											  },											  
											  min:{
												value: "0",
												errorMessage: "Value should be greater than 0."
											  },										  
											  max:{
												value: "454",
												errorMessage: "Value should be less than 454."
											  }
											}}
										/>
									</AvGroup>
								</Colxx>
							</Row>
						</Colxx>					
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-columns" >
					  <IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
	  </Modal>
	  );
  }
};

export default AddColumnsModal;
