import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, DropdownItem, CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddProductCategoryModal from "../../../containers/inventory/product-categories/AddProductCategoryModal";
import EditProductCategoryModal from "../../../containers/inventory/product-categories/EditProductCategoryModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
var dataCrypto = new SimpleCrypto(dataEncryptKey);


export default class AllProductCategories extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		this.state = {
			modalOpenAdd: false,
			modalOpenEdit: false,
			search: '',
			selectedIds:[],
			product_categories:[],
			parent_categories:[],
			metric_categories:[],
			user_privileges:[],
			category_id:0,	  
		};
		this.deleteItem = this.deleteItem.bind(this);
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.product_categories.forEach(x => {
				if(x.weed_id==null){
					newSelected.push(x.category_id);
				}
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
		
	deleteItem = (e,selectedIds,status)  => {
		if(selectedIds.length==0){
			swal("Warning!","Please select at least one category","warning");
			return;
		}
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="product category";
		
		if(selectedIds.length>1){
			msg_word="product categories";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-product-category").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('category_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'inventory/delete-product-categories',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-product-category").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-product-category").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}	
		
	activeItem=(e,category_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(category_id);			
		}else{
			var x=selectedIds.indexOf(category_id);
			if(x>=0){
				selectedIds.splice(x,1);
			}
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.product_categories.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,category_id=0) => {
		this.setState({
			category_id: parseInt(category_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'inventory/product-categories',formData).then((res)=>{
			var data = res.data;
			
			var metric_categories = data.metric_categories;
				metric_categories = metric_categories.map((row,index)=>{
					row.value=row.Name;
					row.key=index;
					row.label=row.Name;
					return row;
				});
				
			if(data.product_categories){
				var parent_categories = data.product_categories.filter(row=>{ return row.parent_category==0 || row.parent_category==null });
				parent_categories = parent_categories.map(row=>{
					row.value=row.category_id;
					row.key=row.category_id;
					row.label=row.name;
					return row;
				});
				parent_categories.unshift({value:0,key:0,label:'-- Select Parent Category --'})
				
				this.setState({
					product_categories: data.product_categories,
					parent_categories,
					metric_categories,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					product_categories: [],
					parent_categories: [],
					metric_categories,
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidMount(){
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}

	render() {
	  let data = this.state.product_categories
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search) || row.parent_name.toLowerCase().includes(this.state.search) || row.status.includes(this.state.search)
             
			})
		}
		
	const dataTableColumns = [		
		{
			Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
			accessor: "category_id",
			width:50,
			sortable: false,
			Cell: ( rowInfo )=> {
				if(rowInfo.original.weed_id==null){
				return ( <FormGroup>
					<CustomInput type="checkbox" id={"coupon_"+rowInfo.original.category_id} onChange={event =>this.activeItem(event,rowInfo.original.category_id) } checked={this.state.selectedIds.includes(rowInfo.original.category_id)} />
					</FormGroup> );
				}else{
					return "";
				}
			}
		},
		{
			Header: "Name",
			accessor: "name",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Parent Category",
			accessor: "parent_name",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Status",
			accessor: "status",
			Cell: props =>  <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{parseInt(props.value)===1?"Active":"Inactive"}</Badge>
		},	  	  
		{
			Header: "Actions",
			accessor: "category_id",
			sortable: false,
			Cell: props => this.state.user_privileges.indexOf(54)!==-1 || this.state.user_privileges.indexOf(55)!==-1 ? <Fragment>
					<div className="action-btns w-9 w-sm-50 p-0">
						<ItemDropdown item={<DropdownMenu >
								{this.state.user_privileges.indexOf(54)!==-1 ? <DropdownItem onClick={(e)=> this.toggleModalEdit(e,props.value) } >
									<span className="d-inline-block">Edit</span>						
								</DropdownItem>:""}		
								{props.original.weed_id==null && this.state.user_privileges.indexOf(55)!==-1 ? <DropdownItem onClick={event =>this.deleteItem(event,[props.value],2)} >
									<span className="d-inline-block" >Delete</span>
								</DropdownItem>:""}
							</DropdownMenu>}
						/>
					</div>
				</Fragment>:""
		}
	];
    return (
      <Fragment>
	    <Row>		
          <Colxx xxs="12">
            <Breadcrumb heading="menu.product-category" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>	
		<Card className="p-4">
		   <Row>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
				<Colxx xxs="12">
					<div className="float-sm-right">
						{" "}{this.state.user_privileges.indexOf(53)!==-1 ? <Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}							  
							>
							  Add New							  
						 </Button>:""}{" "}{this.state.user_privileges.indexOf(55)!==-1 ? <Button																		 
								color="primary"
								size="sm"
								className=""
								id="delete-product-category"
								onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 							  
							>
							  Delete							  
						</Button>:""}																								   
					</div>
					
					<div className="mb-4 dropdown-edit">						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={10}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: false}]}
						/>
					</div>
					
				</Colxx>
			</Row>
			{this.state.user_privileges.indexOf(53)!==-1 ? <AddProductCategoryModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				parent_categories={this.state.parent_categories}
				metric_categories={this.state.metric_categories}
				dataListRender={this.dataListRender}
			/>:""}
			{this.state.user_privileges.indexOf(54)!==-1 ? <EditProductCategoryModal
				modalOpen={this.state.modalOpenEdit}
				toggleModal={this.toggleModalEdit}
				category_id={this.state.category_id}
				parent_categories={this.state.parent_categories}
				metric_categories={this.state.metric_categories}
				dataListRender={this.dataListRender}
			/>:""}
		</Card>
      </Fragment>
    );
  }
}
