import React, { Component } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { NavLink } from "react-router-dom";
import IntlMessages from "../../helpers/IntlMessages";

import { REST_API_END_POINT,dataEncryptKey } from "../../constants/defaultValues";
import axios from 'axios';
import swal from 'sweetalert';
import SimpleCrypto from "simple-crypto-js";
import { getCookie, setCookie } from "../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const onClickHandler = (facility_id) => {
	var form_data = new FormData();
	form_data.append("facility_id",facility_id);
	
	axios.post(REST_API_END_POINT+'register/get-facility-data',form_data).then((data)=>{					
		if(data.data.status==1){
			localStorage.setItem('facility_id', dataCrypto.encrypt(facility_id));
			localStorage.setItem('facility_data', dataCrypto.encrypt(data.data.facility_data));
			setCookie('pos_customer_id',null);
			setCookie('cart',null);
			setCookie('currency_code',null);
			setCookie('bogo_code',null);
			setCookie('payment_data',null);
			setCookie('change_data',null);
			localStorage.removeItem('shopCartCoupon');
			localStorage.removeItem('shopCartCouponCode');
			localStorage.removeItem('shopCartItems');
			window.location.reload();
		}					
	}).catch(e => console.log("Addition failed , Error ", e));
}

class TopnavEasyAccess extends Component {
	constructor(props) {
		super(props);
		this.state = {
			facilities: [],
		};		
	}
	  
	componentDidMount() {
		var formData = new FormData();
		formData.append('subscriber_id',1);
		
		axios.post(REST_API_END_POINT+'front-end/get-facilities',formData).then((res)=>{
			var data = res.data;
		
			var facilities=data.facilities;		
			this.setState({
				facilities
			});
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	render() {		
		var facilities = this.state.facilities;
		
		var facility_id = localStorage.getItem('facility_id');
		if(facility_id!=null){
			facility_id =  dataCrypto.decrypt(facility_id);
		}
		
		if(facilities.length>1){
		  return (<div className=" navbar front-nav-bar-fac header-icons d-inline-block align-middle">
			<div className="position-relative d-inline-block">
			  <UncontrolledDropdown className="dropdown-menu-right">
				<DropdownToggle className="header-icon" color="empty">
				  <i className="simple-icon-home" />
				</DropdownToggle>
				<DropdownMenu
				  className="position-absolute mt-3"
				  right
				  id="iconMenuDropdown"
				>
				{facilities.map(row=>{
					return (<a key={row.id} className={"icon-menu-item cursor-pointer "+(facility_id==row.id ? "active-facility":"")} onClick={()=>{ onClickHandler(row.id) }}>
						<i className="simple-icon-home d-block" />{" "}
						{row.name}
					</a>)
				})}
				  
				</DropdownMenu>
			  </UncontrolledDropdown>
			</div>
			</div>
		  );
		}else{
			return "";
		}
	}
};

export default TopnavEasyAccess;
