import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import accountManagement from "./accountManagement";
import completedOrders from "./completedOrders";
import allDistribution from "./allDistribution";
import viewaccount from "./viewaccount";

const Account = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
                      
			<Route path={`${match.url}/all-accounts`} component={accountManagement} />
			<Route path={`${match.url}/completed-orders`} component={completedOrders} />			
            <Route path={`${match.url}/all-distributions`} component={allDistribution} />
			<Route path={`${match.url}/view-account/:account_id`} component={viewaccount} />
			<Route path={`${match.url}`} component={accountManagement} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Account;