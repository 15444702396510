import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,Card, CardBody

} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { uuid } from 'uuidv4';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor} from "../../../constants/defaultValues";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class EditTaxModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.addRow = this.addRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		
		this.state = {
			modalOpenAdd: false,
			blocking: false,
			taxCategory:null,
			tax_rate:"",
			is_tax_split:false,
			tax_split_up:[
				{
					id:uuid(),
					name:"",
					rate:0,
				}
			]
		};
	}
  
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {			
			document.getElementById("update-tax").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("taxForm"));
			formData.append('tax_id',this.props.tax_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('is_tax_split',this.state.is_tax_split ? 1:0);
			if(this.state.is_tax_split){
				formData.append('tax_split_up',JSON.stringify(this.state.tax_split_up));
			}

			axios.post(REST_API_END_POINT+'inventory/edit-tax-category',formData).then((res)=>{
				var data = res.data;
				
				var taxCategory=this.state.taxCategory;
				taxCategory.name=values.name;
				this.setState({ taxCategory:taxCategory });
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-tax").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-tax").disabled=false;
			});

		}
	} 
  
	componentDidUpdate(prevProps, prevState, snapshot){
	   
        if(this.props.modalOpen){
			let taxCategory; 		  
            if (prevProps.tax_id !== this.props.tax_id && this.props.tax_id!=0) {
				this.setState({ blocking:true });
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData();
				formData.append('tax_id',this.props.tax_id);
				formData.append('facility_id',facility_id);
	
				axios.post(REST_API_END_POINT+'inventory/get-tax-category-by-id',formData).then((res)=>{
					var data = res.data;
					var status=null;
					taxCategory=data.result;
					let tax_rate = taxCategory.tax_rate;
					let is_tax_split = taxCategory.is_tax_split==1;
					let tax_split_up = taxCategory.tax_split_up!=null ? JSON.parse(taxCategory.tax_split_up):[ {id:uuid(), name:"", rate:0 }];
					this.setState({ 
						taxCategory,
						statusType:status,
						blocking:false,
						tax_rate,
						tax_split_up,
						is_tax_split
					});
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					this.setState({ blocking:false });
				});	
			}
		}
	}
	
	addRow(){		
		let tax_split_up = this.state.tax_split_up;
		tax_split_up.push({
			id:uuid(),
			name:"",
			rate:0,
		});
		this.setState({ tax_split_up });
	};
	
	deleteRow(id){		
		let tax_split_up = this.state.tax_split_up;
		tax_split_up = tax_split_up.filter(r=>{ return r.id!=id });
		
		let tax_rate = tax_split_up.map(r=>{ return r.rate }).reduce((total, num)=>{ return parseFloat(total)+parseFloat(num) });
		tax_rate = parseFloat(tax_rate).toFixed(2);
		
		this.setState({ tax_split_up, tax_rate });
	};
  
	render(){	
        if(this.state.taxCategory!=null){
			var taxCategory=this.state.taxCategory;
		}else{
           var taxCategory;
		}
	  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Tax Category
		  </ModalHeader>
		  <AvForm
		        id="taxForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
			
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Name<span className="text-danger">*</span></Label>
							  <AvField
								id="cname"
								name="name"
								type="text"
								autoComplete="off"
								value={ taxCategory?((taxCategory.name!=null)?taxCategory.name:''):'' }
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								  pattern: {
									value: "^[a-zA-Z ,.'-]+$",
									errorMessage: "Invalid name"
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "Your first name must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "Your first name must be between 2 and 100 characters"
								  }
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12" className="mb-2">
							<Label>													  
								<Switch
									className="custom-switch custom-switch-primary im-inline-block"
									checked={this.state.is_tax_split}
									onChange={status => {
										let tax_rate = this.state.tax_rate;
										if(!this.state.is_tax_split){
											tax_rate =  this.state.tax_split_up.map(r=>{ return r.rate }).reduce((total, num)=>{ return parseFloat(total)+parseFloat(num) });
											tax_rate = parseFloat(tax_rate).toFixed(2);
										}
										this.setState({ is_tax_split:!this.state.is_tax_split, tax_rate }) 
									}}
								/>
								<span className="h6 ml-4">Enable Tax Split Up</span>
							</Label>
						</Colxx>
					</Row>
					{this.state.is_tax_split ? <Fragment>{this.state.tax_split_up.map((row,index)=>{
							return (<Card className="mt-2" key={index}>
								<CardBody className="p-2">
									<Row>
										<Colxx md="6">
											<AvGroup >
												<Label>Name<span className="text-danger">*</span></Label>
												<AvField
													name={"split_name["+index+"]"}
													type="text"
													autoComplete="off"
													validate={{
														required: {
															value: true,
															errorMessage: "Please enter numeric value"
														},
													}}
													value={row.name}						
													onChange={e => {														
														let tax_split_up = this.state.tax_split_up;
														tax_split_up = tax_split_up.map(r=>{
															if(r.id==row.id){
																r.name = e.target.value;
															}
															return r;
														})
														this.setState({ tax_split_up })														
													}}
												/>
											</AvGroup>
										</Colxx>
										<Colxx md="5">
											<AvGroup >
												<Label>Tax Rate(%)<span className="text-danger">*</span></Label>
												<AvField
													name={"rate["+index+"]"}
													type="number"
													autoComplete="off"
													validate={{
														required: {
															value: true,
															errorMessage: "Please enter numeric value"
														},
														min: {
															value: "0",
															errorMessage: "Please enter a value greater than or equal to 0"
														}
													}}
													value={row.rate}						
													onChange={e => {														
														let tax_split_up = this.state.tax_split_up;
														tax_split_up = tax_split_up.map(r=>{
															if(r.id==row.id && e.target.value>=0){
																r.rate = e.target.value;
															}
															return r;
														});
														let tax_rate = tax_split_up.map(r=>{ return r.rate }).reduce((total, num)=>{ return parseFloat(total)+parseFloat(num) });
														tax_rate = parseFloat(tax_rate).toFixed(2);
														this.setState({ tax_split_up, tax_rate })														
													}}
												/>
											</AvGroup>
										</Colxx>
										{index!==0 ? <Colxx md="1" className="text-center">
											<span className="text-danger d-inline-block mt-4" onClick={()=>{ this.deleteRow(row.id); }}><i className="fas fa-2x fa-trash-alt"></i></span>
										</Colxx>:""}
									</Row>
								</CardBody>
							</Card>)
						})}
						<div className="mt-4 text-center">
							<Button color="primary" onClick={this.addRow}>Add Row</Button>
						</div>
					</Fragment>:""}					
					<Row className="mt-2">
						<Colxx lg="12">
						 <AvGroup className="error-t-negative">
							  <Label>Tax Rate (%)<span className="text-danger">*</span></Label>
							  <AvField
								id="tax_rate"
								name="tax_rate"
								type="number"
								min='0'
								autoComplete="off"
								value={this.state.tax_rate}
								readOnly={this.state.is_tax_split}
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}
								onChange={(e)=>{
									this.setState({ tax_rate:e.target.value })
								}}
							  />
							</AvGroup>
						</Colxx>									
					</Row>
				</BlockUi>				
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update-tax" >Update</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditTaxModal;
