import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, FormGroup, Input, Label, CustomInput, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import number_format from "number_format-php";
import swal from 'sweetalert';

import {REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, LBS_CONSTANT} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class RawMaterialStaging extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			containers:[],
			product_packages:[],
			consolidations:[],
			container_uid:null,
			container_capacity:0,
			in_house_weight_lbs_val:"",
			in_house_weight_gram_val:"",
			package_uid:null,		  
		};
		
		this.getContainerDetails = this.getContainerDetails.bind(this);
		this.addConsolidation = this.addConsolidation.bind(this);
		this.removeConsolidation = this.removeConsolidation.bind(this);
		this.completeConsolidation = this.completeConsolidation.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount(){
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'processor/raw-material',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				var containers = data.containers;
				containers = containers.map(row=>{
					row.label=row.container_name+" ("+row.barcode+")";
					row.value=row.container_id;
					row.key=row.container_id;
					return row;
				});
				
				var product_packages = data.packages;
				product_packages = product_packages.map(row=>{
					row.label=row.name+" ("+row.barcode+")";
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				
				this.setState({
					containers,
					product_packages
				});
			}else{
				this.setState({					
					containers:[],
					product_packages: [],
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	
	getContainerDetails(container_id) {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('container_id',container_id);
		
		axios.post(REST_API_END_POINT+'processor/get-container-details',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){				
				var consolidations = data.consolidations;
				var container_capacity = data.container_capacity;
				this.setState({
					consolidations,
					container_capacity
				});
			}else{
				this.setState({					
					consolidations:[],
					container_capacity:0,
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	addConsolidation() {
		const container_uid=this.state.container_uid;
		const package_uid=this.state.package_uid;
		const consolidations=this.state.consolidations;
		const container_capacity=this.state.container_capacity;
		if(container_uid!=null){
			var container_id=container_uid.value;
			if(package_uid!=null){
				var package_id=package_uid.value;
				var in_house_weight_lbs_val =0;
				var in_house_weight_gram_val =0;
				
				var package_current_qty = parseFloat(package_uid.quantity);
				
				if(this.state.in_house_weight_lbs_val!=''){
					in_house_weight_lbs_val = parseFloat(this.state.in_house_weight_lbs_val);
				}
				if(this.state.in_house_weight_gram_val!=''){
					in_house_weight_gram_val = parseFloat(this.state.in_house_weight_gram_val);
				}
				var in_house_weight = parseFloat(((in_house_weight_lbs_val*LBS_CONSTANT)+in_house_weight_gram_val).toFixed(4));
				if(package_current_qty<in_house_weight){
					let package_current_qty_lbs=parseInt(package_current_qty/LBS_CONSTANT);
					let	package_current_qty_grams=parseFloat(((package_current_qty/LBS_CONSTANT)%1)*LBS_CONSTANT).toFixed(4);
					swal("Warning!",'The package has only '+package_current_qty_lbs+' Lbs '+package_current_qty_grams+' Grams ',"warning");
					return;
				}
				
				var total_grams=0;
				if(consolidations.length){
					total_grams = parseFloat(consolidations.map(r=>r.in_house_weight).reduce((total,in_house_weight)=>{ return parseFloat(total)+parseFloat(in_house_weight) }));
				}
								
				if(parseFloat((total_grams+in_house_weight).toFixed(2))>container_capacity){
					swal("Warning!",'The addition of this package will exceed the container capacity ',"warning");
					return;
				}
				
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('facility_id',facility_id);
				formData.append('container_id',container_id);
				formData.append('package_id',0);
				formData.append('sack_stock_id',package_id);
				formData.append('in_house_weight',in_house_weight);
				
				axios.post(REST_API_END_POINT+'processor/add-consolidation',formData).then((res)=>{
					var data = res.data;
					
					if(data.status==1){				
						let consolidations = data.consolidations;
						var product_packages = this.state.product_packages.filter(row=>{ return row.id!=package_id });
						this.setState({
							consolidations,
							product_packages,
							package_uid:null, 
							in_house_weight_lbs_val:"",
							in_house_weight_gram_val:""
						});
					}else{
						swal("Warning!","Failed consolidatie package to container","warning");
					}
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			}else{
				swal("Warning!","Please select a package","warning");
			}
		}else{
			swal("Warning!","Please select a container","warning");
		}
	}
	
	removeConsolidation(biomass_id) {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('biomass_id',biomass_id);
		
		axios.post(REST_API_END_POINT+'processor/remove-consolidation',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				var product_packages = data.packages;
				product_packages = product_packages.map(row=>{
					row.label=row.name+" ("+row.barcode+")";
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				
				var consolidations = this.state.consolidations.filter(row=>{ return row.biomass_id!=biomass_id });
				this.setState({
					consolidations,
					product_packages
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	completeConsolidation(){
		this.setState({
			consolidations:[],
			container_uid:null,
			container_capacity:0,
			in_house_weight_lbs_val:"",
			in_house_weight_gram_val:"",
			package_uid:null,
		});
	}
	
	handleSubmit(event, errors, values) {
		return;
	}
	
	render() {
		const { containers, product_packages, consolidations, container_capacity } = this.state;
		var total_grams=0;
		var total_container_weight_lbs=0;
		var total_container_weight_gram=0;
		
		var final_consolidations=consolidations.map((consolidation,index)=>{			
			total_grams+=parseFloat(consolidation.in_house_weight);
			return(<Row className="align-items-center mb-3" key={index}>
						<Colxx lg="4">
							<Input
								readOnly={true}
								value={consolidation.barcode}
							/>
						</Colxx>
						<Colxx lg="8">
							<Row className="align-items-center">
								<Colxx lg="5">
									<Input
										readOnly={true}
										value={consolidation.in_house_weight_lbs+" (Lbs)"}
									/>
								</Colxx>
								<Colxx lg="5">
									<Input
										readOnly={true}
										value={consolidation.in_house_weight_gram+" (Grams)"}
									/>
								</Colxx>
								<Colxx lg="2">
									<span className="text-danger" onClick={()=>{ this.removeConsolidation(consolidation.biomass_id) }}><i className="fas fa-2x fa-times" /></span>
								</Colxx>
							</Row>
						</Colxx>	
					</Row>);
		})
		total_container_weight_lbs=parseInt(total_grams/LBS_CONSTANT);
		total_container_weight_gram=parseFloat(((total_grams/LBS_CONSTANT)%1)*LBS_CONSTANT).toFixed(5);
		
		var fill = 0;
		if(container_capacity!=0){
			fill = (total_grams/container_capacity)*100;
		}
		
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading_text="Biomass Consolidation And Labelling" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
			<AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<Row>
					<Colxx lg="12" className="mb-4">
						<Row className="processor-container-fill">
							<Colxx lg="10">
								<AvGroup className="error-t-negative">
									<Label>Enter/Scan Destination Container UID</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="container_uid"
										id="container_uid"
										placeholder="-- Select Container --"
										value={this.state.container_uid}						
										options={containers}
										onChange={container_uid => { 
											this.setState({ container_uid }); 
											if(container_uid!=null){
												this.getContainerDetails(container_uid.value);
											}
										}}
									/>
								</AvGroup>
							
								<Row>
									<Colxx lg="4" className="mb-4">
										<AvGroup className="error-t-negative">
											<Label>Enter/Scan Package UID To Consolidate</Label>
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"						
												name="package_uid"
												id="package_uid"
												placeholder="-- Select Packages --"
												value={this.state.package_uid}						
												options={product_packages}
												onChange={package_uid => { 
													if(package_uid!=null){
														let in_house_weight_lbs_val=parseInt(package_uid.quantity/LBS_CONSTANT);
														let	in_house_weight_gram_val=parseFloat(((package_uid.quantity/LBS_CONSTANT)%1)*LBS_CONSTANT).toFixed(4);
														this.setState({ package_uid, in_house_weight_lbs_val, in_house_weight_gram_val })
													}else{
														this.setState({ package_uid })
													}
												}}
											/>
										</AvGroup>
									</Colxx>
									<Colxx lg="4" className="mb-4 form-group-mb-0">
										<AvGroup>
											<Label>In House Weight</Label>
											<AvField
												id="in_house_weight_lbs_val"
												name="in_house_weight_lbs_val"
												type="number"												
												placeholder="(Lbs)"
												value={this.state.in_house_weight_lbs_val}
												onChange={e => { this.setState({ in_house_weight_lbs_val:e.target.value }) }}												
											/>
											<Label>Lbs</Label>
										</AvGroup>
									</Colxx>						
									<Colxx lg="4" className="mb-4 form-group-mb-0">
										<AvGroup>
											<Label>&nbsp;</Label>
											<AvField
												id="in_house_weight_gram_val"
												name="in_house_weight_gram_val"
												type="number"	
												placeholder="(Grams)"
												value={this.state.in_house_weight_gram_val}
												onChange={e => { this.setState({ in_house_weight_gram_val:e.target.value }) }}
											/>
											<Label>Grams</Label>
										</AvGroup>
									</Colxx>
								</Row>
								
								<Row className="text-center mb-4">
									<Colxx lg="6">
										<Button	color="success" block size="lg" className="mr-1" onClick={this.addConsolidation} >Consolidate Next Package To Container</Button> 
									</Colxx>
									<Colxx lg="6">
										<Button color="danger" type="button" block size="lg" className="mr-1" onClick={this.completeConsolidation} >Complete Container Consolidation</Button>
									</Colxx>
								</Row>
								
								<div>
								{final_consolidations}
								</div>
								
								<Row className="align-items-center">
									<Colxx lg="4">
										<Label>Total Container Weight </Label>
									</Colxx>
									<Colxx lg="8">
										<Row className="align-items-center">
											<Colxx lg="5">																	
												<Input
													readOnly={true}
													value={total_container_weight_lbs+" (Lbs)"}
												/>
											</Colxx>
											<Colxx lg="5">
												<Input
													readOnly={true}
													value={total_container_weight_gram+" (Grams)"}
												/>
											</Colxx>
										</Row>
									</Colxx>
								</Row>
							</Colxx>
							<Colxx lg="2">
								<div className="meter animate">
									<span style={{height: (fill*3.52)+"px"}}><span></span></span>
								</div>
								
								<h2 className="text-center mt-3 mb-0" id="container_filled">{number_format(parseFloat(fill),2,'.','')}%</h2>
								<p className="text-center mb-0" style={{fontSize:'14px'}}>is occupied</p>
								<p className="text-center mb-2" style={{fontSize:'14px'}}>Capacity: <span>{parseInt(container_capacity/LBS_CONSTANT)+' Lbs '+parseFloat(((container_capacity/LBS_CONSTANT)%1)*LBS_CONSTANT).toFixed(0)+' Grams'}</span></p>
							</Colxx>
							
						</Row>

					</Colxx>	 
				</Row>						
			</AvForm>
		</Card>
		
      </Fragment>
    );
  }
}
