import React, { Component } from "react";
import { AvForm, AvField, AvGroup,AvInput } from "availity-reactstrap-validation";
import {
  CustomInput,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import axios from 'axios';
import swal from 'sweetalert';
import SignaturePad from 'react-signature-pad';
import { zeroPad } from "../../helpers/Utils";
import number_format from "number_format-php";
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class SignShippingModal extends Component{
  constructor(props) {
    super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.submitSignature = this.submitSignature.bind(this);
		
		this.state = {
			order_data: null,
			order_products: null
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.delivery_id!== this.props.delivery_id && this.props.delivery_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('delivery_id',this.props.delivery_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'transportation/get-delivery-detail',formData).then((res)=>{
					
					var data = res.data;
					if(data.status==1){
						this.setState({ 
							order_data:data.order_data,
							order_products:data.order_products
						});
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such order","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }

	handleSubmit(event, errors, values) {
		console.log(errors);
		console.log(values);
	}

	submitSignature() {		
		var signature = this.refs.mySignature;
		if(signature.isEmpty()){
			swal("Please put your signature before approve!");
		}else{
			document.getElementById('add-sign-photo').disabled=true; 
			var attachment = signature.toDataURL();
			
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);			
			var user_id = localStorage.getItem('user_id');
			var formData = new FormData();
			formData.append('delivery_id',this.props.delivery_id);
			formData.append('user_id',user_id);
			formData.append('facility_id',facility_id);
			formData.append('sign_agreement_image_hidden',attachment);
			
			axios.post(REST_API_END_POINT+'transportation/sign-shipping-manifest',formData).then((res)=>{
				var data = res.data;
				if(data.status==1){
					this.props.dataListRender();
					signature.clear();
					this.props.toggleModal();
					swal("Success",data.msg,"success");
				}else{
					swal("Failed",data.msg,"warning");
				}
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}
	}
  
	render() {	
		const { order_data, order_products } = this.state;
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<AvForm
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
				
					<ModalHeader toggle={this.props.toggleModal}>
						Sign Shipping Manifest
					</ModalHeader>
					<ModalBody>	
						{order_data!=null ? <h2 className="mb-4 font-weight-bold">#{zeroPad(order_data.order_number,6)}</h2>:""}
						<Table border >
							<thead>
								<tr>
									<th>Product</th>
									<th>Quantity</th>
								</tr>
							</thead>
							
							<tbody>
								{order_data!=null ? order_products.map((row,index)=>{
									return(<tr key={index}>
										<td>{row.product_name}</td>
										<td>{number_format(parseFloat(row.product_qty),2,'.','')}</td>
									</tr>)
								}):null}
							</tbody>					
							
						</Table>							
						
						<div className="left-aligned-sign">
							<SignaturePad ref="mySignature" />
						</div>
					
					</ModalBody>
					<ModalFooter className="justify-content-center">
						<Button color="primary" id="add-sign-photo" onClick={this.submitSignature} >
							Submit
						</Button>
						{" "}
						<Button onClick={()=>{
							var signature = this.refs.mySignature;
							signature.clear();
						}}>
							Clear
						</Button>{" "}
						<Button outline onClick={this.props.toggleModal}>
							Cancel
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
	  );
  }
};
