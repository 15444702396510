import React, { Component, Fragment } from "react";
import { Row,Card } from "reactstrap";

import DataListView from "../../containers/wholesale/vendors/DataListView";
import ImageListView from "../../containers/wholesale/vendors/ImageListView";
import ThumbListView from "../../containers/wholesale/vendors/ThumbListView";

import Pagination from "../../containers/pages/Pagination";
import ListPageHeading from "../../containers/wholesale/vendors/ListPageHeading";
import AddVendorModal from "../../containers/wholesale/AddVendorModal";
import EditVendorModal from "../../containers/wholesale/EditVendorModal";
import EditCustomerModal from "../../containers/customer/customers/EditCustomerModal";
//import GetInviteLinkModal from "../../containers/wholesale/GetInviteLinkModal";
//import GenerateLinkModal from "../../containers/wholesale/GenerateLinkModal";

import { Colxx } from "../../components/common/CustomBootstrap";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import swal from 'sweetalert';

import {REST_API_END_POINT,dataEncryptKey, JQUERY_MODULE} from "../../constants/defaultValues";

function collect(props) {
  return { data: props.data };
}

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class Vendors extends Component {
	
	constructor(props) {
		super(props);
		this.mouseTrap = require("mousetrap");

		this.state = {
			displayMode: "list",
			selectedPageSize: 10,
			orderOptions: [
				{ column: "date_of_registration", label: "Added On" },
				{ column: "vendor_name", label: "Vendor Name" },
				{ column: "phone", label: "Phone" },
				{ column: "email_id", label: "Email" }
			],
			pageSizes: [10, 20, 30, 50, 100],
			selectedOrderOption: { column: "date_of_registration", label: "Added On" },
			dropdownSplitOpen: false,
			modalOpen: false,
			currentPage: 1,
			totalItemCount: 0,
			totalPage: 1,
			search: "",
			selectedItems: [],
			selectedItemsStatus: [],
			lastChecked: null,
			isLoading: false,
			items: [],
			vendor_id:0,
			customer_id:0,
			modalOpenEdit: false,
			modalOpenEditOther: false,
			user_privileges:[]
		};
		this.dataListRender = this.dataListRender.bind(this);
		this.deleteItem = this.deleteItem.bind(this);
		this.toggleModalEdit = this.toggleModalEdit.bind(this);
		this.toggleModalEditOther = this.toggleModalEditOther.bind(this);
	}
	
	componentDidMount(){
		this.dataListRender();
		this.mouseTrap.bind(["ctrl+a", "command+a"], () =>
			this.handleChangeSelectAll(false)
		);
		this.mouseTrap.bind(["ctrl+d", "command+d"], () => {
			this.setState({
				selectedItems: [],
				selectedItemsStatus: []
			});
			return false;
		});
		
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}

	componentWillUnmount() {
		this.mouseTrap.unbind("ctrl+a");
		this.mouseTrap.unbind("command+a");
		this.mouseTrap.unbind("ctrl+d");
		this.mouseTrap.unbind("command+d");
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		let register_type = this.props.register_type;

		const {
		  selectedPageSize,
		  currentPage,
		  selectedOrderOption,
		  search
		} = this.state;
		axios
			.get(`${REST_API_END_POINT}wholesale/get-vendors?facility_id=${facility_id}&pageSize=${selectedPageSize}&currentPage=${currentPage}&orderBy=${
				selectedOrderOption.column
				}&search=${search}&register_type=${register_type}`
			).then(res => {
				return res.data;
			})
		  .then(data => {
				this.setState({
					totalPage: data.totalPage,
					items: data.result,
					selectedItems: [],
					selectedItemsStatus: [],
					totalItemCount: data.totalItem,
					isLoading: true
				});
		  });
	}
	
	deleteItem =  (e,vendor_id,status,register_type="1")  => {
		var data_word = parseInt(register_type)==2 ? "customer":"vendor"
		var msg="";
		var success_msg="";
		var failure_msg="";
        var this_obj=this;
        if(status===1){
            msg="Activate the "+data_word+" ?";
            success_msg="The "+data_word+" has been activated.";
            failure_msg="Failed to activate "+data_word
        }else if(status===2){
            msg="Delete the "+data_word+" ?";
            success_msg="The "+data_word+" has been deleted.";
            failure_msg="Failed to delete "+data_word;
        }else{
            msg="Deactivate the "+data_word+" ?";
            success_msg="The "+data_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+data_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {			
			if (willDelete) {
				var user_id = localStorage.getItem('user_id');
				
				var formData = new FormData();
				if(parseInt(register_type)==2){
					formData.append('customer_id',vendor_id);
				}else{
					formData.append('vendor_id',vendor_id);
				}
				formData.append('status',status);
				formData.append('user_id',user_id);
				
				var req_url = parseInt(register_type)==2 ? 'customer/delete-customer':'wholesale/delete-vendor';
				
				axios.post(REST_API_END_POINT+req_url,formData).then((res)=>{	
					var data = res.data;					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						var msg='';
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
					}
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					this.dataListRender();									
				}).catch(e => console.log("Addition failed , Error ", e));				
				
			}else{
				swal("Your operation is canceled!");
			}
        });
    }

	toggleModal = () => {
		this.setState({
			modalOpen: !this.state.modalOpen
		});
	};
	
	toggleModalEdit = (e,vendor_id=0) => {		
		this.setState({
			vendor_id: parseInt(vendor_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
	toggleModalEditOther = (e,vendor_id=0) => {		
		this.setState({
			customer_id: parseInt(vendor_id),
			modalOpenEditOther: !this.state.modalOpenEditOther,
		});
	};
	
	changeOrderBy = column => {
		this.setState({
			selectedOrderOption: this.state.orderOptions.find(x => x.column === column)
		  },() => this.dataListRender());
	};
	
	changePageSize = size => {
		this.setState({
			selectedPageSize: size,
			currentPage: 1
		  },() => this.dataListRender());
	};
	
	changeDisplayMode = mode => {
		this.setState({displayMode: mode });
		return false;
	};
	
	onChangePage = page => {
		this.setState({
			currentPage: page
		  },() => this.dataListRender());
	};

	onSearchKey = e => {
		this.setState({
			  search: e.target.value.toLowerCase()
			},() => this.dataListRender());
	};
	
	onCheckItem = (event, id, status) => {
		if (event.target.tagName === "A" || (event.target.parentElement && event.target.parentElement.tagName === "A")){
		  return true;
		}
		
		if (this.state.lastChecked === null) {
			this.setState({ lastChecked: id});
		}

		let selectedItems = this.state.selectedItems;
		let selectedItemsStatus = this.state.selectedItemsStatus;
		if (selectedItems.includes(id)) {
			selectedItemsStatus.splice(selectedItems.indexOf(id), 1); 
			selectedItems = selectedItems.filter(x => x !== id);
		} else {
			selectedItems.push(id);
			selectedItemsStatus.push(status);
		}
		this.setState({
		  selectedItems,
		  selectedItemsStatus
		});

		if (event.shiftKey) {
			var items = this.state.items;
			var start = this.getIndex(id, items, "id");
			var end = this.getIndex(this.state.lastChecked, items, "id");
			items = items.slice(Math.min(start, end), Math.max(start, end) + 1);
			selectedItems.push(
				...items.map(item => { return item.id; })
			);
			selectedItems = Array.from(new Set(selectedItems));
			this.setState({ selectedItems });
		}
		document.activeElement.blur();
	};

	getIndex(value, arr, prop) {
		for (var i = 0; i < arr.length; i++) {
			if (arr[i][prop] === value) {
				return i;
			}
		}
		return -1;
	}
	
	handleChangeSelectAll = isToggle => {
		if (this.state.selectedItems.length >= this.state.items.length) {
			if (isToggle) {
				this.setState({
				  selectedItems: [],
				  selectedItemsStatus: [],
				});
			}
		} else {
			this.setState({
				selectedItems: this.state.items.map(x => x.vendor_id),
				selectedItemsStatus: this.state.items.map(x => parseInt(x.status))
			});
		}
		document.activeElement.blur();
		return false;
	};
	
	groupDelete = (status) => {
		var selectedItems= this.state.selectedItems;
		if(selectedItems.length>0){
			var msg="";
			if(status==0){
				msg="You want to deactivate these profiles!"
			}else if(status==2){
				msg="You want to delete these profiles!"
			}else{
				msg="You want to activate these profiles!"			
			}
			swal({
			  title: "Are you sure?",
			  text: msg,
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
					var user_id = localStorage.getItem('user_id');
				
					var formData = new FormData();
					formData.append('vendor_ids',selectedItems.join(","));
					formData.append('status',status);
					formData.append('user_id',user_id);
				
				axios.post(REST_API_END_POINT+'vendor/group-delete',formData).then((res)=>{ 
					if(res.data.status==1){
						this.dataListRender();
						if(status==1){
							swal("Success!", "The profiles has been deactivated successfully!", "success");
						}else if(status==2){
							swal("Success!", "The profiles has been deleted successfully!", "success");
						}else{
							swal("Success!", "The profiles has been activated successfully!", "success");
						}								
					}else{
						if(status==1){
							swal("Failed to deactivate profiles!", "Please try after sometime.", "error");
						}else if(status==2){
							swal("Failed to delete profiles!", "Please try after sometime.", "error");
						}else{
							swal("Failed to activate profiles!", "Please try after sometime.", "error");
						}		
					}
					
				}).catch(e => console.log("Addition failed , Error ", e));
			  } else {
				swal("You have canceled the operation!");
			  }
			});
		}else{
			swal("Please select at least one profile!");
		}		
	};
	


  render() {
    const {
      currentPage,
      items,
      displayMode,
      selectedPageSize,
      totalItemCount,
      selectedOrderOption,
      selectedItems,
      orderOptions,
      pageSizes,
      modalOpen,
	  selectedItemsStatus
    } = this.state;
    const { match } = this.props;
    const startIndex = (currentPage - 1) * selectedPageSize;
    const endIndex = currentPage * selectedPageSize;

    return !this.state.isLoading ? (
      <div className="loading" />
    ) : (
      <Fragment>
		<div id="api_message"></div>
        <div className="mobile-show">
			<ListPageHeading
				heading="menu.data-list"
				displayMode={displayMode}
				changeDisplayMode={this.changeDisplayMode}
				handleChangeSelectAll={this.handleChangeSelectAll}
				changeOrderBy={this.changeOrderBy}
				changePageSize={this.changePageSize}
				selectedPageSize={selectedPageSize}
				totalItemCount={totalItemCount}
				selectedOrderOption={selectedOrderOption}
				match={match}
				startIndex={startIndex}
				endIndex={endIndex}
				selectedItemsLength={selectedItems ? selectedItems.length : 0}
				itemsLength={items ? items.length : 0}
				onSearchKey={this.onSearchKey}
				orderOptions={orderOptions}
				pageSizes={pageSizes}
				toggleModal={this.toggleModal}
				selectedItemsStatus={selectedItemsStatus}
				groupDelete={this.groupDelete}
				user_privileges={this.state.user_privileges}
				register_type={this.props.register_type}
			/>
		  
			<AddVendorModal
				modalOpen={modalOpen}
				toggleModal={this.toggleModal}
				dataListRender={this.dataListRender}
			/>
		  
			<EditVendorModal
				modalOpen={this.state.modalOpenEdit}
				toggleModal={this.toggleModalEdit}
				vendor_id={this.state.vendor_id}
				dataListRender={this.dataListRender}			
			/>
			
			<EditCustomerModal
				modalOpen={this.state.modalOpenEditOther}
				toggleModal={this.toggleModalEditOther}
				customer_id={this.state.customer_id}
				dataListRender={this.dataListRender}
			/>
		  
		  {/*<GetInviteLinkModal
            modalOpen={modalOpenInvite}
            toggleModal={this.toggleModalInvite}
          />
		   <GenerateLinkModal
            modalOpen={modalOpenGenerate}
            toggleModal={this.toggleModalGenerate}
          />*/}
		   
		  
          <Row>
            {this.state.items.map(result => {
				if (this.state.displayMode === "imagelist") {
					return (
						<ImageListView
							key={result.vendor_id}
							result={result}
							isSelect={this.state.selectedItems.includes(result.vendor_id)}
							collect={collect}
							onCheckItem={this.onCheckItem}
							editmodal={this.toggleModalEdit}
							editmodalother={this.toggleModalEditOther}
							deleteItem={this.deleteItem}
							user_privileges={this.state.user_privileges}
							register_type={this.props.register_type}
						/>
					);
				} else if (this.state.displayMode === "thumblist") {
					return (
					  <ThumbListView
						key={result.vendor_id}
						result={result}
						isSelect={this.state.selectedItems.includes(result.vendor_id)}
						collect={collect}
						onCheckItem={this.onCheckItem}
						editmodal={this.toggleModalEdit}
						editmodalother={this.toggleModalEditOther}
						deleteItem={this.deleteItem}
						user_privileges={this.state.user_privileges}
						register_type={this.props.register_type}
					  />
					);
				} else {
					return(
						<DataListView
							key={result.vendor_id}
							result={result}
							isSelect={this.state.selectedItems.includes(result.vendor_id)}
							onCheckItem={this.onCheckItem}
							collect={collect}
							editmodal={this.toggleModalEdit}
							editmodalother={this.toggleModalEditOther}
							deleteItem={this.deleteItem}
							user_privileges={this.state.user_privileges}
							register_type={this.props.register_type}							
						/>
					);
				}
            })}{" "}
            <Pagination
              currentPage={this.state.currentPage}
              totalPage={this.state.totalPage}
              onChangePage={i => this.onChangePage(i)}
            />
            
          </Row>
        </div>
      </Fragment>
    );
  }
}
export default Vendors;
