import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card, CardHeader, Button, CardBody, FormGroup, Input,
    Label,
	CustomInput,
 } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import DatePicker from "react-datepicker";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import DataTablePagination from "../../components/DatatablePagination";
import ReactTable from "react-table";
import moment from "moment";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import ReactToPrint from 'react-to-print';
import PrintLeafManagement from "../../containers/leafmanagement/PrintLeafManagement";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class LeafManagement extends Component {
	
	constructor(props) {
		super(props);
	  
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.state = {
			endDate: null,
			startDate: null,
			module_id: null,
			selected_facility_id: null,
			search: '',
			modules:[],
			facilities:[],
			leaf_management:[],
		};
	}
	
	handleChangeDate = date => {
		this.setState({
		  startDate: date
		});
	};
	
	handleChangeEnd = time => {
		this.setState({
		  endDate: time
		});
	};
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.startDate!=null){
			formData.append('start_date',moment(this.state.startDate).format('YYYY-MM-DD'));
		}else{
			formData.append('start_date','');
		}
		
		if(this.state.endDate!=null){
			formData.append('end_date',moment(this.state.endDate).format('YYYY-MM-DD'));
		}else{
			formData.append('end_date','');
		}
		
		if(this.state.module_id!=null){
			formData.append('module_id',this.state.module_id.value);
		}else{
			formData.append('module_id','0');
		}	
		
		if(this.state.selected_facility_id!=null){
			formData.append('selected_facility_id',this.state.selected_facility_id.value);
		}else{
			formData.append('selected_facility_id','-1');
		}		
		
		axios.post(REST_API_END_POINT+'leaf-management/get-all',formData).then((res)=>{
			var data = res.data;
			
		    var modules=data.modules;			
		    var facilities=data.facilities;			
			
			modules = modules.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			facilities = facilities.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			facilities.unshift({ label:'All',value:0,key:0 });
			
			var selected_facility_id=facilities.find(r=>{ return r.value==facility_id });
			
			
			if(data.leaf_management){
				this.setState({
					leaf_management: data.leaf_management,
					modules,
					facilities,
					selected_facility_id
				});
			}else{
				this.setState({
					leaf_management: [],
					modules,
					facilities,
					selected_facility_id
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	render() {
		let data = this.state.leaf_management;
		if (this.state.search) {
			data = data.filter(row => {
				if(row.user==null){
					row.user=""
				}
				return row.operation.toLowerCase().includes(this.state.search.toLowerCase()) || row.user.toLowerCase().includes(this.state.search.toLowerCase()) || moment(row.created_at).format('MM/DD/YYYY HH:mm').includes(this.state.search)
			})
		}
		
		const dataTableColumns = [
			{
				Header: "Operation",
				accessor: "operation",
				Cell: props => <p className="list-item-heading" dangerouslySetInnerHTML={{ __html: props.value }} ></p>
			},
			{
				Header: "Done By",
				accessor: "user",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Filed Date & Time",
				accessor: "created_at",
				Cell: props => <p className="text-muted">{moment(props.value).format('MM/DD/YYYY HH:mm')}</p>
			}	  
		];
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.leaf-management" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
			
				<Card className="p-4">
					<AvForm
						className="av-tooltip tooltip-right-bottom"
						onSubmit={this.handleSubmit}
					>		
						<Row className="mt-4">
							<Colxx lg="4">
								<Label>Start Date</Label>
								<DatePicker
									id="start_date"
									name="start_date"
									autoComplete="off"
									className="mb-2"
									selected={this.state.startDate}
									onChange={this.handleChangeDate}
									placeholderText=""
									dateFormat="MM/DD/YYYY"
								  />
							</Colxx>
							<Colxx lg="4">
								<Label>End Date</Label>
								<DatePicker
									id="end_date"
									name="end_date"
									autoComplete="off"
									className="mb-2"
									selected={this.state.endDate}
									onChange={this.handleChangeEnd}
									placeholderText=""
									dateFormat="MM/DD/YYYY"
								/>
							</Colxx>
							<Colxx lg="4">
								<Label >Module<span className="text-danger">*</span></Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"
									name="module_id"
									id="module_id"
									value={this.state.module_id}						
									onChange={module_id => { this.setState({ module_id }) }}
									options={this.state.modules}
								/>
							</Colxx>
							<Colxx lg="4">
								<Label >Facilities<span className="text-danger">*</span></Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"
									name="selected_facility_id"
									id="selected_facility_id"
									value={this.state.selected_facility_id}						
									onChange={selected_facility_id => { this.setState({ selected_facility_id }) }}
									options={this.state.facilities}
								/>
							</Colxx>
							
							<Colxx lg="8" xxs="12">
								<div className="float-sm-right mt-3">
									{" "}<Button
											onClick={this.dataListRender}
											color="primary"
											size="sm"
											className=""
										>
										  View Report
									</Button>{" "}<ReactToPrint
										trigger={() => <Button
												color="primary"
												size="sm"
												type="button"
											>
												Print
											</Button>}
										content={() => this.componentRef}
										copyStyles={true}
										pageStyle={""}
									/>
								</div>
							</Colxx>
						</Row>					
					</AvForm> 			
				
					<div className="mb-4 dropdown-edit mt-4">
						<FormGroup row>
							<Colxx lg="12" >
								<Input
									type="text"
									autoComplete="off"
									name="table_search"
									id="table_search"
									placeholder="Search"
									value={this.state.search}
									onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							minRows = {2}
							filterable={false}
							showPageJump={false}
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}
							defaultSorted={[{id: "send_on",desc: false}]}
						/>
					</div>
				</Card>	
				
				<div className="d-none">
					<PrintLeafManagement ref={el => (this.componentRef = el)} data={data} />
				</div>
			</Fragment>
		);
	}
}
