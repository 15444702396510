import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, FormGroup, Input,
    DropdownItem,
	Label,
	CustomInput, Badge,
	InputGroup, 
    InputGroupAddon,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ReactTable from "react-table";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import swal from 'sweetalert';
import {REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, themeColor} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const QTY_TYPES = [
    { label: "Milligrams", value: "0", key: 0 },
    { label: "Grams", value: "1", key: 1 },
    { label: "Units", value: "2", key: 2 }
];

export default class KitchenProcess extends Component {
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.productChange = this.productChange.bind(this);
		this.recipeChange = this.recipeChange.bind(this);
		this.state = {
			search: '',
			products:[],
			recipes:[],
			variations:[],
			ingredients:[],
			new_product:null,
			product_variation:null,
			recipe_id:null,
			no_of_items:"",
			blocking:true
		};
	}
	
	componentDidMount() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'kitchen/get-data-process',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				var products=data.products;
				var recipes=data.recipes;
				
				products = products.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				recipes = recipes.map((element,index)=>{
					element.label=element.recipe_name;
					element.value=element.recipe_id;
					element.key=index;
					return element;
				});
				
				var new_product = null;
				
				if(products.length){
					new_product = products[0];
					this.productChange(new_product.id);
				}
				
				this.setState({
					products,
					recipes,
					new_product,
					blocking:false
				});	
			}else{
				this.setState({
					products:[],
					recipes:[],
					new_product:null,					
					blocking:false
				});
			}			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	productChange(product_id){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('product_id',product_id);
		
        this.setState({ blocking:true });
		
		axios.post(REST_API_END_POINT+'kitchen/get-variations',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				var variations=data.variations;
				
				variations = variations.map((element,index)=>{
					element.label=element.variation_name;
					element.value=element.variation_id;
					element.key=index;
					return element;
				});
				
				var product_variation = null;
				
				if(variations.length){
					product_variation = variations[0];
				}
				
				this.setState({
					variations,
					product_variation,
					blocking:false
				});	
			}else{
				this.setState({
					variations:[],
					product_variation:null,
					blocking:false
				});
			}			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	recipeChange(recipe_id){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('recipe_id',recipe_id);
		
        this.setState({ blocking:true });
		
		axios.post(REST_API_END_POINT+'kitchen/get-recipe',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				var ingredients=data.ingredients;
				
				if(ingredients.length==0){
					swal("Warning!","This recipe has no ingredients. Please choose another recipe to proceed","warning");
				}
				
				this.setState({
					ingredients,
					blocking:false
				});	
			}else{
				swal("Warning!","This recipe has no ingredients. Please choose another recipe to proceed","warning");
				
				this.setState({
					ingredients:[],
					blocking:false
				});
			}			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			
			if(this.state.new_product==null){
				swal("Warning!","Please choose a product","warning");
				return;
			}
			
			if(this.state.product_variation==null){
				swal("Warning!","Please choose a product variation","warning");
				return;
			}
			
			if(this.state.recipe_id==null){
				swal("Warning!","Please choose a recipe","warning");
				return;
			}
			
			if(this.state.ingredients.length==0){
				swal("Warning!","This recipe has no ingredients. Please choose another recipe to proceed","warning");
				return;
			}
			
			document.getElementById("kitchen-process").disabled=true;
			
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			
			var ingredients = this.state.ingredients;
			var no_of_items = this.state.no_of_items;
			if(no_of_items==''){
				no_of_items=0;
			}
			
			var product_list = [];
			for(let ingredient of ingredients){
				let p={};
				p.product_id = ingredient.product_id;
				p.variation_id = ingredient.variation_id;
				p.quantity= parseFloat(ingredient.quantity)*parseFloat(no_of_items);
				if(ingredient.qty_type=="0" && p.variation_id==null){
					p.quantity = p.quantity/1000;
				}
				
				product_list.push(p);
			}
			formData.append('product_list',JSON.stringify(product_list));
			
			axios.post(REST_API_END_POINT+'kitchen/check-product-stock',formData).then((res)=>{
				var data = res.data;
				var proceed=true;
				
				var products=data.products;
				for(let value of products){
					if(value.available==0){
						let value_qty_type = value.qty_type;
						if(parseInt(value.product_type)==2){
							value_qty_type="3";
						}
						swal("Warning!",'Only '+value.available_qty+' '+(value_qty_type=="3" ? "Units":"Grams")+' of "'+value.name+'" is available',"warning");
						proceed=false;
					}
				}
				
				if(proceed){
					let user_id = localStorage.getItem('user_id');
					let facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					let formData = new FormData();
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);		
					formData.append('new_product',this.state.new_product.value);		
					formData.append('product_variation',this.state.product_variation.value);		
					formData.append('no_of_items',this.state.no_of_items);		
					
					let no_of_items = this.state.no_of_items;
					if(no_of_items==''){
						no_of_items=0;
					}
					
					let product_list = [];
					for(let ingredient of ingredients){
						let p={};
						p.product_id = ingredient.product_id;
						p.variation_id = ingredient.variation_id;
						p.ingredient_name = ingredient.name;
						p.ingredient_qty = parseFloat(ingredient.quantity)*parseFloat(no_of_items);
						if(ingredient.qty_type=="0" && p.variation_id==null){
							p.ingredient_qty = p.ingredient_qty/1000;							
						}
						
						if(ingredient.qty_type=="2"){
							p.ingredient_unit="Units";
						}else{
							p.ingredient_unit="Grams";
						}
						
						product_list.push(p);
					}
					formData.append('product_list',JSON.stringify(product_list));
					
					axios.post(REST_API_END_POINT+'kitchen/process',formData).then((res)=>{
						var data = res.data;
						if(data.status==0){
							swal("Warning!",data.msg,"warning");
							document.getElementById("kitchen-process").disabled=false;
						}else{
							swal("Success!",data.msg,"success").then(()=>{
								window.location.reload();
							});
						}						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("kitchen-process").disabled=false;
					});
				}else{
					document.getElementById("kitchen-process").disabled=false;
				}
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("kitchen-process").disabled=false;
			});
		}
	}
  
	render() {
		const { products, recipes, variations, no_of_items } = this.state;
		let data = this.state.ingredients;
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search)
			})
		}
		var final_no_of_items = 0;
		if(no_of_items!=''){
			final_no_of_items=no_of_items;
		}
		
		const dataTableColumns = [
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Quantity",
				accessor: "quantity",
				Cell: props => {
					if(props.original.product_type==1){
						return(<p className="list-item-heading"><strong>{parseFloat(props.value)*parseFloat(final_no_of_items)}</strong>{" "+(QTY_TYPES.find(r=>{ return r.value==props.original.qty_type }).label)}</p>)
					}else{
						let final_qty_type=variations.find(r=>{ return r.variation_id==props.original.variation_id });
						if(final_qty_type!=null){
							return(<p className="list-item-heading"><strong>{parseFloat(props.value)*parseFloat(final_no_of_items)}</strong>{" x "+final_qty_type.variation_name}</p>)
						}else{
							return "";
						}
					}
				}
			}
		];
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading_text="Create Finished Products" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
					<Card className="p-4">
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
							<Row className="mt-4">
								<Colxx lg="6">
									<AvGroup className="error-t-negative">
										<Label>New Product</Label>
										<Select
											components={{ Input: CustomSelectInput }}
											className="react-select"
											classNamePrefix="react-select"						
											name="new_product"
											id="new_product"
											placeholder="--Select Product--"
											value={this.state.new_product}						
											options={products}
											onChange={new_product => { this.setState({ new_product }); this.productChange(new_product.id) }}
										/>
									</AvGroup>
								</Colxx>
								<Colxx lg="6">
									<AvGroup className="error-t-negative">
										<Label>Variation</Label>
										<Select
											components={{ Input: CustomSelectInput }}
											className="react-select"
											classNamePrefix="react-select"
											name="product_variation"
											id="product_variation"
											placeholder="--Select Variation--"
											value={this.state.product_variation}						
											options={variations}
											onChange={product_variation => { this.setState({ product_variation }) }}
										/>
									</AvGroup>
								</Colxx>
							</Row>
					
							<Row>
								<Colxx lg="12">
									<AvGroup className="error-t-negative">
										<Label>Recipe</Label>
										<Select
											components={{ Input: CustomSelectInput }}
											className="react-select"
											classNamePrefix="react-select"						
											name="recipe_id"
											id="recipe_id"
											placeholder="--Select Recipe--"
											value={this.state.recipe_id}						
											options={recipes}
											onChange={recipe_id => { 
												this.setState({ recipe_id });
												this.recipeChange(recipe_id.value);
											}}
										/>
									</AvGroup>
								</Colxx>
							</Row>
							<Row>
								<Colxx lg="12">
									<AvGroup className="error-t-negative">
										<Label>No. of items<span className="text-danger">*</span></Label>
										<AvField
											id="no_of_items"
											name="no_of_items"
											type="number"
											min="0"
											step="1"
											value={no_of_items}
											onChange={e => { this.setState({ no_of_items:e.target.value }) }}
											validate={{
												required: {
													value: true,
													errorMessage: "This field is required."
											  }
											}}
										/>
									</AvGroup>
								</Colxx>
							</Row>
							
							<div className="py-4">
								<h2>Ingredients</h2>				
								<div className="mt-4 mb-4 dropdown-edit">						
									<FormGroup row>
										<Colxx lg="6">
											<Input
											  type="text"
											  name="table_search"
											  id="table_search"
											  placeholder="Search"
											  value={this.state.search}
											  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
											/>
										</Colxx>
									</FormGroup>
									
									<ReactTable
										data={data}
										columns={dataTableColumns}
										defaultPageSize={10}
										minRows = {2}
										filterable={false}
										showPageJump={false}
										PaginationComponent={DataTablePagination}
										showPageSizeOptions={true}
										defaultSorted={[{id: "send_on",desc: false}]}
									/>
								</div>
								<Row className="mt-4">					
									<Colxx lg="12">
										<Button
											color="primary"
											size="lg"
											className="mr-2"
											id="kitchen-process"
											>
											Process
										</Button>
									</Colxx>
								</Row>
							</div>

						</AvForm>
					</Card>
				</BlockUi>
			
			</Fragment>
		);
	}
}
