import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup, InputGroup,
  InputGroupAddon
 } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import ImageCropper from "../../../components/common/ImageCropper";
import moment from 'moment';
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectStatus = [
    { label: "Inactive", value: "0", key: 0 },
    { label: "Active", value: "1", key: 1 }
];
  

class EditProductCategoryModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateCroppedImageUrl = this.updateCroppedImageUrl.bind(this);
	
	this.state = {
		modalOpenAdd: false,
		metricCategorySelect: null,
		statusType: { label: "Active", value: "1", key: 1 },
		productcategory:null,
        shop_yes:true,
        shop_no:false,
        shop_yes_value:"1",		
        shop_no_value:"0",
		pos_yes:true,
		pos_no:false,
		pos_yes_value:"1",
		pos_no_value:"0",
        aromas:true,
		aromas_yes_value:"Y",
		flavors:true,
		flavors_yes_value:"Y",
		cannabinoids:true,
		cannabinoids_yes_value:"Y",
		cbd:true,
		cbd_yes_value:"Y",
		cloth_size:true,
		cloth_size_yes_value:"Y",
		effects:true,
		effects_yes_value:"Y",
		symptoms:true,
		symptoms_yes_value:"Y",
		thc:true,
		thc_yes_value:"Y",
		croppedImageUrl:null,
		is_minwidth:true
	};
	
  }
	
	handleSubmit(event, errors, values) {
		if(this.state.croppedImageUrl!=null && !this.state.is_minwidth){
			swal("Warning!","Minimum required dimension of the image is 320x320","warning");
			return;
		}
		if (errors.length === 0) {			
			document.getElementById("product-category-edit").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("categoryForm"));
			formData.append('category_id',this.props.category_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			
			if(this.state.croppedImageUrl!=null){
				var fileFromBlob = new File([this.state.croppedImageUrl], 'category-image'+moment().unix()+'.png');
				formData.append('thumbnail',fileFromBlob);
			}
			formData.delete('thumbnail_crop');

			axios.post(REST_API_END_POINT+'inventory/edit-product-category',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("product-category-edit").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("product-category-edit").disabled=false;
			});

		}
	}	

	componentDidUpdate(prevProps, prevState, snapshot){
	   
        if(this.props.modalOpen){
			let productcategory; 		  
            if (prevProps.category_id !== this.props.category_id && this.props.category_id!=0) {
				var formData = new FormData();
				formData.append('category_id',this.props.category_id);
	
				axios.post(REST_API_END_POINT+'inventory/product-category-by-id',formData).then((res)=>{
					var data = res.data;
					var parent_category=null;
					var metricCategorySelect=null;
					var status=null;
					
					productcategory=data.category;

					this.props.parent_categories.forEach((element,index) => {
						if(element.value==productcategory.parent_category){
							parent_category=element;
						}
					});
					
					this.props.metric_categories.forEach((element,index) => { 
						if(element.value==productcategory.metrc_category){
							metricCategorySelect=element;
						}
					});
					selectStatus.forEach((element,index) => { 
						if(element.value==productcategory.status){
							status=element;
						}
					});
					
					var shop_yes=true;
					var shop_no=false;
					var pos_yes=true;
					var pos_no=false;
					var aromas=true;
					var flavors=true;
					var cannabinoids=true;
					var cbd=true;
					var cloth_size=true;
					var effects=true;
					var symptoms=true;
					var thc=true;
					
					if(parseInt(productcategory.show_on_shop)==1){
						shop_yes=true;
						shop_no=false;
					}else{
						shop_yes=false;
						shop_no=true;
					}
					
					if(parseInt(productcategory.show_on_pos)==1){
						pos_yes=true;
						pos_no=false;
					}else{
						pos_yes=false;
						pos_no=true;
					}
					
					if(productcategory.aromas=="Y"){
						aromas=true;
					}else{
						aromas=false;
					}
					
					if(productcategory.flavors=="Y"){
						flavors=true;
					}else{
						flavors=false;
					}
					
					if(productcategory.cannabinoids=="Y"){
						cannabinoids=true;
					}else{
						cannabinoids=false;
					}
					
					if(productcategory.cbd=="Y"){
						cbd=true;
					}else{
						cbd=false;
					}
					
					if(productcategory.cloth_size=="Y"){
						cloth_size=true;
					}else{
						cloth_size=false;
					}
					
					if(productcategory.effects=="Y"){
						effects=true;
					}else{
						effects=false;
					}
					
					if(productcategory.symptoms=="Y"){
						symptoms=true;
					}else{
						symptoms=false;
					}
					
					if(productcategory.thc=="Y"){
						thc=true;
					}else{
						thc=false;
					}
					
					this.setState({ 
						productcategory,
						parent_category,
						metricCategorySelect,
						status,
						shop_yes,
						shop_no,
						pos_yes,
						pos_no,
						aromas,
						flavors,
						cannabinoids,
						cbd,
						cloth_size,
						effects,
						symptoms,
						thc,
					});
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});	
			}
		}
	}
	
	updateCroppedImageUrl = (croppedImageUrl,is_minwidth) => {
		this.setState({ croppedImageUrl,is_minwidth })
	};
	

  render() {
		var parent_categories = this.props.parent_categories;
		if(this.state.productcategory!=null){
			var productcategory=this.state.productcategory;
			if(typeof productcategory.category_id!='undefined'){
				parent_categories = parent_categories.filter(row=>{ return row.category_id!=productcategory.category_id; });
			}
		}else{
           var productcategory;
		}
		
		
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Edit Product Category
			</ModalHeader>
			<AvForm
				id="categoryForm"		
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="cname"
							name="name"
							type="text"
							value={ productcategory?((productcategory.name!=null)?productcategory.name:''):'' }
							validate={{
								required: {
									value: true,
									errorMessage: "This field is required."
								},
								minLength: {
									value: 2,
									errorMessage:"The name must be between 2 and 120 characters"
								},
								maxLength: {
									value: 120,
									errorMessage:"The name must be between 2 and 120 characters"
								}
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Metrc Category</Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="metrc_category"
								id="metrc_category"
								placeholder="--Select Metrc Category--"						
								value={this.state.metricCategorySelect}					
								options={this.props.metric_categories}
								onChange={metricCategorySelect => { this.setState({ metricCategorySelect }) }}

							/>
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12" className="mt-2 mb-2">
						<Label>Category Type<span className="text-danger">*</span></Label> &nbsp;&nbsp;							
						<AvRadioGroup
							inline
							name="category_type"
							validate={{
							  required: {
								value: true,
								errorMessage: "Please select any of the category type"
							  }
							}}
							value={ productcategory?((productcategory.category_type!=null)?productcategory.category_type:'0'):'0' }
						>								
							<AvRadio customInput label="Medical Marijuana" value="1" />
							<AvRadio customInput label="Edibles" value="2" />
							<AvRadio customInput label="Non-Edibles" value="3" />
							<AvRadio customInput label="Raw Material" value="4" />
							<AvRadio customInput label="Shatter" value="5" />
							<AvRadio customInput label="Other" value="0" />								
						</AvRadioGroup>
					</Colxx>
				</Row>
				
				<span >Description </span> 
				<Row>
				
					<Colxx lg="12">					
							<AvGroup className="error-t-negative">
							<AvField
								name="desc"
								id="description"
								type="textarea"
								value={ productcategory?((productcategory.description!=null)?productcategory.description:''):'' }
								placeholder=""
								rows="5"
								validate={{
								  minLength: {
									value: 5,
									errorMessage: "Your message must have minimum of 5 characters"
								  },
								}}
							/>
							</AvGroup>							
					</Colxx>																		
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Parent Category</Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="parent_category"
								id="parent_category"
								placeholder="--Select Parent Category--"						
								value={this.state.parent_category}						
								options={parent_categories}
								onChange={parent_category => { this.setState({ parent_category:parent_category }) }}

							/>
						</AvGroup>
					</Colxx>
				</Row>
				{productcategory!=null ? (productcategory.thumbnail!=null ? <Row className="mt-4">
					<Colxx lg="12" >
						<div className="d-flex justify-content-start">
							<div className="text-center img-view-wrapper">
								<img src={BASE_URL+'products/'+productcategory.thumbnail} alt="Category Image" className="img-responsive" />
							</div>
						</div>
					</Colxx>
				</Row>:""):""}
				
				<ImageCropper 
					updateCroppedImageUrl={this.updateCroppedImageUrl}
					label={"Thumbnail (Recommended size 320x320)"}
				/>
				<span>Features</span>
				<Row className="mt-4">
					<Colxx lg="6">						
						<FormGroup>
							<CustomInput 
								type="checkbox" 
								label="Aromas"
								name="aromas"
								value={this.state.aromas_yes_value}
								checked={this.state.aromas}
								onChange={() => { this.setState({ aromas:!this.state.aromas }) }}
								id="aromas"
							/>
						</FormGroup>
						<FormGroup>
							<CustomInput 
							type="checkbox" 
							label="Flavors"
							name="flavors"
							value={this.state.flavors_yes_value}
							checked={this.state.flavors}
							onChange={() => { this.setState({ flavors:!this.state.flavors }) }}
							id="flavors"
							/>
						</FormGroup>
						<FormGroup>
							<CustomInput 
							type="checkbox" 
							label="Effects"
							name="effects"
							value={this.state.effects_yes_value}
							checked={this.state.effects}
							onChange={() => { this.setState({ effects:!this.state.effects }) }}
							id="effects"/>
						</FormGroup>
						<FormGroup>
							<CustomInput 
							type="checkbox" 
							label="Symptoms"
							name="symptoms"
							value={this.state.symptoms_yes_value}
							checked={this.state.symptoms}
							onChange={() => { this.setState({ symptoms:!this.state.symptoms }) }}
							id="symptoms"/>
						</FormGroup>
					</Colxx>
					<Colxx lg="6">
						<FormGroup>
							<CustomInput 
							type="checkbox" 
							label="THC"
							name="thc"
							value={this.state.thc_yes_value}
							checked={this.state.thc}
							onChange={() => { this.setState({ thc:!this.state.thc }) }}
							id="thc"
							/>
						</FormGroup>						
						<FormGroup>
							<CustomInput 
							type="checkbox" 
							label="CBD"
							name="cbd"
							value={this.state.cbd_yes_value}
							checked={this.state.cbd}
							onChange={() => { this.setState({ cbd:!this.state.cbd }) }}
							id="cbd"
							/>
						</FormGroup>
						<FormGroup>
							<CustomInput 
							type="checkbox" 
							label="Cannabinoids"
							name="cannabinoids"
							value={this.state.cannabinoids_yes_value}
							checked={this.state.cannabinoids}
							onChange={() => { this.setState({ cannabinoids:!this.state.cannabinoids }) }}
							id="cannabinoids"
							/>
						</FormGroup>
						<FormGroup>
							<CustomInput 
								type="checkbox" 
								label="Cloth Size"
								name="cloth_size"
								value={this.state.cloth_size_yes_value}
								checked={this.state.cloth_size}
								onChange={() => { this.setState({ cloth_size:!this.state.cloth_size }) }}
								id="cloth_size"
							/>
						</FormGroup>
					</Colxx>		
				</Row>
				<Row>
					<Colxx lg="12" className="mt-2 mb-2">
						<Label>Show in shop</Label>	&nbsp;&nbsp;						
						<Label>&nbsp;&nbsp;
						  <CustomInput
							id="shop_yes"
							type="radio"
							name="show_on_shop"
							value={this.state.shop_yes_value}
							className="d-inline-block"
							checked={this.state.shop_yes}
							onChange={() => { this.setState({ shop_yes:true,shop_no:false }) }}							
						  />
							{" "}Yes
						</Label>&nbsp;&nbsp;						
						<Label>
						  <CustomInput
							id="shop_no"
							type="radio"
							name="show_on_shop"
							value={this.state.shop_no_value}
							className="d-inline-block"	
                            checked={this.state.shop_no}
							onChange={() => { this.setState({ shop_yes:false,shop_no:true }) }}							
						  />
							{" "}No
						</Label>		
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12" className="mt-2 mb-2">
						<Label>Show in POS</Label>	&nbsp;&nbsp;						
						<Label>&nbsp;&nbsp;
							<CustomInput
								id="pos_yes"
								type="radio"
								name="show_on_pos"
								className="d-inline-block"
								value={this.state.pos_yes_value}
								className="d-inline-block"
								checked={this.state.pos_yes}
								onChange={() => { this.setState({ pos_yes:true,pos_no:false }) }}
							/>
							{" "}Yes
						</Label>&nbsp;&nbsp;						
						<Label>
							<CustomInput
								id="pos_no"
								type="radio"
								name="show_on_pos"
								className="d-inline-block"
								value={this.state.pos_no_value}
								className="d-inline-block"
								checked={this.state.pos_no}
								onChange={() => { this.setState({ pos_yes:false,pos_no:true }) }}								
							/>
							{" "}No
						</Label>		
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						<Label>Status</Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name="status"
							id="status"
							value={this.state.status}						
							options={selectStatus}
							onChange={status => { this.setState({ status }) }}
						/>
						</AvGroup>					
					</Colxx>					
				</Row>
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="product-category-edit" >Update</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditProductCategoryModal;
