import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import AddStrainModal from "../../containers/inventory/AddStrainModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import DropzoneExample from "../../containers/forms/DropzoneExample";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
const selectData = [
    { label: "$", value: "0", key: 0 },
    { label: "%", value: "1", key: 1 }
  ];
  const selectRoom = [
    { label: "Room 1", value: "1", key: 0 },
    { label: "Room 2", value: "2", key: 1 }
  ];
  const selectQuantity = [
    { label: "Gram", value: "1", key: 0 },
    { label: "Lbs", value: "2", key: 1 },
    { label: "Unit", value: "3", key: 2 }
  ];
  const selectVendor = [
    { label: "Phinest ", value: "1", key: 0 }
  ];
  const selectAdditives = [
    { label: "Additives ", value: "1", key: 0 }
  ];
  const selectTaxCategory = [
    { label: "Sales Tax", value: "8", key: 0 },
    { label: "Service Tax", value: "1", key: 1 }
  ];
  const selectCategories = [
    { label: "Tissue Culture Clones", value: "1", key: 0 },
    { label: "Trim", value: "2", key: 1 },
    { label: "Fresh Frozen Flower", value: "3", key: 2 },
    { label: "Frozen Trim", value: "4", key: 3 }
  ];
  const selectsubCategories = [
    { label: "Tissue Culture Clones", value: "1", key: 0 },
    { label: "Trim", value: "2", key: 1 },
    { label: "Fresh Frozen Flower", value: "3", key: 2 },
    { label: "Frozen Trim", value: "4", key: 3 }
  ];
  const selectType = [
    { label: "Normal", value: "1", key: 0 },
    { label: "Veterans", value: "2", key: 1 },
    { label: "Senior Citizens", value: "3", key: 2 },
    { label: "Student", value: "4", key: 3 },
    { label: "Inbound Customer", value: "5", key: 4 }
  ];
  const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	["bold", "italic", "underline"],
     // dropdown with defaults from theme	
	[
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],    	    
    
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline", 
  "list",
  "bullet",
  "indent", 
];

class EditGroupModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {
 modalOpenAdd: false,		
		};
	
  }
	
  handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }
  toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
  onChange = e => {
    switch (e.target.name) {
      case 'profile_pic':
		if(e.target.files[0]){
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
		}else{
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
      break;
      default:
        e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
     }
  };


  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Group
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Group Name</Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							validate={{
							  required: {
								value: true,
								errorMessage: "Please enter the  name"
							  },
							  pattern: {
								value: "^[a-zA-Z ,.'-]+$",
								errorMessage: "Invalid first name"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>City</Label>
						  <AvField
							id="city"
							name="city"
							type="text"
							validate={{
							  required: {
								value: true,
								errorMessage: "Field is required"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row className="mt-4">
					<Colxx lg="12">
						<Label >Category</Label>
					 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						isMulti
						id="select2-state_id-container"
						value={this.state.selectedOptions}
						onChange={this.handleChangeMulti}
						options={selectCategories}
					/>
					</Colxx>									
				</Row>
				<Row className="mt-4">
					<Colxx lg="12" className="mb-2">						
						<Label>Group Logo</Label>
						 <DropzoneExample />
					</Colxx>
				</Row>								
								
				
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" >
			  <IntlMessages id="customer.save" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditGroupModal;
