import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";


  const selectsubCategories = [
    { label: "Tissue Culture Clones", value: "1", key: 0 },
    { label: "Trim", value: "2", key: 1 },
    { label: "Fresh Frozen Flower", value: "3", key: 2 },
    { label: "Frozen Trim", value: "4", key: 3 }
  ];

class OnBoardingModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {
 modalOpenAdd: false,		
		};
	
  }
	
  handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }
  toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
  onChange = e => {
    switch (e.target.name) {
      case 'profile_pic':
		if(e.target.files[0]){
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
		}else{
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
      break;
      default:
        e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
     }
  };


  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Onboard
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
			
					<Row className="mt-2">
					<Colxx lg="12" className="mb-2">	
					<AvGroup>
					  <Label>WorkFlow</Label>
					  <AvField 
						name="suggestion" 
						type="textarea" 
						rows="2"
						validate={{
							  required: {
								value: true,
								errorMessage: "Please enter the  message"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The message must have atleast 2 characters"
							  }
							}}
						/>
					</AvGroup>
					</Colxx>
				</Row>				
				<Row className="mt-4">
					<Colxx lg="12">
						<Label >Product</Label>
					 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						isMulti
						id="select2-state_id-container"
						value={this.state.selectedOptions}
						onChange={this.handleChangeMulti}
						options={selectsubCategories}
					/>
					</Colxx>									
				</Row>
				<Row className="mt-4">
					<Colxx lg="12" className="mb-2">	
					<AvGroup>
					  <Label>Message</Label>
					  <AvField 
						name="suggestion" 
						type="textarea" 
						rows="6"
						validate={{
							  required: {
								value: true,
								errorMessage: "Please enter the  message"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The message must have atleast 2 characters"
							  }
							}}
						/>
					</AvGroup>
					</Colxx>
				</Row>								
								
				
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" >
			  <IntlMessages id="customer.save" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default OnBoardingModal;
